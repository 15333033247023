<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <canvas id="withdrawChart" ref="withdrawChart"></canvas>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import { Chart, registerables } from 'chart.js';
import axios from "@/axs/axios.js";

Chart.register(...registerables);

export default {
    components: { Header, SideBar },
    data() {
        return {
            chart: null,
            monthlyWithdraws: Array(12).fill(0),
            monthlyDeposits: Array(12).fill(0)
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const response = await axios.get('/admin-stat-transactions');
                const data = response.data;

                if (data.retraits && data.depots) {
                    this.monthlyWithdraws = [
                        parseFloat(data.retraits.janvier) || 0,
                        parseFloat(data.retraits.février) || 0,
                        parseFloat(data.retraits.mars) || 0,
                        parseFloat(data.retraits.avril) || 0,
                        parseFloat(data.retraits.mai) || 0,
                        parseFloat(data.retraits.juin) || 0,
                        parseFloat(data.retraits.juillet) || 0,
                        parseFloat(data.retraits.août) || 0,
                        parseFloat(data.retraits.septembre) || 0,
                        parseFloat(data.retraits.octobre) || 0,
                        parseFloat(data.retraits.novembre) || 0,
                        parseFloat(data.retraits.décembre) || 0,
                    ];

                    this.monthlyDeposits = [
                        parseFloat(data.depots.janvier) || 0,
                        parseFloat(data.depots.février) || 0,
                        parseFloat(data.depots.mars) || 0,
                        parseFloat(data.depots.avril) || 0,
                        parseFloat(data.depots.mai) || 0,
                        parseFloat(data.depots.juin) || 0,
                        parseFloat(data.depots.juillet) || 0,
                        parseFloat(data.depots.août) || 0,
                        parseFloat(data.depots.septembre) || 0,
                        parseFloat(data.depots.octobre) || 0,
                        parseFloat(data.depots.novembre) || 0,
                        parseFloat(data.depots.décembre) || 0,
                    ];

                    this.createChart();
                } else {
                    console.error("Les données de retraits ou de dépôts sont manquantes.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données : ", error);
            }
        },
        createChart() {
            console.log("Création du graphique...");
            const ctx = this.$refs.withdrawChart.getContext('2d');

            const labels = [
                'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin',
                'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'
            ];

            console.log("Données des retraits : ", this.monthlyWithdraws);
            console.log("Données des dépôts : ", this.monthlyDeposits);

            // Détruire le graphique précédent si existe
            if (this.chart) {
                this.chart.destroy();
            }

            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Rembourssement',
                            data: this.monthlyWithdraws,
                            fill: false,
                            borderColor: 'rgb(255, 0, 0)',
                            tension: 0.1
                        },
                        {
                            label: 'Dépot',
                            data: this.monthlyDeposits,
                            fill: false,
                            borderColor: '#006666',
                            tension: 0.1
                        }
                    ]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        }
    },
};
</script>

<style>

</style>

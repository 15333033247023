<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            payoutRequests: []
        };
    },
    mounted() {
        this.fetchPayoutRequests(); 
    },
    methods: {
        async fetchPayoutRequests() {
            try {
                const response = await axios.get("/payout-request");
                this.payoutRequests = response.data.payout_requests.data;
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Impossible de récupérer les données.',
                });
            }
        },

        async validatePayoutRequest(requestId) {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir valider cette demande ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, valider',
                cancelButtonText: 'Annuler',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await axios.put(`/payout-requests/${requestId}/successful`);
                        Swal.fire('Validée!', 'La demande de remboursement a été validée.', 'success');
                        this.fetchPayoutRequests();
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: 'Une erreur est survenue lors de la validation.',
                        });
                    }
                }
            });
        },

        async refusePayoutRequest(requestId) {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir refuser cette demande ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Oui, refuser',
                cancelButtonText: 'Annuler',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await axios.put(`/payout-requests/${requestId}/failed`);
                        Swal.fire('Refusée!', 'La demande de remboursement a été refusée.', 'success');
                        this.fetchPayoutRequests();
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: 'Une erreur est survenue lors du refus.',
                        });
                    }
                }
            });
        },
    },
};
</script>

<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Demande de remboursement</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                            <thead style="background-color: #006666;">
                                                <tr>
                                                    <th scope="col" style="color: white;">N°</th>
                                                    <th scope="col" style="color: white;">CLIENT</th>
                                                    <th scope="col" style="color: white;">SOLDE</th>
                                                    <th scope="col" style="color: white;">MONTANT A REMBOURSSE</th>
                                                    <th scope="col" style="color: white;">STATUT</th>
                                                    <th scope="col" style="color: white;">NUMERO DE RETRAIT</th>
                                                    <th scope="col" style="color: white;">ACTION</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="payoutRequests.length > 0">
                                                <tr v-for="(request, index) in payoutRequests" :key="request.id">
                                                    <td>{{ index + 1 }}</td> 
                                                    <td>{{ request.client.nom }}</td>
                                                    <td>{{ request.balance }}</td>
                                                    <td>{{ request.amount }}</td>
                                                    <td>
                                                        <span v-if="request.status === 'PENDING'" class="badge rounded-pill text-bg-warning">En attente</span>
                                                        <span v-if="request.status === 'SUCCESSFUL'" class="badge rounded-pill text-bg-success">Approuvé</span>
                                                        <span v-if="request.status === 'FAILED'" class="badge rounded-pill text-bg-danger">Rejeté</span>
                                                        <span v-if="request.status === 'EXPIRED'" class="badge rounded-pill text-bg-secondary">Expiré</span>
                                                    </td>
                                                    <td>{{ request.withdraw_number }}</td>
                                                    <td>
                                                        <template v-if="payoutRequests.length > 0">
                                                            <button class="btn btn-success" @click="validatePayoutRequest(request.id)">Valider</button>
                                                            <span>&nbsp;</span>
                                                            <button class="btn btn-danger" @click="refusePayoutRequest(request.id)">Refuser</button>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="7" class="text-center">
                                                        <div class="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
                                                            <i class="fas fa-trash-alt mr-2"></i>
                                                            <span>Aucune demande de remboursement disponible.</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>
<template>
  <div>
    <!-- Composant d'en-tête -->
    <Header />
    <div class="container-fluid">
      <div class="row">
        <!-- Composant de barre latérale -->
        <SideBar />
        <!-- Zone principale du contenu -->
        <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
          <div class="row my-4">
            <div class="col-lg-12 col-12">
              <div class="custom-block bg-white">
                <h5 class="mb-4">PAYLINKS</h5>
                <div class="alert alert-danger" role="alert">
                  Un "Paylink" est un lien de paiement sécurisé que vous pouvez partager avec vos clients. Lorsqu'un
                  client clique sur ce lien, il est redirigé vers une page de paiement où il peut régler le montant
                  spécifié. Cela facilite la collecte de paiements en ligne sans avoir à configurer une page de paiement
                  complexe.
                </div>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#paylinkModal">
                  Ajouter un Paylink
                </button>
              </div>
            </div>
          </div>

          <!-- Modal Bootstrap pour ajouter un Paylink -->
          <div class="modal fade" id="paylinkModal" tabindex="-1" aria-labelledby="paylinkModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="paylinkModalLabel">Ajouter un Paylink</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <form @submit.prevent="submitForm">
                    <!-- Étape 1 du formulaire -->
                    <div v-if="step === 1">
                      <h3 class="form-title">Étape 1:</h3>
                      <div class="form-group">
                        <label for="objet">Objet</label>
                        <input v-model="formData.objet" type="text" class="form-control" id="objet" placeholder="Objet"
                          required />
                        <p class="text-muted">Brève description de votre PayLink qui présente vos attentes et objectifs
                          visés auprès de vos utilisateurs (au plus 150 caractères)</p>
                      </div>

                      <div class="form-group">
                        <label for="mode">Mode</label>
                        <select v-model="formData.mode" class="form-control" id="mode" required>
                          <option disabled value="">Choisir un mode</option>
                          <option value="public">Public</option>
                          <option value="privé">Privé</option>
                        </select>
                        <p class="text-muted">Préciser si votre PayLink est accessible à un groupe restreint de
                          personnes (Amis, Collègues, famille…) ou au contraire à toute personne en possession du lien.
                        </p>
                      </div>

                      <!-- Champ pour entrer une description -->
                      <div class="form-group">
                        <label for="description">Description</label>
                        <textarea v-model="formData.description" class="form-control" id="description"
                          placeholder="Description"></textarea>
                        <p class="text-muted">Donnez plus de détails sur la demande de paiement.</p>
                      </div>

                      <!-- Champ pour entrer le montant minimum -->
                      <div class="form-group">
                        <label for="montant_min">Montant Min</label>
                        <input v-model="formData.montant_min" type="number" class="form-control" id="montant_min"
                          placeholder="Montant Min" />
                        <p class="text-muted">Indiquez le montant minimum à payer.</p>
                      </div>

                      <!-- Bouton pour passer à l'étape suivante -->
                      <button @click="nextStep" class="btn btn-primary mt-3">
                        Suivant
                      </button>
                    </div>

                    <!-- Étape 2 du formulaire -->
                    <div v-if="step === 2">
                      <h3 class="form-title">Étape 2:</h3>
                      <!-- Champ pour entrer la date d'expiration -->
                      <div class="form-group">
                        <label for="expiration_at">Date d'Expiration</label>
                        <input v-model="formData.expiration_at" type="date" class="form-control" id="expiration_at" />
                        <p class="text-muted">Indiquez jusqu'à quelle date ce lien de paiement sera valide.</p>
                      </div>

                      <!-- Champ pour entrer le numéro de retrait -->
                      <div class="form-group">
                        <label for="withdrawal_number">Numéro de Retrait</label>
                        <input v-model="formData.withdrawal_number" type="text" class="form-control"
                          id="withdrawal_number" placeholder="Numéro de Retrait" required />
                        <p class="text-muted">Indiquez le numéro pour les retraits ou transactions associées à ce
                          Paylink.</p>
                      </div>

                      <!-- Boutons pour naviguer entre les étapes -->
                      <button @click="prevStep" class="btn btn-secondary mt-3">
                        Précédent
                      </button>
                      <button @click="nextStep" class="btn btn-primary mt-3">
                        Suivant
                      </button>
                    </div>

                    <!-- Étape 3 du formulaire -->
                    <div v-if="step === 3">
                      <h3 class="form-title">Étape 3: Fichiers et Soumission</h3>
                      <!-- Champ pour télécharger une image (facultatif) -->
                      <div class="form-group">
                        <label for="image">Image (facultatif)</label>
                        <input @change="handleImageUpload" type="file" class="form-control" id="image" />
                        <p class="text-muted">Vous pouvez ajouter une image ou un logo avec le lien de paiement.</p>
                      </div>

                      <!-- Boutons pour soumettre le formulaire ou revenir en arrière -->
                      <button @click="prevStep" class="btn btn-secondary mt-3">
                        Précédent
                      </button>
                      <button type="submit" class="btn btn-primary mt-3">
                        Soumettre
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";

export default {
  components: { Header, Footer, SideBar },
  data() {
    return {
      step: 1,
      formData: {
        objet: "",
        montant_min: "",
        description: "",
        mode: "",
        expiration_at: "",
        withdrawal_number: "",
        image: null,
        client_id: "",
      },
    };
  },
  created() {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        this.formData.client_id = userData.additional_info.id;
      } catch (error) {
        console.error('Erreur lors du parsing de userData depuis sessionStorage:', error);
      }
    }
  },
  methods: {
    nextStep() {
      if (
        this.step === 1 &&
        (!this.formData.objet || !this.formData.montant_min)
      ) {
        Swal.fire({
          icon: "error",
          text: "Veuillez remplir tous les champs requis pour l'étape 1.",
        });
        return;
      }
      if (
        this.step === 2 &&
        (!this.formData.expiration_at || !this.formData.withdrawal_number)
      ) {
        Swal.fire({
          icon: "error",
          text: "Veuillez remplir tous les champs requis pour l'étape 2.",
        });
        return;
      }
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    handleImageUpload(event) {
      this.formData.image = event.target.files[0];
    },
    submitForm() {
      const formData = new FormData();
      for (const key in this.formData) {
        formData.append(key, this.formData[key]);
      }
      axios.post("/add-paylinks", formData, {headers: { "Content-Type": "multipart/form-data" },})
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Paylink créé avec succès !",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Erreur lors de la soumission",
            text: error.response.data.message || "Une erreur est survenue.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.custom-block {
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
</style>

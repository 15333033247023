<template>
  <main class="main" id="top">

    <Head /> <br />
    <div class="container">
      <div class="row justify-content-center align-items-center min-vh-100 py-5">
        <div class="col-md-7">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <h3 class="text-center mb-4">COMPTE CLIENT</h3>
                <form @submit.prevent="registerUser">
                  <div class="mb-3 text-start">
                    <label class="form-label" for="client">Nom</label>
                    <input class="form-control" v-model="nom" id="client" type="text"
                       required />
                    <p v-if="errors.client" class="text-danger">{{ errors.client[0] }}</p>
                  </div>
                  <div class="mb-3 text-start">
                    <label class="form-label" for="telephone">Prénom</label>
                    <input class="form-control" v-model="prenom" id="telephone" type="tel"
                      required />
                    <p v-if="errors.telephone" class="text-danger">{{ errors.telephone[0] }}</p>
                  </div>
                  <div class="mb-3 text-start">
                    <label class="form-label" for="numeromtn">Numéro MTN</label>
                    <input class="form-control" v-model="numeromtn" id="numeromtn" type="text"
                       />
                    <p v-if="errors.numeromtn" class="text-danger">{{ errors.numeromtn[0] }}</p>
                  </div>
                  <div class="mb-3 text-start">
                    <label class="form-label" for="numeromoov">Numéro MOOV</label>
                    <input class="form-control" v-model="numeromoov" id="numeromoov" type="text"
                      />
                    <p v-if="errors.numeromoov" class="text-danger">{{ errors.numeromoov[0] }}</p>
                  </div>
                  <div class="mb-3 text-start">
                    <label class="form-label" for="numeroceltis">Numéro Celtis</label>
                    <input class="form-control" v-model="numeroceltis" id="numeroceltis" type="text"
                       />
                    <p v-if="errors.numeroceltis" class="text-danger">{{ errors.numeroceltis[0] }}</p>
                  </div>
                  <div class="mb-3 text-start">
                    <label class="form-label" for="email">Email</label>
                    <input class="form-control" v-model="email" id="email" type="email" placeholder="name@example.com"
                      required />
                    <p v-if="errors.email" class="text-danger">{{ errors.email[0] }}</p>
                  </div>
                  <div class="mb-3 text-start">
                      <label class="form-label" for="password">Mot de passe</label>
                      <input class="form-control " v-model="password" id="password" type="password"
                        required />
                      <p v-if="errors.password" class="text-danger">{{ errors.password[0] }}</p>
                    
                  </div>
                  <button class="btn btn-secondary w-100 mb-3" style="background-color: #006666;" @click="registerUser"
                    :disabled="loading">
                    <span v-if="loading">Chargement...</span>
                    <span v-else>S'inscrire</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container px-5">
      <div class="row align-items-center justify-content-between flex-column flex-sm-row">
        <div class="col-auto">
          <div class="small m-0">Copyright &copy; Africamoney 2024</div>
        </div>
        <div class="col-auto">
          <a class="small" href="#!" style="color: white;">Confidentialité</a>
          <span class="mx-1">&middot;</span>
          <a class="small" href="#!" style="color: white;">Terms et conditions</a>
          <span class="mx-1">&middot;</span>
          <a class="small" href="#!" style="color: white;">Contact</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "@/axs/axios.js";
import Head from "@/components/Head.vue";
import Swal from 'sweetalert2'; // Importez SweetAlert2

export default {
  components: { Head },
  data() {
    return {
      nom: '',
      prenom: '',
      numeromtn: '',
      numeromoov: '',
      numeroceltis: '',
      email: '',
      password: '',
      loading: false,
      errors: {}
    };
  },
  methods: {
    async registerUser() {
      try {
        this.loading = true;

        const response = await axios.post('/inscription-client', {
          nom: this.nom,
          prenom: this.prenom,
          numeromtn: this.numeromtn,
          numeromoov: this.numeromoov,
          numeroceltis: this.numeroceltis,
          email: this.email,
          password: this.password,
          role_id: 5,
        });

        // Afficher SweetAlert lorsque le compte est créé avec succès
        Swal.fire({
          icon: 'success',
          title: 'Compte créé avec succès!',
          toast: true,
          timer: 3000,
          position: 'top-end',
          showConfirmButton: false
        });

        // Rediriger l'utilisateur vers la page de connexion après la création du compte
        this.$router.push('/connexion');

      } catch (error) {
        console.error('Erreur lors de la requête d\'inscription :', error);
        if (error.response && error.response.status === 422 && error.response.data.errors) {
          this.errors = error.response.data.errors;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>


<style>
/* Styles personnalisés si nécessaire */
</style>

<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
          <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Recherche ( Nom de l'entreprise)</h5>
                            <div class="row mb-4">
                                <div class="col-md-12">
                                    <input v-model="entreprise" @input="fetchEntreprises" type="text"
                                        class="form-control" id="searchDossier">
                                </div>
                            </div>

                            <a class="btn btn-sm btn-primary" href="/admin/ajouter-entreprise">
                                    <i class="bi bi-diagram-2-fill"></i> Ajouter une entreprise
                                </a>
                        </div>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Mes entreprises</h5>
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white;">N°</th>
                                            <th scope="col" style="color: white;">INSRIPTION</th>
                                            <th scope="col" style="color: white;">ENTREPRISE</th>
                                            <th scope="col" style="color: white;">CATÉGORIE</th>
                                            <th scope="col" style="color: white;">TELEPHONE</th>
                                            <th scope="col" style="color: white;">ADRESSE</th>
                                            <th scope="col" class="text-center" style="color: white;">LOGO</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="entreprises.length > 0">

                                        <tr v-for="(entreprise, index) in entreprises" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ new Date(entreprise.created_at).toLocaleString() }}</td>
                                            <td>{{ entreprise.entreprise }}</td>
                                            <td>{{ entreprise.categorie }}</td>
                                            <td>{{ entreprise.telephone }}</td>
                                            <td>{{ entreprise.adresse }}</td>
                                            <td class="text-center">
                                                <img :src="BACK_URL + entreprise.logo"
                                                    class="custom-block-profile-image img-fluid" alt="africamoney"
                                                    style="width: 10%; height: auto;">
                                            </td>

                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="12" class="text-center">
                                                <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                    role="alert">
                                                    <i class="fas fa-trash-alt mr-2"></i>
                                                    <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                    <span>Aucune Entreprise disponible.</span>
                                                    <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                    <i class="fas fa-trash-alt mr-2"></i>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>


                                </table>
                                <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                    class="btn btn-warning">Précédent</button>
                                <span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                    class="btn btn-primary">Suivant</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { BACK_URL } from "@/entreprise_logo.js";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            BACK_URL: BACK_URL,
            entreprises: [],
            entreprise: '',
        };
    },
    created() {
        this.fetchEntreprises();
    },
    methods: {
        fetchEntreprises() {
            axios.get('/entreprises', { params: { entreprise: this.entreprise } })
                .then(response => {
                    this.entreprises = response.data.data.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
};
</script>

<style></style>
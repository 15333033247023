<template>
  <Head/> <br/><br/><br/><br/><br/><br/>
  <div class="container">
    <!-- Lecteur de code QR -->
    <div class="qr-scanner" v-if="qrCodeScannerOpen">
      <qrcode-stream
        :constraints="selectedConstraints"
        @detect="onDetect"
        @camera-on="onCameraReady"
        @error="onError"
      />
    </div>

    <!-- Message d'erreur -->
    <p class="error" v-if="error">{{ error }}</p>

    <!-- Affichage du contenu scanné -->
    <div v-if="result" class="scan-result">
      <h5>Contenu scanné :</h5>
      <p>{{ result }}</p>
    </div>

    <!-- Bouton pour ouvrir le scanner QR -->
     <!-- <div class="scan-button" v-if="!qrCodeScannerOpen">
      <button class="btn btn-primary" @click="startQrScanner">
        <i class="bi bi-camera-fill scan-icon"></i>
        <span class="scan-text">Scanner QR</span>
      </button>
    </div>-->
  </div>
</template>

<script>
import { ref } from 'vue';
import { QrcodeStream } from 'vue-qrcode-reader';
import Head from "@/components/Head.vue";

export default {
  components: { QrcodeStream, Head },
  setup() {
    const qrCodeScannerOpen = ref(true);
    const error = ref('');
    const result = ref('');
    const selectedConstraints = ref({ facingMode: 'environment' });

    const startQrScanner = () => {
      qrCodeScannerOpen.value = true;
      result.value = '';
    };

    const onDetect = (detectedCodes) => {
      if (detectedCodes.length > 0) {
        const firstDetectedCode = detectedCodes[0];
        const qrContent = firstDetectedCode.rawValue;

        console.log('QR Code détecté:', qrContent);
        result.value = qrContent;

        // Vérifie si le contenu scanné est une URL
        if (isValidUrl(qrContent)) {
          // Rediriger vers l'URL scannée
          window.location.href = qrContent;
        } else {
          error.value = 'Le contenu scanné n\'est pas une URL valide';
        }

        qrCodeScannerOpen.value = false;
      }
    };

    const isValidUrl = (string) => {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    };

    const onCameraReady = async () => {
      error.value = '';
    };

    const onError = (err) => {
      error.value = `[${err.name}]: `;

      if (err.name === 'NotAllowedError') {
        error.value += 'Vous devez accorder l\'autorisation d\'accéder à la caméra';
      } else if (err.name === 'NotFoundError') {
        error.value += 'Aucune caméra trouvée sur cet appareil';
      } else if (err.name === 'NotSupportedError') {
        error.value += 'Contexte sécurisé requis (HTTPS, localhost)';
      } else if (err.name === 'NotReadableError') {
        error.value += 'La caméra est déjà utilisée par une autre application';
      } else if (err.name === 'OverconstrainedError') {
        error.value += 'Les caméras installées ne sont pas adaptées';
      } else if (err.name === 'StreamApiNotSupportedError') {
        error.value += 'L\'API Stream n\'est pas prise en charge dans ce navigateur';
      } else if (err.name === 'InsecureContextError') {
        error.value += 'L\'accès à la caméra est uniquement permis dans un contexte sécurisé. Utilisez HTTPS ou localhost au lieu de HTTP.';
      } else {
        error.value += err.message;
      }
    };

    return {
      qrCodeScannerOpen,
      selectedConstraints,
      error,
      result,
      startQrScanner,
      onDetect,
      onCameraReady,
      onError,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-scanner {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  border: 2px dashed #ccc;
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.error {
  font-weight: bold;
  color: red;
  margin: 10px 0;
}

.scan-result {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.scan-icon {
  font-size: 24px;
}

.scan-text {
  margin-top: 5px;
  font-size: 14px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .container {
    padding: 10px;
  }

  .qr-scanner {
    flex-direction: column;
    margin: 10px 0;
  }


  .scan-icon {
    font-size: 20px;
  }

  .scan-text {
    font-size: 12px;
  }
}
</style>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            payoutRequests: [],
            currentPage: 1,
            totalPages: 1,
            isLoading: false,
            error: null,
            clientId: null
        };
    },
    mounted() {
        const userDataString = sessionStorage.getItem('userData');
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.clientId = userData.additional_info.id;
                this.fetchPayoutRequests(this.currentPage);
            } catch (error) {
                console.error('Erreur lors du parsing de userData depuis sessionStorage:', error);
            }
        } else {
            console.error('userData n\'est pas disponible dans sessionStorage');
        }
    },
    methods: {
        async fetchPayoutRequests(page) {
            if (!this.clientId) {
                console.error('clientId non défini');
                return;
            }

            this.isLoading = true;
            try {
                const response = await axios.get(`/payout-request/${this.clientId}?page=${page}`);
                this.payoutRequests = response.data.payout_request.data;
                this.currentPage = response.data.payout_request.current_page;
                this.totalPages = response.data.payout_request.last_page;
                this.error = null;
            } catch (err) {
                this.error = 'Erreur lors du chargement des données.';
                Swal.fire('Erreur', this.error, 'error');
            } finally {
                this.isLoading = false;
            }
        },
        goToPage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.fetchPayoutRequests(page);
            }
        }
    }
};
</script>


<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-4">
                                        <label for="startDate">Date de début</label>
                                        <input type="date" id="startDate" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="endDate">Date de fin</label>
                                        <input type="date" id="endDate" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="searchNumber">Contact</label>
                                        <input type="text" class="form-control" id="searchNumber">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Mes Contacts</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">NUMERO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>


<style></style>
<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-4">
                                        <label for="date">Date d'enregistrement</label>
                                        <input type="date" id="date" class="form-control" v-model="filters.date"
                                            @input="applyFilters">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="searchNumber">Numéro MTN</label>
                                        <input type="text" class="form-control" id="searchNumber"
                                            v-model="filters.telephonemtn" @input="applyFilters">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="searchOuvrier">Nom/Prenom</label>
                                        <input type="text" class="form-control" id="searchOuvrier"
                                            v-model="filters.search" @input="applyFilters">
                                    </div>
                                </div>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <input type="file" @change="handleFileUpload" />
                                <button @click="uploadFile" class="btn btn-sm btn-secondary mt-2">
                                    <i class="bi bi-file-earmark-arrow-up"></i> Importez une liste 
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="ouvrirModalAjoutOuvrier" class="btn btn-sm btn-danger">
                                    <i class="bi bi-plus-circle"></i> Ajouter un ouvrier
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="exportToPDF" class="btn btn-sm btn-primary">
                                    <i class="bi bi-file-earmark-pdf-fill me-2"></i> Téléchargez la liste
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="ouvrirModalPointage" class="btn btn-sm btn-success">
                                    <i class="bi bi-check-circle"></i> Valider le pointage
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="invalidatePointage" class="btn btn-sm btn-warning">
                                    <i class="bi bi-x-circle"></i> Dévalider
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Liste des ouvriers</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th>
                                                    <input class="form-check-input" type="checkbox" id="selectAll"
                                                        @change="toggleSelectAll">
                                                </th>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">NOM & PRENOM</th>
                                                <th scope="col" style="color: white;">ROLE</th>
                                                <th scope="col" style="color: white;">NUMERO MTN</th>
                                                <th scope="col" style="color: white;">NUMERO MOOV</th>
                                                <th scope="col" style="color: white;">NUMERO CELTIS</th>
                                                <th scope="col" style="color: white;">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(ouvrier, index) in filteredOuvriers" :key="index">
                                                <td>
                                                    <input class="form-check-input" type="checkbox"
                                                        v-model="selectedOuvriers" :value="ouvrier.id">
                                                </td>
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ ouvrier.nom }} {{ ouvrier.prenom }}</td>
                                                <td>{{ ouvrier.role }}</td>
                                                <td>{{ ouvrier.telephonemtn }}</td>
                                                <td>{{ ouvrier.telephonemoov }}</td>
                                                <td>{{ ouvrier.telephoneceltis }}</td>
                                                <td>
                                                    <span>&nbsp;</span>
                                                    <button class="btn btn-warning btn-sm">
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                    <span>&nbsp;</span>
                                                    <button class="btn btn-danger btn-sm">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Affichage de la pagination si nécessaire -->
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" :class="{ disabled: !pagination.prev_page_url }">
                                <a class="page-link" href="#" @click.prevent="fetchPreviousPage">
                                    précédent
                                </a>
                            </li>
                            <li class="page-item" v-for="page in pagination.last_page" :key="page"
                                :class="{ active: page === pagination.current_page }">
                                <a class="page-link" href="#" @click.prevent="fetchPage(page)">{{ page }}</a>
                            </li>
                            <li class="page-item" :class="{ disabled: !pagination.next_page_url }">
                                <a class="page-link" href="#" @click.prevent="fetchNextPage">
                                    Suivant
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <Footer />
                </main>
            </div>
        </div>
    </div>

    <!-- Modal pour ajouter un ouvrier -->
    <div class="modal" id="modalAjoutOuvrier" tabindex="-1" aria-labelledby="modalAjoutOuvrierLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalAjoutOuvrierLabel">Ajouter un ouvrier</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="ajouterOuvrier">
                        <div class="mb-3">
                            <label for="nom" class="form-label">Nom</label>
                            <input type="text" class="form-control" id="nom" v-model="nouvelOuvrier.nom" required>
                        </div>
                        <div class="mb-3">
                            <label for="prenom" class="form-label">Prénom</label>
                            <input type="text" class="form-control" id="prenom" v-model="nouvelOuvrier.prenom" required>
                        </div>
                        <div class="mb-3">
                            <label for="role" class="form-label">Rôle</label>
                            <select class="form-select" id="role" v-model="nouvelOuvrier.role" required>
                                <option value="" disabled selected>Sélectionner un rôle</option>
                                <option v-for="(role, index) in roles" :key="index" :value="role">{{ role }}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="numeromtn" class="form-label">Numéro MTN</label>
                            <input type="number" class="form-control" id="numero" v-model="nouvelOuvrier.numeromtn"
                                required>
                        </div>
                        <div class="mb-3">
                            <label for="numeromov" class="form-label">Numéro MOOV</label>
                            <input type="number" class="form-control" id="numero" v-model="nouvelOuvrier.numeromoov"
                                required>
                        </div>
                        <div class="mb-3">
                            <label for="numeroceltis" class="form-label">Numéro CELTIS</label>
                            <input type="number" class="form-control" id="numero" v-model="nouvelOuvrier.numeroceltis"
                                required>
                        </div>
                        <button type="submit" class="btn btn-primary">Enregistrer</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal pour la saisie de la date de pointage -->
    <div class="modal" id="modalDatePointage" tabindex="-1" aria-labelledby="modalDatePointageLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalDatePointageLabel">Saisir la date de pointage</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="validerPointageAvecDate">
                        <div class="mb-3">
                            <label for="date_pointage" class="form-label">Date de Pointage</label>
                            <input type="date" class="form-control" id="date_pointage" v-model="datePointage" required>
                        </div>
                        <button type="submit" class="btn btn-primary">Valider</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            file: null,
            ouvriersImportes: [],
            pagination: {},
            filters: {
                date: '',
                telephonemtn: '',
                search: '',
            },
            nouvelOuvrier: { nom: '', prenom: '', role: '', numeromtn: '', numeromoov: '', numeroceltis: '', date_pointage: '' },
            datePointage: '',
            roles: [
                'Manoeuvre',
                'Maçon',
                'Charpentier',
                'Electricien',
                'Plombier',
                'Peintre',
                'Menuisier',
                'Ingénieur',
                'Architecte',
                'Contremaître',
                'Chef de chantier',
                'Autre'
            ],
            selectedOuvriers: [],
        };
    },
    computed: {
        filteredOuvriers() {
            // Appliquer les filtres aux données ouvriersImportes
            return this.ouvriersImportes.filter(ouvrier => {
                let matchDate = true;
                let matchTelephonemtn = true;
                let matchSearch = true;

                // Filtrer par date d'enregistrement
                if (this.filters.date) {
                    const ouvrierDate = new Date(ouvrier.created_at).toLocaleDateString();
                    matchDate = ouvrierDate === this.filters.date;
                }

                // Filtrer par numéro de téléphone MTN
                if (this.filters.telephonemtn) {
                    matchTelephonemtn = ouvrier.telephonemtn.includes(this.filters.telephonemtn);
                }

                // Filtrer par nom/prénom
                if (this.filters.search) {
                    const search = this.filters.search.toLowerCase();
                    matchSearch = ouvrier.nom.toLowerCase().includes(search) || ouvrier.prenom.toLowerCase().includes(search);
                }

                // Retourner vrai si toutes les conditions sont satisfaites
                return matchDate && matchTelephonemtn && matchSearch;
            });
        }
    },
    mounted() {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        const entrepriseId = userData.entreprise.id;

        if (entrepriseId) {
            this.fetchImportedOuvriers(parseInt(entrepriseId));
        } else {
            console.error('ID de l\'entreprise non trouvé dans la session storage.');
        }
    },
    methods: {
        async fetchImportedOuvriers(entrepriseId, page = 1) {
            try {
                const response = await axios.get(`/ouvriers/importes/${entrepriseId}?page=${page}`);
                this.ouvriersImportes = response.data.ouvriers.data;
                this.pagination = response.data.ouvriers;
            } catch (error) {
                console.error('Erreur lors de la récupération des ouvriers importés :', error);
            }
        },

        handleFileUpload(event) {
            this.file = event.target.files[0];
        },

        async uploadFile() {
            if (!this.file) {
                Swal.fire('Erreur', 'Veuillez sélectionner un fichier à importer.', 'error');
                return;
            }

            try {
                // Récupérer l'ID de l'entreprise depuis sessionStorage
                const userData = JSON.parse(sessionStorage.getItem('userData'));
                const entrepriseId = userData.entreprise.id;

                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('entreprise_id', entrepriseId); // Ajouter l'ID de l'entreprise au FormData

                const response = await axios.post('/import-ouvriers', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                Swal.fire('Succès', response.data.success, 'success');
            } catch (error) {
                Swal.fire('Erreur', 'Une erreur s\'est produite lors de l\'importation du fichier.', 'error');
            }
        },

        // Ouverture du modal pour ajouter un ouvrier
        ouvrirModalAjoutOuvrier() {
            $('#modalAjoutOuvrier').modal('show');
        },
        // Ouverture du modal pour la date de pointage
        ouvrirModalPointage() {
            if (this.selectedOuvriers.length === 0) {
                Swal.fire('Attention', 'Veuillez sélectionner au moins un ouvrier.', 'warning');
                return;
            }
            $('#modalDatePointage').modal('show');
        },
        ajouterOuvrier() {
            axios.post('/ajouter-ouvrier', this.nouvelOuvrier)
                .then(response => {
                    Swal.fire('Succès', 'Ouvrier ajouté avec succès.', 'success');
                    this.nouvelOuvrier = { nom: '', prenom: '', role: '', numero: '', date_pointage: '' };
                    $('#modalAjoutOuvrier').modal('hide');
                })
                .catch(error => {
                    Swal.fire('Erreur', 'Une erreur s\'est produite lors de l\'ajout de l\'ouvrier.', 'error');
                });
        },
        // Méthode pour récupérer la page précédente
        fetchPreviousPage() {
            if (this.pagination.prev_page_url) {
                const page = this.pagination.current_page - 1;
                const entrepriseId = sessionStorage.getItem('entrepriseId');
                this.fetchImportedOuvriers(parseInt(entrepriseId), page);
            }
        },
        // Méthode pour récupérer une page spécifique
        fetchPage(page) {
            const entrepriseId = sessionStorage.getItem('entrepriseId');
            this.fetchImportedOuvriers(parseInt(entrepriseId), page);
        },
        // Méthode pour récupérer la page suivante
        fetchNextPage() {
            if (this.pagination.next_page_url) {
                const page = this.pagination.current_page + 1;
                const entrepriseId = sessionStorage.getItem('entrepriseId');
                this.fetchImportedOuvriers(parseInt(entrepriseId), page);
            }
        },
        // Appliquer les filtres lorsque les champs sont modifiés
        applyFilters() {
            const entrepriseId = sessionStorage.getItem('entrepriseId');
            this.fetchImportedOuvriers(parseInt(entrepriseId));
        },
        // Méthode pour pointer la présence d'un ouvrier
        pointageOuvrier(ouvrierId, datePointage) {
            axios.post('/pointage-ouvrier', { ouvrier_id: ouvrierId, date_pointage: datePointage })
                .then(response => {
                    Swal.fire('Succès', 'Pointage enregistré avec succès.', 'success');
                    this.applyFilters(); // Ré-appliquer les filtres pour rafraîchir la liste
                })
                .catch(error => {
                    Swal.fire('Erreur', 'Une erreur s\'est produite lors de l\'enregistrement du pointage.', 'error');
                });
        },
        // Méthode pour valider le pointage des ouvriers sélectionnés avec la date
        validerPointageAvecDate() {
            if (!this.datePointage) {
                Swal.fire('Attention', 'Veuillez saisir une date de pointage.', 'warning');
                return;
            }
            this.selectedOuvriers.forEach(ouvrierId => {
                this.pointageOuvrier(ouvrierId, this.datePointage);
            });
            $('#modalDatePointage').modal('hide');
        },
        // Méthode pour sélectionner ou désélectionner tous les ouvriers
        toggleSelectAll(event) {
            if (event.target.checked) {
                this.selectedOuvriers = this.filteredOuvriers.map(ouvrier => ouvrier.id);
            } else {
                this.selectedOuvriers = [];
            }
        }
    },

    exportToPDF() {
            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir exporter ces données en PDF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Exporter',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();
                    let totalPages = 1;

                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png'; // Changez cette ligne pour correspondre à votre logo
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);
                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 17.5;
                    const text = 'LISTE DES OUVRIERS';
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });

                    if (this.filters.date_pointage) {
                        const periodTextY = middleY + 10;
                        doc.text(periodText, middleX, periodTextY, { align: 'center', color: '#ffffff' });
                    }
                    doc.setTextColor(0, 0, 0);

                    const tableData = this.ouvriersImportes.map((ouvrier, index) => [
                        index + 1,
                        `${ouvrier.nom} ${ouvrier.prenom}`,
                        ouvrier.role,
                        ouvrier.telephonemtn,
                        ouvrier.telephonemoov,
                        ouvrier.telephoneceltis,
                    ]);

                    const headers = ['N°', 'NOM & PRENOM', 'ROLE', 'TELEPHONE MTN', 'TELEPHONE MOOV', 'TELEPHONE CELTIS'];

                    doc.autoTable({
                        head: [headers],
                        body: tableData,
                        headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff', fontSize: 8 },
                        bodyStyles: { fontSize: 7 },
                        startY: middleY + 30,
                        didDrawPage: function (data) {
                            totalPages = doc.internal.getNumberOfPages();
                            const bottomMargin = 10;
                            doc.setFontSize(6);
                            doc.text("Page " + data.pageNumber + " sur " + totalPages, 14, doc.internal.pageSize.height - bottomMargin);
                            const currentDate = new Date();
                            const dateString = currentDate.toLocaleDateString();
                            const timeString = currentDate.toLocaleTimeString();
                            doc.text("imprimé le: " + dateString + " à " + timeString, 14, doc.internal.pageSize.height - bottomMargin + 5);
                        },
                    });

                    doc.save('liste_des_ouvriers.pdf');

                    Swal.fire({
                        title: 'Succès !',
                        text: 'L\'exportation en PDF a réussi.',
                        icon: 'success',
                    });
                }
            });
        }

};
</script>



<style scoped>
/* Ajoutez vos styles ici si nécessaire */
</style>

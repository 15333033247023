<template>
  <div class="container">
    <header class="navbar sticky-top flex-md-nowrap bg-dark">
      <div class="col-md-3 col-lg-3 me-0 px-3 fs-6">
        <a class="navbar-brand" href="/">
          <h6 class="text-white text-center">Détails de l'entreprise</h6>
        </a>
      </div>
    </header>

    <main class="main">

      <Head />

      <div class="col-lg-12 col-12">
        <div
          class="custom-block custom-block-profile-front custom-block-profile text-center bg-white d-flex flex-column align-items-center justify-content-center"
          v-if="entreprise">
          <div class="image-container">
            <img :src="BACK_URL + entreprise.logo" alt="Logo de l'entreprise" />
          </div>

          <h4>{{ entreprise.entreprise }}</h4>

          <div class="text-center"> <br />
            <h6><i>“ Merci beaucoup pour votre généreuse contribution à notre bonheur. Nous garderons à jamais en
                mémoire votre geste si attentionné. ”</i></h6>

          </div>

          <button class="btn btn-success mt-4" @click="showSupportModal"
            style="background-color: #006666; border-color: #006666; font-size: 1.25rem; padding: 10px 20px;">
            Me soutenir <i class="bi bi-gift me-2"></i>
          </button>

        </div>
      </div>
    </main>

    <!-- Modal -->
    <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #006666">
            <h5 class="modal-title" id="supportModalLabel" style="color: white">
              NOUVEAU PAIEMENT
            </h5>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a class="nav-link" :class="{
                  active: selectedTab === 'MTN',
                  '': selectedTab === 'MTN',
                }" @click.prevent="handleTabClick('MTN')" href="#">
                  <img src="/images/logomtn.svg" alt="MTN" style="height: 50px; margin-right: 28px" />

                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{
                  active: selectedTab === 'MOOV',
                  '': selectedTab === 'MOOV',
                }" @click.prevent="handleTabClick('MOOV')" href="#">
                  <img src="/images/logomoov.png" alt="MOOV" style="height: 50px; margin-right: 28px" />

                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{
                  active: selectedTab === 'CELTIIS',
                  '': selectedTab === 'CELTIIS',
                }" @click.prevent="handleTabClick('CELTIIS')" href="#">
                  <img src="/images/celtislogo.png" alt="CELTIIS" style="height: 50px; margin-right: 28px" />

                </a>
              </li>
            </ul>
            <div v-if="isUnavailable" class="alert alert-info mt-3" role="alert">
              Non disponible
            </div>
            <div v-else>
              <!-- Formulaire pour entrer les détails de paiement -->
              <div class="mb-3">
                <label for="nom" class="form-label">Nom</label>
                <input type="text" class="form-control" id="nom" v-model="nom" placeholder="Entrez votre nom"
                  required />
              </div>
              <div class="mb-3">
                <label for="prenom" class="form-label">Prénom</label>
                <input type="text" class="form-control" id="prenom" v-model="prenom" placeholder="Entrez votre prénom"
                  required />
              </div>
              <div class="mb-3">
                <label for="amount" class="form-label">Montant</label>
                <input type="number" class="form-control" id="amount" v-model="amount" min="1" />
              </div>
              <div class="mb-3">
                <label for="paymentNumber" class="form-label">Numéro de paiement</label>
                <input type="text" v-model="paymentNumber" @blur="validateAndPrefixNumber" class="form-control"
                  id="paymentNumber" placeholder="Ex:00000000" required />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="processPayment"
              style="background-color: #006666; border-color: #006666;"> Envoyer
            </button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
              Quitter
            </button>
          </div>
        </div>
      </div>
    </div>

    <br /><br /><br />

    <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
            <div class="col-auto">
                <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
            </div>
            <div class="col-auto">
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
            </div>
        </div>
    </div>
</footer>


  </div>
</template>
<script>
import axios from "@/axs/axios.js";
import Head from "@/components/Head.vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { BACK_URL } from "@/entreprise_logo.js";
import Swal from "sweetalert2";

export default {
  components: { Head },
  setup() {
    const route = useRoute();
    const entreprise = ref(null);
    const amount = ref(1);
    const paymentNumber = ref("");
    const currency = ref("XOF");
    const externalId = ref("12345678");
    const payer = ref({
      partyIdType: "MSISDN",
      partyId: "",
    });

    const selectedTab = ref("MTN");
    const nom = ref("");  // Ajout du champ nom
    const prenom = ref("");  // Ajout du champ prénom
    const entrepriseId = ref(null);  // Ajout de l'ID de l'entreprise
    const isCheckingStatus = ref(false);
    const isProcessingPayment = ref(false);
    const paymentReferenceId = ref(null); // Ajout pour stocker l'ID de référence du paiement
    const isUnavailable = ref(false);  // Nouvelle variable pour gérer l'affichage du message

    const validateAndPrefixNumber = () => {
      if (paymentNumber.value && !paymentNumber.value.startsWith("229")) {
        paymentNumber.value = `229${paymentNumber.value}`;
      }
    };

    const fetchEntrepriseDetails = async (id) => {
      try {
        const response = await axios.get(`/getentreprisedetails/${id}`);
        entreprise.value = response.data.data;
        entrepriseId.value = entreprise.value.id;  // Assurez-vous que l'ID est stocké
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de l'entreprise:",
          error
        );
      }
    };

    const showSupportModal = () => {
      const supportModal = new bootstrap.Modal(
        document.getElementById("supportModal")
      );
      supportModal.show();
    };

    const handleTabClick = (tab) => {
      if (tab === 'MOOV' || tab === 'CELTIIS') {
        isUnavailable.value = true;
      } else {
        isUnavailable.value = false;
      }
      selectedTab.value = tab;
    };

    const processPayment = async () => {
      if (amount.value <= 0 || !paymentNumber.value) {
        Swal.fire({
          icon: "warning",
          title: "Champs invalides",
          text: "Veuillez entrer un montant positif et un numéro de paiement.",
        });
        return;
      }

      payer.value.partyId = paymentNumber.value;

      Swal.fire({
        title: "Paiement en cours",
        text: "Votre paiement est en cours de traitement, veuillez patienter...",
        icon: "info",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const data = {
          amount: amount.value,
          paymentNumber: paymentNumber.value,
          currency: currency.value,
          externalId: externalId.value,
          payer: payer.value,
          nomdestinataire: `${nom.value} ${prenom.value}`,
          entreprise_id: entrepriseId.value,
          mode: 1,
          numero: paymentNumber.value,
          operateur: 'MTN'
        };

        console.log(data);
        const response = await axios.post("/process-payment", data);

        if (response.status === 200 || response.status === 202) {
          paymentReferenceId.value = response.data.paiement.referenceId;
          console.log(response.data.paiement.referenceId);
          startCheckingStatus(paymentReferenceId.value);

          Swal.close();
          Swal.fire({
            text: `Paiement de ${amount.value} XOF initié. En attente du paiement sur votre télephone!`,
            icon: "info",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erreur lors du paiement",
          text: "Une erreur est survenue. Veuillez réessayer.",
        });
        console.error("Erreur lors du paiement :", error);
      }
    };

    const startCheckingStatus = (referenceId) => {
      isCheckingStatus.value = true;
      const maxDuration = 60000;
      const interval = 5000;
      const startTime = Date.now();

      const checkStatus = async () => {
        if (!isCheckingStatus.value) return;

        try {
          const response = await axios.get(`/check-payment-status/${referenceId}`);
          if (response.status === 200) {
            const status = response.data.status;
            if (status === 'SUCCESSFUL') {
              Swal.fire({
                icon: 'success',
                title: 'Paiement réussi',
                text: 'Merci beaucoup pour votre généreuse contribution à notre bonheur. Nous garderons à jamais en mémoire votre geste si attentionné.',
                confirmButtonColor: '#006666',
              });

              resetForm();
              $('#addcard').modal('hide');
              isProcessingPayment.value = false;
              stopCheckingStatus();
            } else if (status === 'FAILED') {
              Swal.fire({
                icon: 'error',
                title: 'Paiement échoué',
                text: 'Votre paiement a échoué. Veuillez réessayer.',
                confirmButtonColor: '#006666',
              });
              isProcessingPayment.value = false;
              stopCheckingStatus();
            } else if (status === 'PENDING' && (Date.now() - startTime) < maxDuration) {
              setTimeout(() => checkStatus(), interval);
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Délai d\'attente dépassé',
                text: 'Le paiement est toujours en cours. Veuillez réessayer.',
                confirmButtonColor: '#006666',
              });
              isProcessingPayment.value = false;
              stopCheckingStatus();
            }
          }
        } catch (error) {
          console.error('Erreur lors de la vérification du statut du paiement :', error);
          if ((Date.now() - startTime) < maxDuration) {
            setTimeout(() => checkStatus(), interval);
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Délai d\'attente dépassé',
              text: 'Le paiement est toujours en cours. Veuillez réessayer.',
              confirmButtonColor: '#006666',
            });
            isProcessingPayment.value = false;
            stopCheckingStatus();
          }
        }
      };

      checkStatus();
    };

    const stopCheckingStatus = () => {
      isCheckingStatus.value = false;
    };

    const resetForm = () => {
      // Réinitialiser les champs du formulaire si nécessaire
      amount.value = 1;
      paymentNumber.value = "";
      currency.value = "XOF";
      externalId.value = "12345678";
      payer.value.partyId = "";
      nom.value = "";
      prenom.value = "";
      entrepriseId.value = null;
    };

    onMounted(() => {
      fetchEntrepriseDetails(route.params.id);
    });

    return {
      entreprise,
      amount,
      paymentNumber,
      currency,
      externalId,
      payer,
      selectedTab,
      nom,
      prenom,
      entrepriseId,
      showSupportModal,
      processPayment,
      validateAndPrefixNumber,
      startCheckingStatus,
      stopCheckingStatus,
      BACK_URL: BACK_URL,
      resetForm,
      handleTabClick, // Expose the new method
      isUnavailable // Expose the new variable
    };
  },
};
</script>

<style scoped>
.image-container {
  width: 110%;
  text-align: center;
}

.image-container img {
  max-width: 110%;
  height: auto;
  display: block;
  margin: 0 auto;
}
</style>

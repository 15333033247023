<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            reference: '', // Ajoutez une propriété pour stocker la référence du dossier
            dossier: null // Ajoutez une propriété pour stocker les données du dossier
        };
    },
    methods: {
        searchDossier() {
            // Vérifiez si la référence du dossier est vide
            if (!this.reference) {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Veuillez entrer la référence du dossier pour afficher les transactions.'
                });
                return;
            }

            // Effectuez la requête API pour rechercher les dossiers avec les dates
            axios.get(`/dossiers-with-dates?reference=${this.reference}`)
                .then(response => {
                    // Mettez à jour les données du dossier avec les données reçues de l'API
                    this.dossier = response.data.data;
                })
                .catch(error => {
                    console.error('Erreur lors de la recherche du dossier:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: 'Une erreur s\'est produite lors de la recherche du dossier.'
                    });
                });
        },

        exportToPDF() {
            if (!this.dossier) return;

            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir télécharger le reçu ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();

                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');

                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png';
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);

                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 20;
                    const text = `RAPPORT DU DOSSIER: ${this.dossier.reference}`;
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });

                    doc.setTextColor(0, 0, 0);

                    // Vérifiez si les dates sont présentes dans les données du dossier
                    if (this.dossier.dates && this.dossier.dates.length > 0) {
                        const datesData = this.dossier.dates.map(date => [date]);
                        doc.autoTable({
                            head: [['Dates générées']],
                            body: datesData,
                            startY: 50,
                            headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff' },
                        });
                    } else {
                        // Si les dates ne sont pas disponibles, affichez un message dans le PDF
                        doc.text('Aucune date générée disponible', middleX, 50, { align: 'center' });
                    }

                    doc.save('rapport_dossier.pdf');
                }
            });
        }


    }
};
</script>

<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <form @submit.prevent="searchDossier">
                        <div class="mb-3">
                            <label for="reference" class="form-label">Reference</label>
                            <input type="text" class="form-control" id="reference" v-model="reference">
                        </div>
                        <button type="submit" class="btn btn-primary">Rechercher</button>
                    </form> <br />
                    <button @click="confirmExportCSV" class="btn btn-sm btn-warning">
                        <i class="bi bi-file-earmark-spreadsheet"></i> Export CSV
                    </button>
                    <span>&nbsp;</span>
                    <button @click="exportToPDF" class="btn btn-sm btn-danger"><i
                            class="bi bi-file-earmark-pdf"></i>Export
                        PDF</button>
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Historiques des transactions</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">EXPEDITEUR</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                                <th scope="col" style="color: white;">OPERATION</th>
                                                <th scope="col" style="color: white;">GSM</th>
                                                <th scope="col" style="color: white;">MESSAGE</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="dossier">
                                            <!-- Utilisez une boucle v-for pour afficher chaque transaction -->
                                            <tr v-for="(transaction, index) in dossier.transactions" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ transaction.datetransaction }}</td>
                                                <td>{{ transaction.nomdestinataire }}</td>
                                                <td>{{ transaction.montant }}</td>
                                                <td>
                                                    <span
                                                        v-if="transaction.mode === 1 || transaction.mode === 2">RETRAIT</span>
                                                    <span v-else-if="transaction.mode === 3">DEPOT</span>
                                                    <span v-else>{{ transaction.mode }}</span>
                                                </td>
                                                <td>
                                                    <img v-if="transaction.operateur === 'MTN MONEY'"
                                                        src="/../../images/MOMOPAY.png" alt="MTN MONEY"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'MOOV MONEY'"
                                                        src="/../../images/MOOVFLOOZ.png" alt="MOOV MONEY"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'CELTIS CASH'"
                                                        src="/../../images/LOGOCELTIS.png" alt="CELTIS CASH"
                                                        style="width:35px; height:34px;">
                                                    <span v-else>{{ transaction.operateur }}</span>
                                                </td>
                                                <td>{{ transaction.smsrecu }}</td>
                                            </tr>
                                        </tbody>

                                        <tbody v-else>
                                            <tr>
                                                <td colspan="12" class="text-center">
                                                    <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                        role="alert">
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <span>Veuillez entrez la réference du dossier pour afficher les
                                                            transactions.</span>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                        class="btn btn-warning">Précédent</button>
                                    <span>&nbsp;</span><span>&nbsp;</span>
                                    <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                        class="btn btn-primary">Suivant</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { useRouter } from 'vue-router';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            dossiers: [],
            debiteurs: [],
            nouveauDossier: {
                reference: '',
                periodicite: '',
                libelle: '',
                montant: '',
                a_payer: '',
                paiement_cash: '',
                penalite: '',
                date_debut: '',
                date_fin: '',
                nbrjourremboursement: '',
                debiteur_id: ''
            },

            dossierEnEdition: {
                reference: '',
                libelle: '',
                montant: '',
                a_payer: '',
                paiement_cash: '',
                penalite: '',
                date_debut: '',
                date_fin: '',
            },
        };
    },
    mounted() {
        this.fetchDossiers();
        this.fetchActeurs();
    },
    methods: {
        async fetchDossiers() {
            try {
                const response = await axios.get("/dossiers");
                this.dossiers = response.data.dossiers.data;
                console.log(this.dossiers);
            } catch (error) {
                console.error("Erreur lors de la récupération des dossiers", error);
            }
        },

        async ajouterDossier() {
            const sessionData = JSON.parse(sessionStorage.getItem('userData'));
            const userAfricamoneyId = sessionData.id;
            this.nouveauDossier.user_africamoney_id = userAfricamoneyId;

            try {
                const response = await axios.post("/dossiers", this.nouveauDossier);
                console.log("Dossier ajouté avec succès :", response.data);

                this.nouveauDossier = {
                    reference: '',
                    periodicite: '',
                    libelle: '',
                    montant: '',
                    a_payer: '',
                    paiement_cash: '',
                    penalite: '',
                    date_debut: '',
                    date_fin: '',
                    nbrjourremboursement: '',
                    debiteur_id: ''
                };

                $('#ajouterDossierModal').modal('hide');

                Swal.fire({
                    icon: 'success',
                    title: 'Dossier Créer avec succès',
                    showConfirmButton: false,
                    timer: 1500
                });

                this.fetchDossiers();
            } catch (error) {
                if (error.response.status === 422) {
                    // Afficher le message d'erreur dans un SweetAlert
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: error.response.data.message
                    });
                } else {
                    console.error("Erreur lors de l'ajout du dossier :", error);
                }
            }
        },

        modifierDossier(id) {

            axios.get(`/dossiers/${id}`)
                .then(response => {
                    this.dossierEnEdition = response.data;
                    $('#modifierDossierModal').modal('show');
                })
                .catch(error => {
                    console.error("Erreur lors du chargement des détails du dossier :", error);
                });
        },

        cloturerDossier(id) {
            const dossier = this.dossiers.find(d => d.id === id);
            if (dossier.statut === 'En cours') {
                Swal.fire({
                    title: 'Êtes-vous sûr de vouloir clôturer ce dossier ?',
                    text: 'Cette action est irréversible !',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, clôturer',
                    cancelButtonText: 'Annuler'
                }).then((result) => {
                    if (result.isConfirmed) {
                         axios.put(`/dossiers/${id}/terminer`)
                            .then(response => {
                                this.fetchDossiers();
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Dossier clôturé avec succès',
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            })
                            .catch(error => {
                                console.error("Erreur lors de la clôture du dossier :", error);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Erreur lors de la clôture du dossier',
                                    text: error.message
                                });
                            });
                    }
                });
            } else {
                // Afficher un message indiquant que le dossier ne peut pas être clôturé
                Swal.fire({
                    icon: 'warning',
                    title: 'Impossible de clôturer ce dossier',
                    text: 'Le dossier n\'est pas en cours.'
                });
            }
        },


        sauvegarderModification() {
            axios.put(`/dossiers/${this.dossierEnEdition.id}`, this.dossierEnEdition)
                .then(response => {
                    $('#modifierDossierModal').modal('hide');
                    Swal.fire({
                        icon: 'success',
                        title: 'Dossier modifié avec succès',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.fetchDossiers();
                })
                .catch(error => {
                    console.error("Erreur lors de la sauvegarde des modifications du dossier :", error);
                });
        },

        goBack() {
            this.$router.back();
        },

        calculerMontantAPayer() {
            const montant = parseFloat(this.nouveauDossier.montant);
            const nbrJours = parseFloat(this.nouveauDossier.nbrjourremboursement);
            const paiementCash = parseFloat(this.nouveauDossier.paiement_cash);

            if (!isNaN(montant) && !isNaN(nbrJours) && !isNaN(paiementCash)) {
                let aPayer = (montant - paiementCash) / nbrJours;
                aPayer = Math.max(0, aPayer);
                this.nouveauDossier.a_payer = aPayer.toFixed(2);

            } else {
                this.nouveauDossier.a_payer = '';
            }
        },

        supprimerDossier(id) {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir supprimer ce dossier ?',
                text: "Cette action est irréversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Oui, supprimer',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/dossiers/${id}`)
                        .then(response => {

                            Swal.fire({
                                icon: 'success',
                                title: 'Dossier supprimé avec succès',
                                showConfirmButton: false,
                                timer: 1500
                            });
                            this.fetchDossiers();
                        })
                        .catch(error => {
                            console.error("Erreur lors de la suppression du dossier :", error);
                            Swal.fire({
                                icon: 'error',
                                title: 'Erreur lors de la suppression du dossier',
                                text: error.message
                            });
                        });
                }
            });
        },

        fetchActeurs() {
            axios.get("/debiteurs")
                .then(response => {
                    this.debiteurs = response.data.debiteurs;
                    console.log(response.data.debiteurs);
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des acteurs", error);
                });
        },
    }
};
</script>

<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
          <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Recherche</h5>
                            <div class="row mb-4">
                                <div class="col-md-12">
                                    <label for="searchDossier">Dossier</label>
                                    <input v-model="reference" @input="fetchDossiers" type="text" class="form-control"
                                        id="searchDossier">
                                </div>
                            </div>
                            <button type="button" @click="goBack()" class="btn btn-outline-success text-center"><i
                                    class="bi bi-arrow-return-left"></i></button>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                data-bs-target="#ajouterDossierModal">
                                <i class="fas fa-plus"></i> Nouveau dossier
                            </button>
                            <span>&nbsp;</span>

                            <a class="btn btn-sm btn-success" href="/entreprise/gerer">
                                <i class="bi bi-arrow-repeat me-2"></i> Relance
                            </a>
                            <span>&nbsp;</span>
                            <a class="btn btn-sm btn-danger" href="/entreprise/gerer">
                                <i class="bi bi-arrow-folder me-2"></i> Transacton
                            </a>

                            <span>&nbsp;</span>
                            <a class="btn btn-sm btn-warning" href="/entreprise/suivi">
                                <i class="bi bi-journal-check me-2"></i> Suivi
                            </a>
                        </div>
                    </div>
                </div>


                <div class="modal fade" id="ajouterDossierModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header" style="background-color: #006666;">
                                <h1 class="modal-title fs-5 text-center" id="exampleModalLabel" style="color: white;">
                                    Nouveau dossier</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>

                            <div class="modal-body">
                                <form @submit.prevent="ajouterDossier">
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="reference" class="col-form-label">Référence:</label>
                                            <input type="text" class="form-control" id="reference"
                                                v-model="nouveauDossier.reference" required>
                                        </div>

                                        <div class="col-md-6">
                                            <label for="libelle" class="col-form-label">Objet:</label>
                                            <select class="form-select" id="libelle" v-model="nouveauDossier.libelle">
                                                <option value="" disabled selected hidden>Sélectionnez l'objet</option>
                                                <option value="achat">Achat</option>
                                                <option value="crédit">Crédit</option>
                                                <option value="epargne">Épargne</option>
                                                <option value="loyer">Loyer</option>
                                                <option value="remboursement prêt">Remboursement Prêt</option>
                                                <option value="remboursement dette">Remboursement Dette</option>
                                                <option value="scolarité">Scolarité</option>
                                                <option value="tontine">Tontine</option>
                                                <option value="autre">Autre</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="periodicite" class="col-form-label">Périodicité du
                                                paiement:</label>
                                            <select class="form-select" id="periodicite"
                                                v-model="nouveauDossier.periodicite" required>
                                                <option value="journalier">Journalier</option>
                                                <option value="hebdomadaire">Hebdomadaire</option>
                                                <option value="mensuel">Mensuel</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="montant" class="col-form-label">Montant à rembourser:</label>
                                            <input type="number" class="form-control" id="montant"
                                                v-model="nouveauDossier.montant" @input="calculerMontantAPayer"
                                                required>
                                        </div>
                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="a_payer" class="col-form-label">Montant à payer:</label>
                                            <input type="text" class="form-control" id="a_payer"
                                                v-model="nouveauDossier.a_payer" required disabled>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="paiement_cash" class="col-form-label">Paiement cash:</label>
                                            <input type="text" class="form-control" id="paiement_cash"
                                                v-model="nouveauDossier.paiement_cash" @input="calculerMontantAPayer"
                                                required>
                                        </div>
                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="penalite" class="col-form-label">Pénalité de retard (%):</label>
                                            <input type="text" class="form-control" id="penalite"
                                                v-model="nouveauDossier.penalite" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="nbrjourremboursement" class="col-form-label">Durée du
                                                remboursement:</label>
                                            <input type="text" class="form-control" id="nbrjourremboursement"
                                                v-model="nouveauDossier.nbrjourremboursement"
                                                @input="calculerMontantAPayer" required>
                                        </div>

                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="date_debut" class="col-form-label">Date de début:</label>
                                            <input type="date" class="form-control" id="date_debut"
                                                v-model="nouveauDossier.date_debut" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="date_fin" class="col-form-label">Date de fin:</label>
                                            <input type="date" class="form-control" id="date_fin"
                                                v-model="nouveauDossier.date_fin" required>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label for="debiteur" class="col-form-label">Client:</label>
                                        <select class="form-select" id="debiteur" v-model="nouveauDossier.debiteur_id"
                                            required>
                                            <option v-for="debiteur in debiteurs.data" :key="debiteur.id"
                                                :value="debiteur.id">{{ debiteur.nom }} {{ debiteur.prenoms }}
                                            </option>
                                        </select>
                                    </div>

                                    <button type="submit" class="btn btn-success">Enregistrer</button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="modal fade" id="modifierDossierModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header" style="background-color: #006666;">
                                <h1 class="modal-title fs-5 text-center" id="exampleModalLabel" style="color: white;">
                                    Modifier le dossier</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>

                            <div class="modal-body">
                                <form>
                                    <div class="mb-3">
                                        <label for="reference" class="col-form-label">Référence:</label>
                                        <input type="text" class="form-control" id="reference"
                                            v-model="dossierEnEdition.reference">
                                    </div>
                                    <div class="mb-3">
                                        <label for="libelle" class="col-form-label">Objet:</label>
                                        <input type="text" class="form-control" id="libelle"
                                            v-model="dossierEnEdition.libelle">
                                    </div>
                                    <!-- Ajoutez d'autres champs de formulaire pour les autres champs à modifier -->
                                </form>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                <button type="button" class="btn btn-primary"
                                    @click="sauvegarderModification">Enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Dossiers</h5>

                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white;">N°</th>
                                            <th scope="col" style="color: white;">Réference</th>
                                            <th scope="col" style="color: white;">Périodicité</th>
                                            <th scope="col" style="color: white;">Début</th>
                                            <th scope="col" style="color: white;">Fin</th>
                                            <th scope="col" style="color: white;">Montant</th>
                                            <th scope="col" style="color: white;">Status</th>
                                            <th scope="col" style="color: white;">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(dossier, index) in dossiers" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ dossier.reference }}</td>
                                            <td>{{ dossier.periodicite }}</td>
                                            <td>{{ dossier.date_debut }}</td>
                                            <td>{{ dossier.date_fin }}</td>
                                            <td>{{ dossier.montant }}</td>
                                            <td>
                                                <span v-if="dossier.statut === 'En cours'"
                                                    class="badge text-bg-warning">
                                                    {{ dossier.statut }}
                                                </span>
                                                <span v-else-if="dossier.statut === 'Terminé'"
                                                    class="badge text-bg-success">
                                                    {{ dossier.statut }}
                                                </span>
                                                <span v-else-if="dossier.statut === 'Defaut'"
                                                    class="badge text-bg-danger">
                                                    {{ dossier.statut }}
                                                </span>
                                            </td>

                                            <td>
                                                <a :href="'/entreprise/dossier-details/' + dossier.id"
                                                    class="btn btn-info btn-sm">
                                                    <i class="fas fa-eye"></i>
                                                </a>
                                                <span>&nbsp;</span>
                                                <button @click="modifierDossier(dossier.id)"
                                                    class="btn btn-warning btn-sm">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <span>&nbsp;</span>
                                                <button @click="supprimerDossier(dossier.id)"
                                                    class="btn btn-danger btn-sm">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                                <span>&nbsp;</span>
                                                <button @click="cloturerDossier(dossier.id)"
                                                    class="btn btn-success btn-sm">
                                                    <i v-if="dossier.statut === 'En cours'" class="fas fa-unlock"></i>
                                                    <i v-else class="fas fa-lock"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</template>

<style></style>
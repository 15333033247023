<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { BACK_URL } from "@/transaction_documents.js";


export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            transactions: [],
            date_debut: '',
            date_fin: '',
            numero: '',
            destinataire: '',
            currentPage: 1,
            totalPages: 0,
            BACK_URL: BACK_URL,
        };
    },
    mounted() {

        this.fetchTransactions();

        setInterval(() => {
            this.fetchTransactions();
        }, 3000);
    },

    methods: {
        fetchTransactions() {
            const params = {
                date_debut: this.startDate,
                date_fin: this.endDate,
                numero: this.numero,
                destinataire: this.destinataire,
                page: this.currentPage
            };

            axios.get("/transactions-entreprise", { params })
                .then(response => {
                    this.transactions = response.data.data.data;
                    this.currentPage = response.data.data.current_page;
                    this.totalPages = response.data.data.last_page;
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des transactions filtrées", error);
                });
        },


        fetchPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchTransactions();
            }
        },

        fetchNextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchTransactions();
            }
        },


        exportCSV() {
            Swal.fire({
                title: 'Êtes-vous sûr?',
                text: "Vous êtes sur le point d'exporter les transactions au format CSV.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, exporter!',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const csvContent = this.generateCSV();
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'transactions.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    Swal.fire(
                        'Exporté!',
                        'Le fichier CSV a été exporté avec succès.',
                        'success'
                    );
                }
            });
        },

        generateCSV() {
            const header = ['N°', 'Date', 'Expéditeur', 'Montant', 'Opération', 'Gsm', 'Message'].join(',');
            const rows = this.transactions.map((transaction, index) => {
                return [
                    index + 1,
                    transaction.datetransaction,
                    `${transaction.nomdestinataire} (${transaction.numero})`,
                    transaction.montant,
                    transaction.mode,
                    transaction.operateur,
                    transaction.smsrecu,
                ].join(',');
            });
            return [header, ...rows].join('\n');
        },

        exportToPDF() {
            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir exporter ces données en PDF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Exporter',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();
                    let totalPages = 1;
        
                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png';
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);
                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 17.5;
                    const text = 'RELEVE DES TRANSACTIONS';
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });
        
                    if (this.startDate && this.endDate) {
                        const formattedStartDate = moment(this.startDate).format('DD/MM/YYYY');
                        const formattedEndDate = moment(this.endDate).format('DD/MM/YYYY');
                        const periodText = `PÉRIODE: du ${formattedStartDate} au ${formattedEndDate}`;
                        const periodTextY = middleY + 10;
                        doc.text(periodText, middleX, periodTextY, { align: 'center', color: '#ffffff' });
                    }
                    doc.setTextColor(0, 0, 0);

                    const tableData = this.transactions.map((transaction, index) => [
                        index + 1,
                        transaction.datetransaction,
                        transaction.nomdestinataire,
                        transaction.montant,
                        transaction.numero,
                        transaction.operateur,
                        transaction.smsrecu,
                    ]);
        
                    const headers = ['N°', 'DATE', 'EXPEDITEUR', 'MONTANT', 'NUMERO', 'OPERATEUR', 'SMS'];
        
                    // Calcul des totaux par opérateur pour les dépôts et les retraits
                    const totalsByOperator = this.transactions.reduce((acc, transaction) => {
                        if (!acc[transaction.operateur]) {
                            acc[transaction.operateur] = { depot: 0, retrait: 0 };
                        }
                        if (transaction.mode === 3) {
                            acc[transaction.operateur].depot += parseFloat(transaction.montant);
                        } else if (transaction.mode === 2) {
                            acc[transaction.operateur].retrait += parseFloat(transaction.montant);
                        }
                        return acc;
                    }, {});
        
                    doc.autoTable({
                        head: [headers],
                        body: tableData,
                        headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff', fontSize: 8 },
                        bodyStyles: { fontSize: 7 },
                        startY: middleY + 30,
                        didDrawPage: function (data) {
                            totalPages = doc.internal.getNumberOfPages();
                            const bottomMargin = 10;
                            doc.setFontSize(6);
                            doc.text("Page " + data.pageNumber + " sur " + totalPages, 14, doc.internal.pageSize.height - bottomMargin);
                            const currentDate = new Date();
                            const dateString = currentDate.toLocaleDateString();
                            const timeString = currentDate.toLocaleTimeString();
                            doc.text("imprimé le: " + dateString + " à " + timeString, 14, doc.internal.pageSize.height - bottomMargin + 5);
                        },
                    });
        
                    // Ajout des totaux à la fin du document
                    const finalY = doc.previousAutoTable.finalY + 10; // Positionnement après le tableau
                    doc.setFontSize(8);
                    doc.setTextColor('#000');
                    doc.setFont('helvetica', 'bold'); // Police en gras
                    let totalsY = finalY;
        
                    const leftMargin = 14; // Positionnement à gauche
                    const rightMargin = doc.internal.pageSize.width - 14; // Positionnement à droite
        
                    for (const [operator, totals] of Object.entries(totalsByOperator)) {
                        doc.text(`TOTAL ${operator}:`, leftMargin, totalsY);
                        doc.text(`D: ${totals.depot.toFixed(2)} - R: ${totals.retrait.toFixed(2)}`, rightMargin, totalsY, { align: 'right' });
                        totalsY += 10; // Ajustement de l'espacement entre les lignes
                    }
        
                    doc.save('releve_des_transactions.pdf');
        
                    Swal.fire({
                        title: 'Succès !',
                        text: 'L\'exportation en PDF a réussi.',
                        icon: 'success',
                    });
                }
            });
        },
        


        handleFileUpload(event, transaction) {
            if (!transaction) {
                console.error("Transaction is not defined.");
                return;
            }

            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('document', file);
            formData.append('_method', 'put');

            this.uploadTransactionDocument(formData, transaction.id);
        },

        uploadTransactionDocument(formData, transactionId) {
            
            const loadingSwal = Swal.fire({
                title: 'En cours',
                html: 'Envoi du document en cours <div class="spinner-border text-primary" role="status"></div>',
                showConfirmButton: false,
                allowOutsideClick: false, // Empêche la fermeture de la modal en cliquant à l'extérieur
            });

            axios.post(`/transactionmarchand/${transactionId}/update-document`, formData)
                .then(response => {

                    loadingSwal.close();
                    this.fetchTransactions();
                    Swal.fire({
                        title: 'Succès !',
                        text: 'Le document a été importé avec succès.',
                        icon: 'success',
                    });
                })
                .catch(error => {

                    loadingSwal.close();
                    console.error('Erreur lors de l\'importation du document:', error);
                    Swal.fire({
                        title: 'Erreur !',
                        text: 'Échec lors de l\'envoi du fichier. Veuillez réessayer.',
                        icon: 'error',
                    });
                });
        },

        viewDocument(event, url) {
            event.stopPropagation();
            window.open(url, '_blank');
        },

    },
};
</script>

<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <label for="startDate">Date de début</label>
                                        <input v-model="startDate" @input="fetchTransactions" type="date" id="startDate"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="endDate">Date de fin</label>
                                        <input v-model="endDate" @input="fetchTransactions" type="date" id="endDate"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-2">
                                        <label for="searchNumber">Numéro</label>
                                        <input v-model="numero" @input="fetchTransactions" type="text"
                                            class="form-control" id="searchNumber">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="searchDestinataire">Client</label>
                                        <input v-model="destinataire" @input="fetchTransactions" type="text"
                                            class="form-control" id="searchDestinataire">
                                    </div>
                                </div>

                                <button @click="exportCSV" class="btn btn-sm btn-secondary">
                                    <i class="bi bi-file-earmark-spreadsheet"></i> Export CSV
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="exportToPDF" class="btn btn-sm btn-danger">
                                    <i class="bi bi-file-earmark-pdf"></i> Export PDF
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>

                                <a class="btn btn-sm btn-primary" href="/entreprise/reseau-gsm">
                                    <i class="bi bi-diagram-2-fill"></i> Réseaux GSM
                                </a>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <a class="btn btn-sm btn-success" href="/entreprise/expediteur-transaction">
                                    <i class="bi bi-arrow-right-square-fill me-2"></i> Expéditeur
                                </a>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <a class="btn btn-sm btn-warning" href="/entreprise/bonus-transaction">
                                    <i class="bi bi-gift me-2"></i> Bonus Client
                                </a>

                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Relevé des transactions</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">EXPEDITEUR</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                                <th scope="col" style="color: white;">OPERATION</th>
                                                <th scope="col" style="color: white;">GSM</th>
                                                <th scope="col" style="color: white;">MESSAGE</th>
                                                <th scope="col" style="color: white;">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="transactions.length > 0">
                                            <tr v-for="(transaction, index) in transactions" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ transaction.datetransaction }}</td>
                                                <td>{{ `${transaction.nomdestinataire} (${transaction.numero})` }}</td>
                                                <td>{{ transaction.montant }}</td>
                                                <td>
                                                    <span
                                                        v-if="transaction.mode === 1 || transaction.mode === 2">RETRAIT</span>
                                                    <span v-else-if="transaction.mode === 3">DEPOT</span>
                                                    <span v-else>{{ transaction.mode }}</span>
                                                </td>
                                                <td>
                                                    <img v-if="transaction.operateur === 'MTN MONEY'"
                                                        src="/../../images/MOMOPAY.png" alt="MTN MONEY"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'MOOV MONEY'"
                                                        src="/../../images/MOOVFLOOZ.png" alt="MOOV MONEY"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'CELTIIS CASH'"
                                                        src="/../../images/LOGOCELTIS.png" alt="CELTIIS CASH"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'DGI'"
                                                        src="/../../images/LOGODGI.jpg" alt="DGI"
                                                        style="width:35px; height:34px;">
                                                    <span v-else>{{ transaction.operateur }}</span>
                                                </td>
                                                <td>{{ transaction.smsrecu }}</td>
                                                <td class="text-center">
                                                    <label v-if="!transaction.document" for="fileInput"
                                                        class="file-upload-btn">
                                                        <!-- Vérifiez si un document est associé à la transaction -->
                                                        <i class="fas fa-paperclip yellow-icon"></i>
                                                    </label>
                                                    <input v-if="!transaction.document" id="fileInput" type="file"
                                                        style="display: none;"
                                                        @change="handleFileUpload($event, transaction)">
                                                    <i v-else class="fas fa-eye btn btn-sm btn-danger"
                                                        @click="viewDocument($event, BACK_URL + transaction.document)"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="12" class="text-center">
                                                    <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                        role="alert">
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <span>Aucune transaction disponible.</span>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>

                                    </table>
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <!-- Show current page and total pages -->
                                            Page {{ currentPage }} sur {{ totalPages }}
                                        </div>
                                        <div>
                                            <!-- Previous page button -->
                                            <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                                class="btn btn-warning">Précédent</button>
                                            <span>&nbsp;</span>
                                            <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                                class="btn btn-primary">Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>



<style>
.file-upload-btn {
    display: inline-block;
    padding: 8px 12px;
    background-color: #006666;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-upload-btn:hover {
    background-color: #006666;
}
</style>
<template>
  <div>
    <Header />

    <div class="container-fluid">
      <div class="row">
        <SideBar />

      <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
          <div class="title-group mb-3">
            <h1 class="h2 mb-0">Profile</h1>
          </div>

          <div class="row my-4">
            <div class="col-lg-12 col-12">
              <div class="custom-block custom-block-profile">
                <div class="row">
                  <div class="col-lg-12 col-12 mb-3">
                    <h6>Général</h6>
                  </div>

                  <div class="col-lg-3 col-12 mb-4 mb-lg-0">
                    <div class="custom-block-profile-image-wrap" v-if="user && user.entreprise">
                      <!-- Utilisez la propriété 'profile' pour afficher l'image -->
                      <img :src="BACK_URL + user.entreprise.logo"
                        class="custom-block-profile-image img-fluid" alt="africamoney" />

                      <a href="/entreprise/parametre" class="bi-pencil-square custom-block-edit-icon"></a>
                    </div>
                  </div>


                  <div class="col-lg-9 col-12">
                    <p class="d-flex flex-wrap mb-2">
                      <strong>Entreprise :</strong>
                      <a v-if="user && user.entreprise">{{ user.entreprise.entreprise }}</a>
                    </p>

                    <p class="d-flex flex-wrap mb-2">
                      <strong>Marchand MTN :</strong>
                      <a v-if="user && user.entreprise">(+229) {{ user.entreprise.marchandmtn }}</a>
                    </p>

                    <p class="d-flex flex-wrap mb-2">
                      <strong>Marchand MOOV :</strong>
                      <a v-if="user && user.entreprise">(+229) {{ user.entreprise.marchandmoov }}</a>
                    </p>

                    <p class="d-flex flex-wrap mb-2">
                      <strong>Marchand CELTIS :</strong>
                      <a v-if="user && user.entreprise">(+229) {{ user.entreprise.marchandceltis }}</a>
                    </p>

                    <p class="d-flex flex-wrap mb-2">
                      <strong>E-mail :</strong>
                      <a v-if="user">{{ user.email }}</a>
                    </p>

                    <p class="d-flex flex-wrap mb-2">
                      <strong>Téléphone :</strong>
                      <a v-if="user && user.entreprise">(+229) {{ user.entreprise.telephone }}</a>
                    </p>

                    <p class="d-flex flex-wrap">
                      <strong>Adresse :</strong>
                      <a v-if="user && user.entreprise">{{ user.entreprise.adresse }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { BACK_URL } from "@/entreprise_logo.js";

export default {
  components: { Header, Footer, SideBar },
  data() {
    return {
      user: null,
      BACK_URL: BACK_URL,
    };
  },
  mounted() {
    // Appeler la méthode profile pour récupérer les informations de l'utilisateur lors du montage du composant
    this.fetchUserProfile();
  },
  methods: {
    async fetchUserProfile() {
      try {
        const response = await axios.get('/profile', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
          },
        });

        if (response.data && response.data.user) { // Correction ici
          this.user = response.data.user; // Correction ici
        } else {
          console.error('Échec de la récupération du profil. Réponse de l\'API:', response.data);
        }
      } catch (error) {
        console.error('Erreur lors de la requête de récupération du profil:', error);
      }
    },
  }
}
</script>

<style></style>

import axios from "axios";

const axiosClient = axios.create({
   // baseURL: `http://localhost:8000/api`
    baseURL: `https://afrikmoney.com/api/public/api`
});

axiosClient.interceptors.request.use(
  config => {
    const authToken = sessionStorage.getItem('authToken');
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosClient;

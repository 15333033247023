<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <!-- Votre contenu existant -->
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche ( NB: Renseignez toute la référence du dossier)</h5>
                                <div class="row mb-4">
                                    <div class="col-md-12">
                                        <input v-model="reference" @input="searchDossier" type="text"
                                            class="form-control" id="searchDossier">
                                    </div>
                                </div>
                                <button type="button" @click="goBack()" class="btn btn-outline-success text-center"><i
                                        class="bi bi-arrow-return-left"></i></button>
                                <span>&nbsp;</span>
                            </div>
                        </div>
                    </div>
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Suivi de paiement</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">REFERENCE</th>
                                                <th scope="col" style="color: white;">MT. A PAYER</th>
                                                <th scope="col" style="color: white;">MT. PAYÉ</th>
                                                <th scope="col" style="color: white;">DATE DE PAIEMENT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(dossier, index) in dossiers" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ dossier.reference }}</td>
                                                <td>{{ dossier.a_payer }} fcfa</td>
                                                <td>{{ dossier.transactions[0].montant }} fcfa</td>
                                                <td>{{ dossier.dates_remboursement[0] }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            dossiers: {}, // Initialisez la variable dossiers
            reference: '' // Ajoutez la référence pour la recherche
        };
    },
    mounted() {
        this.fetchDossiers(); // Appelez la méthode pour récupérer les dossiers
    },
    methods: {
        fetchDossiers() {
            // Appelez votre API pour récupérer les dossiers
            axios.get('/dossiers-dates', {
                params: { reference: this.reference } // Ajoutez la référence comme paramètre de requête
            })
                .then(response => {
                    this.dossiers = response.data.dossiers; // Mettez à jour la variable dossiers avec les données de l'API
                })
                .catch(error => {
                    console.error('Error fetching dossiers: ', error);
                });
        },

        goBack() {
            this.$router.back();
        },

        searchDossier() {
            // Rechargez les dossiers lors de la recherche
            this.fetchDossiers();
        }
    }
};
</script>

<template>
    <Header/>
    <div class="container-fluid">
        <div class="row">
            <SideBar />
          <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="title-group mb-3">
                    <h1 class="h2 mb-0">Details de la transations</h1>
                </div>
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block custom-block-transation-detail bg-white">
                            <div class="d-flex flex-wrap align-items-center border-bottom pb-3 mb-3">
                                <div class="d-flex align-items-center">
                                    <img src="images/profile/young-woman-with-round-glasses-yellow-sweater.jpg"
                                        class="profile-image img-fluid" alt="">

                                    <div>
                                        <p>Store</p>

                                        <small class="text-muted">Payment Received</small>
                                    </div>
                                </div>

                                <div class="ms-auto">
                                    <small>22/8/2023</small>
                                    <strong class="d-block text-success"><span class="me-1">+</span> $280</strong>
                                </div>
                            </div>

                            <div class="d-flex flex-wrap align-items-center">
                                <div class="custom-block-transation-detail-item mt-4">
                                    <h6>Transation ID</h6>

                                    <p>283J3S0EL023</p>
                                </div>

                                <div class="custom-block-transation-detail-item mt-4 mx-auto px-4">
                                    <h6>Description</h6>

                                    <p>Shopee</p>
                                </div>

                                <div class="custom-block-transation-detail-item mt-4 ms-lg-auto px-lg-3 px-md-3">
                                    <h6>Payment Type</h6>

                                    <p>C2C Transfer</p>
                                </div>

                                <div class="custom-block-transation-detail-item mt-4 ms-auto me-auto">
                                    <h6>Amounts</h6>

                                    <p>$280</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> <br/><br/><br/><br/><br/><br/>
                <Footer/>
                </main>

            </div>
        </div>

</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";


export default {
  components: { Header, Footer, SideBar },
};
</script>

<style>

</style>
<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { BACK_URL } from "@/transaction_documents.js";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            transactions: [],
            date_debut: '',
            date_fin: '',
            numero: '',
            destinataire: '',
            currentPage: 1,
            totalPages: 0
        };
    },
    mounted() {
        this.fetchTransactions();
    },

    methods: {
        fetchTransactions() {
            const params = {
                date_debut: this.startDate,
                date_fin: this.endDate,
                page: this.currentPage
            };

            axios.get("/transactions-entreprise", { params })
                .then(response => {
                    this.transactions = response.data.data.data;
                    this.currentPage = response.data.data.current_page;
                    this.totalPages = response.data.data.last_page;
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des transactions", error);
                });
        },

        fetchPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchTransactions();
            }
        },

        fetchNextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchTransactions();
            }
        },

        async exportToPDF() {
            Swal.fire({
                title: 'Exportation ZIP',
                text: 'Êtes-vous sûr de vouloir exporter ces données ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Exporter',
                cancelButtonText: 'Annuler',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const date = moment().format('YYYY-MM-DD_HH-mm-ss');
                    const zip = new JSZip();
                    const folder = zip.folder(date);

                    // Regroupement des transactions par destinataire
                    const transactionsBySender = this.transactions.reduce((acc, transaction) => {
                        if (!acc[transaction.nomdestinataire]) {
                            acc[transaction.nomdestinataire] = [];
                        }
                        acc[transaction.nomdestinataire].push(transaction);
                        return acc;
                    }, {});

                    // Génération de PDF pour chaque destinataire
                    for (const [sender, transactions] of Object.entries(transactionsBySender)) {
                        const doc = new jsPDF();
                        const totalPagesExp = "{total_pages_count_string}";
                        let totalPages = 1;

                        doc.setFillColor(0, 102, 102);
                        doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                        const imgDataLeft = '../images/LOGOAFRICAMONEY.png';
                        doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);
                        const middleX = doc.internal.pageSize.width / 2;
                        const middleY = 17.5;
                        const text = 'RELEVE DES TRANSACTIONS';
                        doc.setTextColor('#ffffff');
                        doc.text(text, middleX, middleY, { align: 'center' });

                        if (this.startDate && this.endDate) {
                            const formattedStartDate = moment(this.startDate).format('DD/MM/YYYY');
                            const formattedEndDate = moment(this.endDate).format('DD/MM/YYYY');
                            const periodText = `PÉRIODE: du ${formattedStartDate} au ${formattedEndDate}`;
                            const periodTextY = middleY + 10;
                            doc.text(periodText, middleX, periodTextY, { align: 'center', color: '#ffffff' });
                        }
                        doc.setTextColor(0, 0, 0);

                        const tableData = transactions.map((transaction, index) => [
                            index + 1,
                            transaction.datetransaction,
                            transaction.nomdestinataire,
                            transaction.montant,
                            transaction.numero,
                            transaction.operateur,
                            transaction.smsrecu,
                        ]);

                        const headers = ['N°', 'DATE', 'EXPEDITEUR', 'MT', 'NUMERO', 'OPERATEUR', 'SMS'];

                        // Calculer les totaux par opérateur pour le destinataire
                        const totalsByOperator = transactions.reduce((acc, transaction) => {
                            if (!acc[transaction.operateur]) {
                                acc[transaction.operateur] = 0;
                            }
                            acc[transaction.operateur] += parseFloat(transaction.montant);
                            return acc;
                        }, {});

                        doc.autoTable({
                            head: [headers],
                            body: tableData,
                            headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff', fontSize: 8 },
                            bodyStyles: { fontSize: 7 },
                            startY: 45,
                            didDrawPage: function (data) {
                                totalPages = doc.internal.getNumberOfPages();
                                const bottomMargin = 10;
                                doc.setFontSize(6);
                                doc.text("Page " + data.pageNumber + " sur " + totalPages, 14, doc.internal.pageSize.height - bottomMargin);
                                const currentDate = new Date();
                                const dateString = currentDate.toLocaleDateString();
                                const timeString = currentDate.toLocaleTimeString();
                                doc.text("imprimé le: " + dateString + " à " + timeString, 14, doc.internal.pageSize.height - bottomMargin + 5); // Décalage vertical pour la deuxième ligne
                            },
                        });

                        // Ajouter les totaux à la fin du document
                        const finalY = doc.previousAutoTable.finalY + 10; // Positionner après le tableau
                        doc.setFontSize(8);
                        doc.setTextColor('#000');
                        doc.setFont('helvetica', 'bold'); // Police en gras
                        let totalsY = finalY;

                        for (const [operator, total] of Object.entries(totalsByOperator)) {
                            doc.text(`TOTAL ${operator}:`, 14, totalsY);
                            doc.text(`${total.toFixed(2)}`, doc.internal.pageSize.width - 14, totalsY, { align: 'right' });
                            totalsY += 10; // Ajuster l'espacement entre les lignes
                        }

                        const pdfOutput = doc.output('blob');
                        folder.file(`${sender.replace(/\s+/g, '_')}.pdf`, pdfOutput);
                    }

                    zip.generateAsync({ type: 'blob' })
                        .then(function (content) {
                            saveAs(content, `transactions_${date}.zip`);
                        });

                    Swal.fire({
                        title: 'Succès !',
                        text: 'L\'exportation a réussi.',
                        icon: 'success',
                    });
                }
            });
        },


    },
};
</script>

<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-6">
                                        <label for="startDate">Date de début</label>
                                        <input v-model="startDate" @input="fetchTransactions" type="date" id="startDate"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="endDate">Date de fin</label>
                                        <input v-model="endDate" @input="fetchTransactions" type="date" id="endDate"
                                            class="form-control">
                                    </div>
                                </div>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="exportToPDF" class="btn btn-sm btn-danger">
                                    <i class="bi bi-file-earmark-pdf"></i> Exportez les transactions PDF
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Relevé des transactions</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">EXPEDITEUR</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                                <th scope="col" style="color: white;">OPERATION</th>
                                                <th scope="col" style="color: white;">GSM</th>
                                                <th scope="col" style="color: white;">MESSAGE</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="transactions.length > 0">
                                            <tr v-for="(transaction, index) in transactions" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ transaction.datetransaction }}</td>
                                                <td>{{ `${transaction.nomdestinataire} (${transaction.numero})` }}</td>
                                                <td>{{ transaction.montant }}</td>
                                                <td>
                                                    <span
                                                        v-if="transaction.mode === 1 || transaction.mode === 2">RETRAIT</span>
                                                    <span v-else-if="transaction.mode === 3">DEPOT</span>
                                                    <span v-else>{{ transaction.mode }}</span>
                                                </td>
                                                <td>
                                                    <img v-if="transaction.operateur === 'MTN MONEY'"
                                                        src="/../../images/MOMOPAY.png" alt="MTN MONEY"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'MOOV MONEY'"
                                                        src="/../../images/MOOVFLOOZ.png" alt="MOOV MONEY"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'CELTIIS CASH'"
                                                        src="/../../images/LOGOCELTIS.png" alt="CELTIIS CASH"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'DGI'"
                                                        src="/../../images/LOGODGI.jpg" alt="DGI"
                                                        style="width:35px; height:34px;">
                                                    <span v-else>{{ transaction.operateur }}</span>
                                                </td>
                                                <td>{{ transaction.smsrecu }}</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="12" class="text-center">
                                                    <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                        role="alert">
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <span>Aucune transaction disponible.</span>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            Page {{ currentPage }} sur {{ totalPages }}
                                        </div>
                                        <div>
                                            <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                                class="btn btn-warning">Précédent</button>
                                            <span>&nbsp;</span>
                                            <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                                class="btn btn-primary">Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

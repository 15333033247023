<template>
  <main class="main">
    <Head />
    <div class="container">
      <div class="row justify-content-center align-items-center min-vh-100 py-5">
        <div class="col-md-10 col-lg-6 col-xl-5">
          <div class="shadow p-3 mb-5 bg-body rounded">
            <div class="text-center mb-7">
              <!-- Icône de groupe de personnes -->
              <i class="bi bi-people" style="font-size: 3rem; color: #006666;"></i>
              <h3 class="text-1000 mt-2">CRÉER UN COMPTE</h3>
            </div>
            <form @submit.prevent="registerUser">
              <div class="mb-3 text-start">
                <label class="form-label" for="nom">Nom</label>
                <input class="form-control form-icon-input" v-model="client.nom" id="nom" type="text" placeholder="Votre nom" required />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="adresse">Adresse</label>
                <input class="form-control form-icon-input" v-model="client.adresse" id="adresse" type="text" placeholder="Votre adresse" required />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="telephone">Téléphone</label>
                <input class="form-control form-icon-input" v-model="client.telephone" id="telephone" type="text" placeholder="Numéro de téléphone" required />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="type_entite">Type d'entité</label>
                <input class="form-control form-icon-input" v-model="client.type_entite" id="type_entite" type="text" placeholder="Type d'entité" required />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="numero_registre_commerce">Numéro Registre Commerce</label>
                <input class="form-control form-icon-input" v-model="client.numero_registre_commerce" id="numero_registre_commerce" type="text" placeholder="Numéro Registre Commerce" />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="description">Description</label>
                <textarea class="form-control form-icon-input" v-model="client.description" id="description" placeholder="Description"></textarea>
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="email">Email</label>
                <input class="form-control form-icon-input" v-model="user.email" id="email" type="email" placeholder="adiza@example.com" required />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="password">Mot de passe</label>
                <div class="form-icon-container position-relative">
                  <input
                    class="form-control form-icon-input"
                    v-model="user.password"
                    :type="passwordVisible ? 'text' : 'password'"
                    placeholder="Mot de passe"
                    id="password"
                    required
                  />
                  <button
                    type="button"
                    class="btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y"
                    @click="togglePasswordVisibility('password')"
                    style="z-index: 2;"
                  >
                    <i :class="passwordVisible ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                  </button>
                </div>
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="password_confirmation">Confirmer le mot de passe</label>
                <div class="form-icon-container position-relative">
                  <input
                    class="form-control form-icon-input"
                    v-model="user.password_confirmation"
                    :type="passwordVisible ? 'text' : 'password'"
                    placeholder="Confirmation"
                    id="password_confirmation"
                    required
                  />
                  <button
                    type="button"
                    class="btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y"
                    @click="togglePasswordVisibility('password_confirmation')"
                    style="z-index: 2;"
                  >
                    <i :class="passwordVisible ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                  </button>
                </div>
              </div>
              <button class="btn btn-secondary w-100 mb-3" style="background-color: #006666;" type="submit" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Créer un compte</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
  <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
      <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
        <div class="col-auto">
          <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
        </div>
        <div class="col-auto">
          <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
          <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "@/axs/axios.js";
import Head from "@/components/Head.vue";
import Swal from 'sweetalert2';

export default {
  components: { Head },
  data() {
    return {
      client: {
        nom: '',
        adresse: '',
        telephone: '',
        type_entite: '',
        numero_registre_commerce: '',
        description: ''
      },
      user: {
        email: '',
        password: '',
        password_confirmation: ''
      },
      loading: false,
      errors: {},
      passwordVisible: false,
    };
  },
  methods: {
    async registerUser() {
      try {
        this.loading = true;
        const response = await axios.post('/add-clients', {
          client: this.client,
          user: this.user,
        });

        Swal.fire({
          icon: 'success',
          title: 'Compte créé avec succès!',
          toast: true,
          timer: 3000,
          position: 'top-end',
          showConfirmButton: false
        });

        this.$router.push('/connexion');

      } catch (error) {
        console.error('Erreur lors de la requête d\'inscription :', error);
        if (error.response && error.response.status === 422 && error.response.data.errors) {
          this.errors = error.response.data.errors;
        }
      } finally {
        this.loading = false;
      }
    },
    togglePasswordVisibility(field) {
      this.passwordVisible = !this.passwordVisible;
      // Vous pouvez personnaliser cette fonction pour gérer la visibilité de chaque champ séparément si nécessaire
    }
  },
};
</script>

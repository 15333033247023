<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            transactions: [],
            loading: false,
            clientId: '',
            startDate: '',
            endDate: '',
            selectedOperateur: '',
            selectedStatut: '',
            currentPage: 1,
            totalPages: 1,
            perPage: 15,
            total: 0,
            paylinkId: '',
            solde: 0,
            withdrawAmount: '',
            withdrawNumber: '',
            isSubmitting: false
        };
    },
    mounted() {
        const userDataString = sessionStorage.getItem('userData');
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.clientId = userData.additional_info.id;
                this.paylinkId = this.$route.params.id;
                this.loadTransactions();
            } catch (error) {
                console.error('Erreur lors du parsing de userData depuis sessionStorage:', error);
            }
        }
    },
    methods: {
        async loadTransactions(page = 1) {
            this.loading = true;
            try {
                const response = await axios.get(`/transactions/${this.clientId}/paylink/${this.paylinkId}`, {
                    params: {
                        startDate: this.startDate,
                        endDate: this.endDate,
                        operateur: this.selectedOperateur,
                        status: this.selectedStatut,
                        page: page
                    }
                });
                const data = response.data.paylinks_transactions;
                this.transactions = data.data;
                this.currentPage = data.current_page;
                this.totalPages = data.last_page;
                this.total = data.total;
                this.calculateSolde();
            } catch (error) {
                console.error('Erreur lors de la récupération des transactions:', error);
            } finally {
                this.loading = false;
            }
        },
        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.loadTransactions(page);
            }
        },
        calculateSolde() {
            this.solde = this.transactions
                .filter(transaction => transaction.status === 'SUCCESSFUL')
                .reduce((sum, transaction) => sum + Math.floor(Number(transaction.montant)), 0);
        },
        async sendPayoutRequest() {
            this.isSubmitting = true;
            const payload = {
                client_id: this.clientId,
                paylink_id: this.paylinkId,
                balance: this.solde.toString(),
                amount: this.withdrawAmount,
                withdraw_number: this.withdrawNumber
            };

            try {
                const response = await axios.post('/send-payout-request', payload);
                Swal.fire({
                    icon: 'success',
                    title: 'Demande envoyée',
                    text: 'Votre demande de remboursement a été initié. Patientez au plus 24h',
                }).then(() => {
                    window.location.reload();
                });
            } catch (error) {
                const errorMessage = error.response?.data?.message || 'Une erreur est survenue lors de l\'envoi de la demande.';
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: errorMessage,
                });
                console.error('Erreur lors de l\'envoi de la demande de paiement:', error);
            } finally {
                this.isSubmitting = false;
            }
        }

    }
};
</script>


<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <label for="startDate">Date de début</label>
                                        <input type="date" id="startDate" class="form-control" v-model="startDate">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="endDate">Date de fin</label>
                                        <input type="date" id="endDate" class="form-control" v-model="endDate">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="operateur">Opérateur</label>
                                        <select id="operateur" class="form-control" v-model="selectedOperateur">
                                            <option value="">Tous</option>
                                            <option value="MTN">MTN</option>
                                            <option value="MOOV">MOOV</option>
                                            <option value="CELTIIS">CELTIIS</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="statut">Statut</label>
                                        <select id="statut" class="form-control" v-model="selectedStatut">
                                            <option value="">Tous</option>
                                            <option value="SUCCESSFUL">Succès</option>
                                            <option value="FAILED">Échec</option>
                                            <option value="PENDING">En attente</option>
                                        </select>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#payoutModal">
                                    <i class="bi bi-plus-circle"></i> Demande de remboursement
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">EXPÉDITEUR</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                                <th scope="col" style="color: white;">GSM</th>
                                                <th scope="col" style="color: white;">STATUT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loading">
                                                <td colspan="6" class="text-center">
                                                    <div class="spinner-border text-success" role="status">
                                                        <span class="sr-only">Chargement en cours...</span>
                                                    </div>
                                                    <p>Chargement en cours...</p>
                                                </td>
                                            </tr>

                                            <tr v-if="!loading && transactions.length === 0">
                                                <td colspan="6" class="text-center">
                                                    <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                        role="alert">
                                                        Aucune transaction disponible pour ce paylink.
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr v-for="(transaction, index) in transactions" :key="transaction.id">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ new Date(transaction.created_at).toLocaleDateString() }}</td>
                                                <td>{{ transaction.nomdestinataire }}</td>
                                                <td>{{ transaction.montant }} FCFA</td>
                                                <td>
                                                    <img v-if="transaction.operateur === 'MTN'"
                                                        src="/../../images/MOMOPAY.png" alt="MTN"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'MOOV'"
                                                        src="/../../images/MOOVFLOOZ.png" alt="MOOV"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'CELTIIS'"
                                                        src="/../../images/LOGOCELTIS.png" alt="CELTIIS"
                                                        style="width:35px; height:34px;">
                                                    <span v-else>{{ transaction.operateur }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="transaction.status === 'PENDING'"
                                                        class="badge text-bg-warning">
                                                        {{ transaction.status }}
                                                    </span>
                                                    <span v-else-if="transaction.status === 'SUCCESSFUL'"
                                                        class="badge text-bg-success">
                                                        {{ transaction.status }}
                                                    </span>
                                                    <span v-else-if="transaction.status === 'FAILED'"
                                                        class="badge text-bg-danger">
                                                        {{ transaction.status }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul class="pagination">
                                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                            <a class="page-link" @click="goToPage(currentPage - 1)">Précédent</a>
                                        </li>
                                        <li class="page-item" v-for="page in totalPages" :key="page"
                                            :class="{ active: page === currentPage }">
                                            <a class="page-link" @click="goToPage(page)">{{ page }}</a>
                                        </li>
                                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                            <a class="page-link" @click="goToPage(currentPage + 1)">Suivant</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="payoutModal" tabindex="-1" aria-labelledby="payoutModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="background-color: rgb(0, 102, 102);">
                    <h5 class="modal-title" id="payoutModalLabel" style="color: #ffffff;">Demande de remboursement</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- Section pour afficher le solde -->
                    <div class="mb-3">
                        <div class="alert alert-primary" role="alert">
                            <h6>SOLDE : {{ solde }} FCFA</h6>
                        </div>
                    </div>

                    <form>
                        <div class="mb-3">
                            <label for="withdrawAmount" class="form-label">Montant à retirer</label>
                            <input type="number" class="form-control" id="withdrawAmount" v-model="withdrawAmount"
                                placeholder="Entrez le montant">
                        </div>
                        <div class="mb-3">
                            <label for="withdrawNumber" class="form-label">Numéro de retrait</label>
                            <input type="text" class="form-control" id="withdrawNumber" v-model="withdrawNumber"
                                placeholder="Entrez le numéro de retrait">
                        </div>
                    </form>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annuler</button>
                    <button type="button" class="btn btn-primary" @click="sendPayoutRequest" :disabled="isSubmitting">
                        <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                        <span v-if="!isSubmitting">Soumettre</span>
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<style></style>

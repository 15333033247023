<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <label for="date">Date de pointage</label>
                                        <input type="date" id="date" class="form-control" v-model="filters.date_pointage" @change="applyFilters">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="searchNumber">Numéro</label>
                                        <input type="text" class="form-control" id="searchNumber" v-model="filters.telephonemtn" @input="applyFilters">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="role">Rôle</label>
                                        <input type="text" class="form-control" id="role" v-model="filters.role" @input="applyFilters">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="searchOuvrier">Ouvrier</label>
                                        <input type="text" class="form-control" id="searchOuvrier" v-model="filters.search" @input="applyFilters">
                                    </div>
                                </div>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <a class="btn btn-sm btn-success" href="/entreprise/listes-ouvriers">
                                    <i class="bi bi-arrow-right-square-fill me-2"></i> Listes des ouvriers
                                </a>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button class="btn btn-sm btn-primary" @click="exportToPDF">
                                    <i class="bi bi-file-earmark-pdf-fill me-2"></i> Télécharger PDF
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Liste des ouvriers pointés</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666; color: white;">
                                            <tr>
                                                <th scope="col">N°</th>
                                                <th scope="col">NOM & PRENOM</th>
                                                <th scope="col">ROLE</th>
                                                <th scope="col">DATE DE POINTAGE</th>
                                                <th scope="col">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(pointage, index) in ouvriersPointes" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ pointage.ouvrier.nom }} {{ pointage.ouvrier.prenom }}</td>
                                                <td>{{ pointage.ouvrier.role }}</td>
                                                <td>{{ pointage.date_pointage }}</td>
                                                <td>
                                                    <button class="btn btn-warning btn-sm">
                                                        <i class="fas fa-edit"></i>
                                                    </button><span>&nbsp;</span>
                                                    <button class="btn btn-danger btn-sm">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <!-- Afficher un message si aucun résultat n'est trouvé -->
                                            <tr v-if="ouvriersPointes.length === 0">
                                                <td colspan="5" class="text-center">Aucun résultat trouvé.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import moment from "moment";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            file: null,
            ouvriersPointes: [],
            filters: {
                date_pointage: '',
                telephonemtn: '',
                telephonemoov: '',
                telephoneceltis: '',
                role: '',
                search: ''
            }
        };
    },
    mounted() {
        this.fetchOuvriersPointes();
    },
    methods: {
        async fetchOuvriersPointes() {
            try {
                const response = await axios.get('/ouvriers-pointes', { params: this.filters });
                this.ouvriersPointes = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des ouvriers pointés:', error);
            }
        },
        applyFilters() {
            // Appliquer des filtres après un délai court pour éviter des appels répétés à l'API lors de la saisie
            setTimeout(() => {
                this.fetchOuvriersPointes();
            }, 300); // ajustez le délai en fonction de vos besoins
        },
        exportToPDF() {
            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir exporter ces données en PDF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Exporter',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();
                    let totalPages = 1;

                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png'; // Changez cette ligne pour correspondre à votre logo
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);
                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 17.5;
                    const text = 'LISTE DES OUVRIERS POINTÉS';
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });

                    if (this.filters.date_pointage) {
                        const formattedDate = moment(this.filters.date_pointage).format('DD/MM/YYYY');
                        const periodText = `DATE DE POINTAGE: ${formattedDate}`;
                        const periodTextY = middleY + 10;
                        doc.text(periodText, middleX, periodTextY, { align: 'center', color: '#ffffff' });
                    }
                    doc.setTextColor(0, 0, 0);

                    const tableData = this.ouvriersPointes.map((pointage, index) => [
                        index + 1,
                        `${pointage.ouvrier.nom} ${pointage.ouvrier.prenom}`,
                        pointage.ouvrier.role,
                        pointage.date_pointage,
                    ]);

                    const headers = ['N°', 'NOM & PRENOM', 'ROLE', 'DATE DE POINTAGE'];

                    doc.autoTable({
                        head: [headers],
                        body: tableData,
                        headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff', fontSize: 8 },
                        bodyStyles: { fontSize: 7 },
                        startY: middleY + 30,
                        didDrawPage: function (data) {
                            totalPages = doc.internal.getNumberOfPages();
                            const bottomMargin = 10;
                            doc.setFontSize(6);
                            doc.text("Page " + data.pageNumber + " sur " + totalPages, 14, doc.internal.pageSize.height - bottomMargin);
                            const currentDate = new Date();
                            const dateString = currentDate.toLocaleDateString();
                            const timeString = currentDate.toLocaleTimeString();
                            doc.text("imprimé le: " + dateString + " à " + timeString, 14, doc.internal.pageSize.height - bottomMargin + 5);
                        },
                    });

                    doc.save('liste_des_ouvriers_pointes.pdf');

                    Swal.fire({
                        title: 'Succès !',
                        text: 'L\'exportation en PDF a réussi.',
                        icon: 'success',
                    });
                }
            });
        }
    }
};
</script>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            transactions: [],
            destinataire: '',
            numero: '',
            operateur: '',
            currentPage: 1,
            totalPages: 0
        };
    },
    mounted() {

        this.fetchTransactions();
    },
    
    methods: {
        fetchTransactions() {

            const params = {
                destinataire: this.destinataire,
                numero: this.numero,
                operateur: this.operateur,
                page: this.currentPage
            };

            axios.get("/expediteur-total", { params })
                .then(response => {
                    this.transactions = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.totalPages = response.data.last_page;
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des transactions filtrées", error);
                });
        },

        fetchPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchTransactions();
            }
        },

        fetchNextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchTransactions();
            }
        },

        goBack() {
            this.$router.back();
        },

        exportCSV() {
            Swal.fire({
                title: 'Êtes-vous sûr?',
                text: "Vous êtes sur le point d'exporter les transactions au format CSV.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, exporter!',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const csvContent = this.generateCSV();
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'transactions.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    Swal.fire(
                        'Exporté!',
                        'Le fichier CSV a été exporté avec succès.',
                        'success'
                    );
                }
            });
        },

        generateCSV() {
            const header = ['N°', 'Expéditeur', 'Montant', 'Gsm'].join(',');
            const rows = this.transactions.map((transaction, index) => {
                return [
                    index + 1,
                    transaction.nomdestinataire,
                    transaction.montant_total,
                    transaction.operateur,
                ].join(',');
            });
            return [header, ...rows].join('\n');
        },

        exportToPDF() {
           
            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir exporter ces données en PDF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Exporter',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    
                    const doc = new jsPDF();
                    const totalPagesExp = "{total_pages_count_string}";
                    let totalPages = 1;

                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png';
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);
                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 23;
                    const text = 'RELEVE GLOBAL DES EXPEDITEURS';
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });
                    doc.setTextColor(0, 0, 0);

                    const tableData = this.transactions.map((transaction, index) => [
                        index + 1,
                        transaction.premiere_date_paiement + ' - ' + transaction.derniere_date_paiement,
                        transaction.nomdestinataire,
                        transaction.montant_total + ' ' + 'FCFA',
                        transaction.numero,
                        transaction.operateur,
                    ]);

                    const headers = ['N°', 'INTERVALE DES PAIEMENTS', 'EXPEDITEUR', 'MONTANT PAYÉ', 'NUMERO', 'OPERATEUR'];

                    // Générer le tableau PDF avec pagination et déplacer vers le bas
                    doc.autoTable({
                        head: [headers],
                        body: tableData,
                        startY: 45,
                        headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff' },
                        didDrawPage: function (data) {
                            totalPages = doc.internal.getNumberOfPages();
                            const bottomMargin = 10;
                            doc.setFontSize(6);
                            doc.text("Page " + data.pageNumber + " sur " + totalPages, 14, doc.internal.pageSize.height - bottomMargin);
                            const currentDate = new Date();
                            const dateString = currentDate.toLocaleDateString();
                            const timeString = currentDate.toLocaleTimeString();
                            doc.text("imprimé le: " + dateString + " à " + timeString, 14, doc.internal.pageSize.height - bottomMargin + 5); // Décalage vertical pour la deuxième ligne
                        },
                    });

                    doc.save('transactions.pdf');
                    Swal.fire({
                        title: 'Succès !',
                        text: 'L\'exportation en PDF a réussi.',
                        icon: 'success',
                    });
                }
            });
        },

    },
};
</script>

<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <label for="searchDestinataire">Client</label>
                                        <input v-model="destinataire" @input="fetchTransactions" type="text"
                                            class="form-control" id="searchDestinataire">
                                    </div>

                                    <div class="col-md-3">
                                        <label for="searchNumber">Numéro de paiement</label>
                                        <input v-model="numero" @input="fetchTransactions" type="text"
                                            class="form-control" id="searchNumber">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="searchoperateur">Opérateur Gsm</label>
                                        <select v-model="operateur" @change="fetchTransactions" class="form-select"
                                            id="searchoperateur">
                                            <option value="">Sélectionnez un opérateur</option>
                                            <option value="MTN MONEY">MTN MONEY</option>
                                            <option value="MOOV MONEY">MOOV MONEY</option>
                                            <option value="CELTIIS CASH">CELTIIS CASH</option>
                                        </select>
                                    </div>
                                </div>

                                <button type="button" @click="goBack()" class="btn btn-outline-success text-center"><i
                                        class="bi bi-arrow-return-left"></i></button>
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                                <button @click="exportCSV" class="btn btn-sm btn-warning">
                                    <i class="bi bi-file-earmark-spreadsheet"></i> Export CSV
                                </button>
                                <span>&nbsp;</span>
                                <button @click="exportToPDF" class="btn btn-sm btn-danger"><i
                                        class="bi bi-file-earmark-pdf"></i>Export
                                    PDF</button>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Rélévé clients</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">OPERATEUR GSM</th>
                                                <th class="text-center" scope="col" style="color: white;">CLIENTS</th>
                                                <th class="text-center" scope="col" style="color: white;">NUMERO DE
                                                    PAIEMENT</th>
                                                <th class="text-center" scope="col" style="color: white;">MONTANT PAYÉ
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="transactions.length > 0">
                                            <tr v-for="(transaction, index) in transactions" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td> {{ transaction.operateur }}</td>
                                                <td class="text-center">{{ transaction.nomdestinataire }}</td>
                                                <td class="text-center">{{ transaction.numero }}</td>
                                                <td class="text-center"><b>{{ transaction.montant_total }} FCFA</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="12" class="text-center">
                                                    <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                        role="alert">
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <span>Aucune transaction disponible.</span>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>

                                    </table>
                                    <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                        class="btn btn-warning">Précédent</button>
                                    <span>&nbsp;</span><span>&nbsp;</span>
                                    <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                        class="btn btn-primary">Suivant</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<template>
    <Header />
  
    <div class="container-fluid">
      <div class="row">
        <SideBar />
        <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
          <div class="title-group mb-3">
            <h1 class="h2 mb-0">TUTORIELS</h1>
          </div>
  
          <!-- Modal HTML -->
          <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl"> <!-- Classe 'modal-xl' pour un modal plus grand -->
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="videoModalLabel">Vidéo Tutoriel</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <!-- Iframe YouTube -->
                  <iframe
                    width="100%"
                    height="100%"
                    :src="videoSrc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                  </iframe>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                </div>
              </div>
            </div>
          </div>
  
          <Footer />
        </main>
      </div>
    </div>
  </template>
  
  <script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import SideBar from "@/components/SideBar.vue";
  
  export default {
    components: { Header, Footer, SideBar },
    data() {
      return {
        videoSrc: 'https://www.youtube.com/embed/AyxUGUVLUxE?autoplay=1'
      };
    },
    mounted() {
      const myModal = new bootstrap.Modal(document.getElementById('videoModal'));
      myModal.show();
    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles personnalisés si nécessaire */
  .modal-dialog {
    max-width: 90vw; /* Ajuste la largeur maximale du modal */
  }
  
  .modal-body iframe {
    height: 60vh; /* Ajuste la hauteur du iframe pour mieux occuper l'espace du modal */
  }
  </style>
  
<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-9 px-md-4 border-start">
                <div class="title-group mb-3">
                    <h1 class="h2 mb-0">Transactions</h1>
                </div>

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Historiques des transactions</h5>

                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>

                                            <th scope="col">Time</th>

                                            <th scope="col">Description</th>

                                            <th scope="col">Payment Type</th>

                                            <th scope="col">Amount</th>

                                            <th scope="col">Balance</th>

                                            <th scope="col">Status</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td scope="row">July 5, 2023</td>

                                            <td scope="row">10:00 PM</td>

                                            <td scope="row">Shopping</td>

                                            <td scope="row">C2C Transfer</td>

                                            <td class="text-danger" scope="row">
                                                <span class="me-1">-</span>
                                                $100.00
                                            </td>

                                            <td scope="row">$5,500.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-danger">
                                                    Pending
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">July 2, 2023</td>

                                            <td scope="row">10:42 AM</td>

                                            <td scope="row">Food Delivery</td>

                                            <td scope="row">Mobile Reload</td>

                                            <td class="text-success" scope="row">
                                                <span class="me-1">+</span>
                                                $250
                                            </td>

                                            <td scope="row">$5,600.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-success">
                                                    Success
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">June 28, 2023</td>

                                            <td scope="row">8:20 PM</td>

                                            <td scope="row">Billing</td>

                                            <td scope="row">Goverment</td>

                                            <td class="text-success" scope="row">
                                                <span class="me-2">+</span>$50
                                            </td>

                                            <td scope="row">$5,350.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-success">
                                                    Success
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">June 24, 2023</td>

                                            <td scope="row">10:48 PM</td>

                                            <td scope="row">Shopee</td>

                                            <td scope="row">QR Code</td>

                                            <td class="text-danger" scope="row">
                                                <span class="me-2">-</span>$380
                                            </td>

                                            <td scope="row">$5,300.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-dark">
                                                    Cancelled
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">June 12, 2023</td>

                                            <td scope="row">12:30 AM</td>

                                            <td scope="row">Food Delivery</td>

                                            <td scope="row">Mobile Reload</td>

                                            <td class="text-success" scope="row">
                                                <span class="me-2">+</span>$250
                                            </td>

                                            <td scope="row">$4,920.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-success">
                                                    Success
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">May 31, 2023</td>

                                            <td scope="row">2:40 PM</td>

                                            <td scope="row">Food Delivery</td>

                                            <td scope="row">Mobile Reload</td>

                                            <td class="text-success" scope="row">
                                                <span class="me-2">+</span>$50
                                            </td>

                                            <td scope="row">$4,920.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-success">
                                                    Success
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">May 22, 2023</td>

                                            <td scope="row">8:50 AM</td>

                                            <td scope="row">Food Delivery</td>

                                            <td scope="row">Mobile Reload</td>

                                            <td class="text-success" scope="row">
                                                <span class="me-2">+</span>$50
                                            </td>

                                            <td scope="row">$4,920.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-success">
                                                    Success
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">May 20, 2023</td>

                                            <td scope="row">6:45 PM</td>

                                            <td scope="row">Food Delivery</td>

                                            <td scope="row">Mobile Reload</td>

                                            <td class="text-danger" scope="row">
                                                <span class="me-2">-</span>$500
                                            </td>

                                            <td scope="row">$4,920.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-danger">
                                                    Pending
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">April 28, 2023</td>

                                            <td scope="row">11:20 AM</td>

                                            <td scope="row">Food Delivery</td>

                                            <td scope="row">Mobile Reload</td>

                                            <td class="text-success" scope="row">
                                                <span class="me-2">+</span>$856
                                            </td>

                                            <td scope="row">$4,920.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-success">
                                                    Success
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td scope="row">April 16, 2023</td>

                                            <td scope="row">11:00 PM</td>

                                            <td scope="row">Food Delivery</td>

                                            <td scope="row">Mobile Reload</td>

                                            <td class="text-success" scope="row">
                                                <span class="me-2">+</span>$50
                                            </td>

                                            <td scope="row">$4,920.00</td>

                                            <td scope="row">
                                                <span class="badge text-bg-danger">
                                                    Pending
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center mb-0">
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">Prev</span>
                                        </a>
                                    </li>
                                    <li class="page-item active" aria-current="page">
                                        <a class="page-link" href="#">1</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="col-lg-7 col-12">
                        <div class="custom-block custom-block-balance">
                            <small>SOLDE</small>

                            <h2 class="mt-2 mb-3">$254,800</h2>

                            <div class="custom-block-numbers d-flex align-items-center">
                                <span>****</span>
                                <span>****</span>
                                <span>****</span>
                                <p>2560</p>
                            </div>

                            <div class="d-flex">
                                <div>
                                    <small>Valid Date</small>
                                    <p>12/2028</p>
                                </div>

                                <div class="ms-auto">
                                    <small>Card Holder</small>
                                    <p>Thomas</p>
                                </div>
                            </div>
                        </div>

                        <div class="custom-block custom-block-bottom d-flex flex-wrap">
                            <div class="custom-block-bottom-item">
                                <a href="#" class="d-flex flex-column">
                                    <i class="custom-block-icon bi-wallet"></i>

                                    <small>Top up</small>
                                </a>
                            </div>

                            <div class="custom-block-bottom-item">
                                <a href="#" class="d-flex flex-column">
                                    <i class="custom-block-icon bi-upc-scan"></i>

                                    <small>Scan & Pay</small>
                                </a>
                            </div>

                            <div class="custom-block-bottom-item">
                                <a href="#" class="d-flex flex-column">
                                    <i class="custom-block-icon bi-send"></i>

                                    <small>Send</small>
                                </a>
                            </div>

                            <div class="custom-block-bottom-item">
                                <a href="#" class="d-flex flex-column">
                                    <i class="custom-block-icon bi-arrow-down"></i>

                                    <small>Request</small>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5 col-12">
                        <div class="custom-block custom-block-transations">
                            <h5 class="mb-4">Transations Récente</h5>

                            <div class="d-flex flex-wrap align-items-center mb-4">
                                <div class="d-flex align-items-center">
                                    <img src="images/profile/senior-man-white-sweater-eyeglasses.jpg"
                                        class="profile-image img-fluid" alt="">

                                    <div>
                                        <p>
                                            <a href="transation-detail.html">Daniel Jones</a>
                                        </p>

                                        <small class="text-muted">C2C Transfer</small>
                                    </div>
                                </div>

                                <div class="ms-auto">
                                    <small>05/12/2023</small>
                                    <strong class="d-block text-danger"><span class="me-1">-</span> $250</strong>
                                </div>
                            </div>

                            <div class="d-flex flex-wrap align-items-center mb-4">
                                <div class="d-flex align-items-center">
                                    <img src="images/profile/young-beautiful-woman-pink-warm-sweater.jpg"
                                        class="profile-image img-fluid" alt="">

                                    <div>
                                        <p>
                                            <a href="transation-detail.html">Public Bank</a>
                                        </p>

                                        <small class="text-muted">Mobile Reload</small>
                                    </div>
                                </div>

                                <div class="ms-auto">
                                    <small>22/8/2023</small>
                                    <strong class="d-block text-success"><span class="me-1">+</span> $280</strong>
                                </div>
                            </div>

                            <div class="d-flex flex-wrap align-items-center">
                                <div class="d-flex align-items-center">
                                    <img src="images/profile/young-woman-with-round-glasses-yellow-sweater.jpg"
                                        class="profile-image img-fluid" alt="">

                                    <div>
                                        <p><a href="transation-detail.html">Store</a></p>

                                        <small class="text-muted">Payment Received</small>
                                    </div>
                                </div>

                                <div class="ms-auto">
                                    <small>22/8/2023</small>
                                    <strong class="d-block text-success"><span class="me-1">+</span> $280</strong>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </main>

        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    components: { Header, Footer, SideBar },
};
</script>

<style></style>
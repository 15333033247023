<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="title-group mb-3">
                        <h1 class="h2 mb-0">Détails du client</h1>
                    </div>
                    <div class="row my-4" v-if="debiteur">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block custom-block-transation-detail bg-white">
                                <div class="d-flex flex-wrap align-items-center border-bottom pb-3 mb-3">
                                    <div class="d-flex align-items-center">
                                        <img src="/images/LOGOAFRICAMONEY.png" class="profile-image img-fluid" alt="">
                                        <div>
                                            <p>CLIENT</p>
                                            <small class="text-muted"> Nom: <b> {{ debiteur.nom }} </b></small>
                                            <br />
                                            <small class="text-muted">Prénom: <b> {{ debiteur.prenoms
                                                    }}</b></small>
                                            <br />
                                            <small class="text-muted">Tél: <b> {{ debiteur.tel }}</b></small>
                                            <br />
                                            <small class="text-muted">Adresse: <b> {{ debiteur.adresse
                                                    }}</b></small>
                                        </div>
                                    </div>
                                    <div class="ms-auto">
                                        <p>A CONTACTER</p>
                                        <small> Nom: <b> {{ debiteur.acontacter }} </b></small>
                                        <strong class="d-block text-success"><span class="me-1">Tel: </span> <b>{{
                        debiteur.telacontacter }} </b></strong>
                                    </div>
                                </div>

                                <h3 class="border-bottom pb-3 mb-3 text-center">Information suplémentaire</h3><br/>
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="custom-block-transation-detail-item mt-4">
                                        <h6>Sait lire</h6>
                                        <p>{{ debiteur.lire_francais }}</p>
                                    </div>

                                    <div class="custom-block-transation-detail-item mt-4">
                                        <h6>Sait lire</h6>
                                        <p>{{ debiteur.ecrire_francais }}</p>
                                    </div>

                                    <div class="custom-block-transation-detail-item mt-4">
                                        <h6>WhatsApp <i class="fab fa-whatsapp"></i></h6>
                                        <p>{{ debiteur.telwhatsapp }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            debiteur: null
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {

                const debiteurId = this.$route.params.id;
                const response = await axios.get(`/debiteurs/${debiteurId}`);
                this.debiteur = response.data.debiteur;
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }
};
</script>

<style></style>
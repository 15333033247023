<template>
  <Header />

  <div class="container-fluid">
    <div class="row">
      <SideBar />
      <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
        <div class="title-group mb-3">
          <h1 class="h2 mb-0">TUTORIELS</h1>
        </div>

        <!-- Liste de tutoriels en cartes -->
        <div class="row">
          <div
            class="col-md-6 col-lg-3 mb-4"
            v-for="(tutoriel, index) in tutoriels"
            :key="index"
          >
            <div class="card h-100">
              <!-- Image du tutoriel -->
              <img :src="tutoriel.imageUrl" class="card-img-top" alt="Image du tutoriel">

              <div class="card-body">
                <h5 class="card-title">{{ tutoriel.titre }}</h5>
                <p class="card-text">{{ tutoriel.description }}</p>

                <!-- Bouton arrondi pour voir le tutoriel -->
                <button @click="openVideoModal(tutoriel.videoUrl)" class="btn btn-success rounded-pill w-100">
                  Voir le tutoriel
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal HTML -->
        <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
             <div class="modal-header" style="background-color: rgb(0, 102, 102);">
              <h5 class="modal-title" id="paylinkModalLabel" style="color: #ffffff;">Vidéo Tutoriel</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <!-- Iframe YouTube -->
                <iframe
                  width="100%"
                  height="100%"
                  :src="videoSrc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";

export default {
  components: { Header, Footer, SideBar },
  data() {
    return {
      videoSrc: '',
      tutoriels: [
        { 
          titre: 'Tutoriel 1', 
          description: 'Créer un payLink.', 
          videoUrl: 'https://www.youtube.com/embed/AyxUGUVLUxE?autoplay=1', 
          imageUrl: 'https://via.placeholder.com/400x200.png?text=Tutoriel+1' 
        },
        { 
          titre: 'Tutoriel 2', 
          description: 'Initié un rembourssement.', 
          videoUrl: 'https://www.youtube.com/embed/XXXXXXX?autoplay=1', 
          imageUrl: 'https://via.placeholder.com/400x200.png?text=Tutoriel+2' 
        },
        { 
          titre: 'Tutoriel 3', 
          description: 'Editer les transactions', 
          videoUrl: 'https://www.youtube.com/embed/YYYYYYY?autoplay=1', 
          imageUrl: 'https://via.placeholder.com/400x200.png?text=Tutoriel+3' 
        },
        { 
          titre: 'Tutoriel 4', 
          description: 'Partager un PayLink', 
          videoUrl: 'https://www.youtube.com/embed/ZZZZZZZ?autoplay=1', 
          imageUrl: 'https://via.placeholder.com/400x200.png?text=Tutoriel+4' 
        }
      ],
    };
  },
  methods: {
    openVideoModal(url) {
      this.videoSrc = url;
      const myModal = new bootstrap.Modal(document.getElementById('videoModal'));
      myModal.show();
    }
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 90vw;
}

.modal-body iframe {
  height: 60vh;
}

</style>

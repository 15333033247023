<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Chart from "chart.js/auto";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {

    },
};
</script>

<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
          <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="title-group mb-3">
                    <h5 class="h5 mb-0 text-center">Bonjour NERO-TECHNOLOGIES</h5>
                </div>

                <div class="row my-4">
                    <div class="col-lg-7 col-12">
                        <div class="custom-block custom-block-balance">
                            <small>TRANSACTIONS</small>
                            <h2 class="mt-2 mb-3">  </h2>
                        </div>
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Statistiques</h5>
                            <canvas id="pie-chart"></canvas>
                        </div>
                    </div>

                    <div class="col-lg-5 col-12">
                        <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white">
                            <div class="custom-block-profile-image-wrap mb-4">
                                <h5>Comptes:</h5>
                            </div>
                            <p class="text-start">
                                <strong>Clients:</strong>
                                <a> </a>
                            </p>
                            <p class="text-start">
                                <strong>Service-Clients:</strong>
                                <a> </a>
                            </p>
                        </div>

                        <div class="custom-block custom-block-bottom d-flex flex-wrap">
                            <div class="custom-block-bottom-item">
                                <a href="#" class="d-flex flex-column" @click="generatePDF">
                                    <i class="custom-block-icon bi-book"></i>
                                    <small>Guide d'utilisation</small>
                                </a>
                            </div>
                        </div>

                        <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white">
                            <div class="custom-block-profile-image-wrap mb-4">
                                <h5>Clients</h5>
                            </div>
                            <canvas id="line-chart"></canvas>
                        </div>

                        <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white">
                            <div class="custom-block-profile-image-wrap mb-4">
                                <h5>Service-Clients</h5>
                            </div>
                            <canvas id="client-line-chart"></canvas>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            transactions: [],
            numero: '',
            destinataire: '',
            currentPage: 1,
            totalPages: 0
        };
    },
    mounted() {

        this.fetchTransactions();
    },

    methods: {
        fetchTransactions() {
            const params = {
                numero: this.numero,
                destinataire: this.destinataire,
                page: this.currentPage
            };

            axios.get('/get-bonus')
                .then(response => {
                    const latestParametre = response.data.latest_parametre;
                    this.startDate = latestParametre.start_date;
                    this.endDate = latestParametre.end_date;
                    this.transactionProgress = latestParametre.transaction_progress;
                    this.bonusProgress = latestParametre.bonus_progress;

                    const params = {
                        numero: this.numero,
                        destinataire: this.destinataire,
                        page: this.currentPage
                    };

                    axios.get(`/total-paiement-expediteur-bonus/${this.startDate}/${this.endDate}/${this.transactionProgress}`, { params })
                        .then(response => {
                            this.transactions = response.data.data;
                            this.currentPage = response.data.current_page;
                            this.totalPages = response.data.last_page;
                        })
                        .catch(error => {
                            console.error("Erreur lors de la récupération des transactions filtrées", error);
                        });
                })
                .catch(error => {
                    console.error(error);
                });

        },

        fetchPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchTransactions();
            }
        },

        fetchNextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchTransactions();
            }
        },


        exportCSV() {
            Swal.fire({
                title: 'Êtes-vous sûr?',
                text: "Vous êtes sur le point d'exporter la liste des clients éligibles au format CSV.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, exporter!',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const csvContent = this.generateCSV();
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'transactions.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    Swal.fire(
                        'Exporté!',
                        'Le fichier CSV a été exporté avec succès.',
                        'success'
                    );
                }
            });
        },

        generateCSV() {
            const header = ['N°', 'Client', 'Numéro', 'Dépense', 'Taux', 'Bonus'].join(',');
            const rows = this.transactions.map((transaction, index) => {
                const bonus = ((transaction.totalMontant * this.bonusProgress) / 100).toFixed(2) + ' FCFA';

                return [
                    index + 1,
                    transaction.nomdestinataire,
                    transaction.datetransaction,
                    transaction.numero,
                    transaction.totalMontant + ' FCFA',
                    this.bonusProgress + ' %',
                    bonus
                ].join(',');
            });

            return [header, ...rows].join('\n');
        },

        exportToPDF() {
            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir exporter ces données en PDF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Exporter',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();
                    let totalPages = 1;

                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png';
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);

                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 23;
                    const text = 'CLIENTS ÉLIGIBLES POUR LE BONUS';
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });

                    doc.setTextColor(0, 0, 0);

                    const tableData = this.transactions.map((transaction, index) => [
                        index + 1,
                        transaction.nomdestinataire,
                        transaction.numero,
                        transaction.totalMontant + ' FCFA',
                        this.bonusProgress + ' %',
                        ((transaction.totalMontant * this.bonusProgress) / 100).toFixed(2) + ' FCFA'
                    ]);

                    const headers = ['N°', 'CLIENTS', 'NUMERO', 'DEPENSE EFFECTUÉE', 'TAUX', 'BONUS'];

                    doc.autoTable({
                        head: [headers],
                        body: tableData,
                        startY: 45,
                        headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff' },
                        didDrawPage: function (data) {
                            totalPages = doc.internal.getNumberOfPages();
                            const bottomMargin = 10;
                            doc.setFontSize(6);
                            doc.text("Page " + data.pageNumber + " sur " + totalPages, 14, doc.internal.pageSize.height - bottomMargin);
                            const currentDate = new Date();
                            const dateString = currentDate.toLocaleDateString();
                            const timeString = currentDate.toLocaleTimeString();
                            doc.text("imprimé le: " + dateString + " à " + timeString, 14, doc.internal.pageSize.height - bottomMargin + 5);
                        },
                    });

                    doc.save('Bonus_Client_Eligible.pdf');

                    Swal.fire({
                        title: 'Succès !',
                        text: 'L\'exportation en PDF a réussi.',
                        icon: 'success',
                    });
                }
            });
        },
    },
};
</script>

<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-6">
                                        <label for="searchNumber">Numéro</label>
                                        <input v-model="numero" @input="fetchTransactions" type="text"
                                            class="form-control" id="searchNumber">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="searchDestinataire">Client</label>
                                        <input v-model="destinataire" @input="fetchTransactions" type="text"
                                            class="form-control" id="searchDestinataire">
                                    </div>
                                </div>

                                <button @click="exportCSV" class="btn btn-sm btn-secondary">
                                    <i class="bi bi-file-earmark-spreadsheet"></i> Export CSV
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="exportToPDF" class="btn btn-sm btn-danger">
                                    <i class="bi bi-file-earmark-pdf"></i> Export PDF
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button class="btn btn-sm btn-success">
                                    <i class="bi bi-gift me-2"></i> Attribuer les Bonus
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h6 class="mb-4">
                                    Clients éligibles du {{ this.startDate }} au {{ this.startDate }} pour le montant de
                                    {{ this.transactionProgress }} FCFA
                                </h6>

                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">CLIENT</th>
                                                <th scope="col" style="color: white;">NUMERO</th>
                                                <th scope="col" style="color: white;">MTE.DEPENSE</th>
                                                <th scope="col" style="color: white;">TAUX</th>
                                                <th scope="col" style="color: white;">BONUS</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="transactions && transactions.length > 0">
                                            <tr v-for="(transaction, index) in transactions" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ transaction.nomdestinataire }}</td>
                                                <td>{{ transaction.numero }}</td>
                                                <td>{{ transaction.totalMontant }} FCFA</td>
                                                <td>{{ this.bonusProgress }} %</td>
                                                <td>{{ ((transaction.totalMontant * this.bonusProgress) /
                                                    100).toFixed(2) }} FCFA</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="12" class="text-center">
                                                    <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                        role="alert">
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <span>Aucun client éligible pour le montant définis.</span>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>

                                    </table>
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <!-- Previous page button -->
                                            <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                                class="btn btn-warning">Précédent</button>
                                            <span>&nbsp;</span>
                                            <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                                class="btn btn-primary">Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<style></style>
<template>
    <main class="main">

        <Head /><br/><br/><br/><br/><br/><br/>
        <div class="container">
            <div class="row justify-content-center align-items-center min-vh-80 py-5">
                <div class="alert alert-info" role="alert">
                    <h5 class="alert-heading">Veuillez saisir l'ID de votre transaction pour télécharger votre reçu de
                        paiement.</h5>
                </div>
                <div class="shadow p-3 mb- bg-body rounded">
                    <div class="text-center mb-4">
                        <h3 class="text-1000">RECHERCHE</h3>
                    </div>
                    <form @submit.prevent="searchTransaction">
                        <div class="mb-3">
                            <label for="IDtransfert" class="form-label">ID Transfert</label>
                            <input type="text" class="form-control" id="IDtransfert" v-model="IDtransfert">
                        </div>
                        <button type="submit" class="btn btn-secondary"
                            style="background-color: #006666;">Rechercher</button>
                    </form>
                </div>
            </div>
        </div>
    </main> <br/><br/>
    <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
            <div class="col-auto">
                <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
            </div>
            <div class="col-auto">
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
            </div>
        </div>
    </div>
</footer>

</template>

<script>
import axios from "@/axs/axios.js";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import Head from "@/components/Head.vue";
import QRCode from 'qrcode';

export default {
    components: { Head },
    data() {
        return {
            IDtransfert: ''
        };
    },
    methods: {
        searchTransaction() {
            // Requête pour obtenir les détails de la transaction en fonction de l'IDtransfert
            axios.get(`/transactions-details?IDtransfert=${this.IDtransfert}`)

                .then(response => {

                    if (response.data.status) {
                        this.generatePDF(response.data.data);
                    } else {
                        Swal.fire({
                            title: 'Erreur',
                            text: 'Aucune transaction trouvée avec cet ID de transfert.',
                            icon: 'error',
                        });
                    }
                })
                .catch(error => {
                    console.error(error);

                    Swal.fire({
                        title: 'Erreur',
                        text: 'Aucune transaction trouvée avec cet ID de transfert.',
                        icon: 'error',
                    });
                });
        },

        generatePDF(transactionDetails) {
            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir télécharger le reçu ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();
                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png';
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);
                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 20;
                    const text = 'REÇU DE PAIEMENT';
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });
                    doc.setTextColor(0, 0, 0);
                    doc.setFontSize(8); // Définir une taille de police plus petite
                    doc.text(`CLIENT`, 10, 55);
                    doc.text(`Nom: ${transactionDetails.debiteur.nom}`, 10, 61);
                    doc.text(`Prénoms: ${transactionDetails.debiteur.prenoms}`, 10, 66);
                    doc.text(`Téléphone: ${transactionDetails.debiteur.tel}`, 10, 72);
                    doc.text(`Adresse: ${transactionDetails.debiteur.adresse}`, 10, 78);
                    doc.text(`WhatsApp: ${transactionDetails.debiteur.telwhatsapp}`, 10, 84);

                    // Informations à inclure dans le code QR
                    const qrData = `
                        CLIENT: ${transactionDetails.debiteur.prenoms} ${transactionDetails.debiteur.nom}
                        EXPEDITEUR: ${transactionDetails.transaction.nomdestinataire}
                        MONTANT: ${transactionDetails.transaction.montant}
                        OPERATEUR: ${transactionDetails.transaction.operateur}
                    `;

                    console.log(qrData);



                    // Convertir les informations en chaîne JSON
                    const qrDataString = JSON.stringify(qrData);

                    // Générer le code QR avec les informations spécifiées
                    QRCode.toDataURL(qrDataString, { errorCorrectionLevel: 'H' }, function (err, url) {
                        if (err) {
                            console.error(err);
                            return;
                        }
                        // Ajouter le code QR au PDF
                        doc.addImage(url, 'PNG', 150, 50, 40, 40);

                        doc.setFontSize(8);
                        doc.text(`#ID transaction: ${transactionDetails.transaction.IDtransfert}`, 10, 95);

                        // Générer le tableau dans le PDF
                        const columns = ['DATE', 'EXPEDITEUR', 'MONTANT', 'NUMERO', 'OPERATEUR'];
                        const data = [
                            [transactionDetails.transaction.datetransaction, transactionDetails.transaction.nomdestinataire, transactionDetails.transaction.montant, transactionDetails.transaction.numero, transactionDetails.transaction.operateur]
                        ];

                        doc.autoTable({
                            head: [columns],
                            body: data,
                            startY: 100,
                            margin: { top: 15 },
                        });


                        // Enregistrer le PDF
                        doc.save('transaction_details.pdf');

                        // Afficher un message de succès
                        Swal.fire({
                            title: 'Succès !',
                            text: 'L\'exportation de votre reçu a réussi.',
                            icon: 'success',
                        });
                    });
                }
            });
        }


    }
};
</script>

<style></style>

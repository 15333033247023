<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            nouveauGestionnaire: {
                email: '',
                password: '',
                nom: '',
                prenom: '',
                user_africamoney_id: '',
                entreprise_id: '',
            },
            loading: false,
            errors: {},
            nouveauSuperviseur: {
                email: '',
                password: '',
                nom: '',
                prenom: '',
                user_africamoney_id: '',
                entreprise_id: '', 
            },
        };
    },
    methods: {
        async ajouterGestionnaire() {
            try {
                const sessionData = JSON.parse(sessionStorage.getItem('userData'));

                // Extraire l'ID de l'entreprise de sessionData
                const entrepriseId = sessionData.entreprise.id;
                this.nouveauGestionnaire.entreprise_id = entrepriseId;

                // Récupérer l'ID de l'utilisateur africamoney
                const userAfricamoneyId = sessionData.id;
                this.nouveauGestionnaire.user_africamoney_id = userAfricamoneyId;


                this.loading = true;
                const response = await axios.post("/ajouter-gestionnaire", this.nouveauGestionnaire);
                console.log("Réponse de l'API lors de l'ajout du gestionnaire :", response.data);

                // Afficher un SweetAlert de succès
                Swal.fire({
                    icon: 'success',
                    title: 'Gestionnaire ajouté avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    // Réinitialiser le formulaire et cacher le modal après le SweetAlert
                    this.nouveauGestionnaire = {
                        email: '',
                        password: '',
                        nom: '',
                        prenom: '',
                        user_africamoney_id: '',
                        entreprise_id: ''
                    };
                    $('#ajouterGestionnaireModal').modal('hide');
                });

            } catch (error) {
                console.error("Erreur lors de l'ajout du gestionnaire :", error);
                if (error.response && error.response.status === 422 && error.response.data.errors) {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.loading = false;
            }
        },
        async ajouterSuperviseur() {
            try {

                const sessionData = JSON.parse(sessionStorage.getItem('userData'));

                const entrepriseId = sessionData.entreprise.id;
                this.nouveauSuperviseur.entreprise_id = entrepriseId;
                const userAfricamoneyId = sessionData.id;
                this.nouveauSuperviseur.user_africamoney_id = userAfricamoneyId;

                this.loading = true;
                const response = await axios.post("/ajouter-superviseur", this.nouveauSuperviseur);
                console.log("Réponse de l'API lors de l'ajout du superviseur :", response.data);

                Swal.fire({
                    icon: 'success',
                    title: 'Superviseur ajouté avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    // Réinitialiser le formulaire et cacher le modal après le SweetAlert
                    this.nouveauSuperviseur = {
                        email: '',
                        password: '',
                        nom: '',
                        prenom: '',
                        user_africamoney_id: '',
                        entreprise_id: ''
                    };
                    $('#ajouterSuperviseurModal').modal('hide');
                });

            } catch (error) {
                console.error("Erreur lors de l'ajout du superviseur :", error);
                if (error.response && error.response.status === 422 && error.response.data.errors) {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>

<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
          <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">

                <button type="button" class="btn btn-primary mt-3" data-bs-toggle="modal"
                    data-bs-target="#ajouterGestionnaireModal">
                    <i class="fas fa-plus"></i> Ajouter un gestionnaire
                </button>
                <span>&nbsp;</span>

                <div class="modal fade" id="ajouterGestionnaireModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header" style="background-color: #006666;">
                                <h1 class="modal-title fs-5" id="exampleModalLabel" style="color: white;"> Ajouter un
                                    gestionnaire</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="ajouterGestionnaire">
                                    <div class="mb-3">
                                        <label for="reference" class="col-form-label">Nom:</label>
                                        <input type="text" class="form-control" id="reference"
                                            v-model="nouveauGestionnaire.nom" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="prenom" class="col-form-label">Prénom:</label>
                                        <input type="text" class="form-control" id="libelle"
                                            v-model="nouveauGestionnaire.prenom" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="col-form-label">E-mail:</label>
                                        <input type="text" class="form-control" id="montant"
                                            v-model="nouveauGestionnaire.email" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="password" class="col-form-label">Mot de passe:</label>
                                        <input type="password" class="form-control" id="echances"
                                            v-model="nouveauGestionnaire.password" required>
                                    </div>
                                    <button type="submit" class="btn btn-success">Enregistrer</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btn-info mt-3" data-bs-toggle="modal"
                    data-bs-target="#ajouterSuperviseurModal">
                    <i class="fas fa-plus"></i> Ajouter un superviseur
                </button>

                <div class="modal fade" id="ajouterSuperviseurModal" tabindex="-1"
                    aria-labelledby="ajouterSuperviseurModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header" style="background-color: #006666;">
                                <h1 class="modal-title fs-5" id="ajouterSuperviseurModalLabel" style="color: white;">
                                    Ajouter un superviseur</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="ajouterSuperviseur">
                                    <div class="mb-3">
                                        <label for="nomSuperviseur" class="col-form-label">Nom:</label>
                                        <input type="text" class="form-control" id="nomSuperviseur"
                                            v-model="nouveauSuperviseur.nom" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="prenomSuperviseur" class="col-form-label">Prénom:</label>
                                        <input type="text" class="form-control" id="prenomSuperviseur"
                                            v-model="nouveauSuperviseur.prenom" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="emailSuperviseur" class="col-form-label">E-mail:</label>
                                        <input type="text" class="form-control" id="emailSuperviseur"
                                            v-model="nouveauSuperviseur.email" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="password" class="col-form-label">Mot de passe:</label>
                                        <input type="password" class="form-control" id="passwordSuperviseur"
                                            v-model="nouveauSuperviseur.password" required>
                                    </div>
                                    <button type="submit" class="btn btn-success">Enregistrer</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Gestionnaires</h5>

                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white;">N°</th>
                                            <th scope="col" style="color: white;">Nom</th>
                                            <th scope="col" style="color: white;">Prénoms</th>
                                            <th scope="col" style="color: white;">E-mail</th>
                                            <th scope="col" style="color: white;">Action</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>

        </div>
    </div>
</template>

<style></style>
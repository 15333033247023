<script>
import Swal from 'sweetalert2';
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            debiteurs: [],
            nouvelActeur: {
                nom: '',
                prenoms: '',
                tel: '',
                telwhatsapp: '',
                adresse: '',
                acontacter: '',
                telacontacter: '',
                lire_francais: '',
                ecrire_francais: ''
            },
            nouveauNumeroPaiement: {
                reseaugsm: '',
                numero: '',
                debiteur_id: ''
            }
        };
    },
    mounted() {
        this.fetchActeurs();
    },
    methods: {
        fetchActeurs() {
            axios.get("/debiteurs", {
                params: {
                    nom: this.nom,
                    tel: this.tel
                }
            })
                .then(response => {
                    this.debiteurs = response.data.debiteurs;
                    console.log(response.data.debiteurs);
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des acteurs", error);
                });
        },


        ajouterActeur() {
            const sessionData = JSON.parse(sessionStorage.getItem('userData'));
            const userAfricamoneyId = sessionData.id;
            this.nouvelActeur.user_africamoney_id = userAfricamoneyId;

            axios.post("/debiteurs", this.nouvelActeur)
                .then(response => {

                    Swal.fire({
                        icon: 'success',
                        title: 'Débiteur ajouté avec succès',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    this.nouvelActeur = {
                        email: '',
                        password: '',
                        numeromtn: '',
                        numeromoov: '',
                        numeroceltis: '',
                        nom: '',
                        prenoms: '',
                        tel: '',
                        telwhatsapp: '',
                        adresse: '',
                        acontacter: '',
                        telacontacter: '',
                        lire_francais: '',
                        ecrire_francais: ''
                    };
                    $('#ajouterActeurModal').modal('hide');
                    this.fetchActeurs();
                })
                .catch(error => {
                    console.error("Erreur lors de l'ajout de l'acteur :", error);
                });
        },

        supprimerDebiteur(id) {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir supprimer ce débiteur ?',
                text: "Cette action est irréversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Oui, supprimer',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`/debiteurs/${id}`)
                        .then(response => {
                            // Si la suppression est réussie
                            Swal.fire({
                                icon: 'success',
                                title: 'Débiteur supprimé avec succès',
                                showConfirmButton: false,
                                timer: 1500
                            });
                            // Mettre à jour la liste des débiteurs après la suppression
                            this.fetchActeurs();
                        })
                        .catch(error => {
                            // Si une erreur se produit lors de la suppression
                            console.error("Erreur lors de la suppression du débiteur :", error);
                            Swal.fire({
                                icon: 'error',
                                title: 'Erreur lors de la suppression du débiteur',
                                text: error.message
                            });
                        });
                }
            });
        },


        ouvrirModalAjouterNumerosPaiement() {
            $('#ajouterNumerosPaiementModal').modal('show');
        },

        ajouterNumerosPaiement() {

            const sessionData = JSON.parse(sessionStorage.getItem('userData'));
            const userAfricamoneyId = sessionData.id;
            this.nouveauNumeroPaiement.user_africamoney_id = userAfricamoneyId;

            axios.post("/numeropaiements", this.nouveauNumeroPaiement)
                .then(response => {
                    console.log("Numéro de paiement ajouté avec succès :", response.data);
                    Swal.fire({
                        icon: 'success',
                        title: 'Numéro de paiement ajouté avec succès',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.nouveauNumeroPaiement = {
                        reseaugsm: '',
                        numero: '',
                        debiteur_id: ''
                    };
                    $('#ajouterNumerosPaiementModal').modal('hide');
                })
                .catch(error => {
                    console.error("Erreur lors de l'ajout du numéro de paiement :", error);
                });
        }
    }
};
</script>


<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
          <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">

                <div class="modal fade" id="ajouterActeurModal" tabindex="-1" aria-labelledby="ajouterActeurModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header" style="background-color: #006666;">
                                <h5 class="modal-title" id="ajouterActeurModalLabel" style="color: white;">Nouveau
                                    client</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    style="color: white;"></button>
                            </div>

                            <div class="modal-body">
                                <form @submit.prevent="ajouterActeur">
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="nom" class="form-label">Nom</label>
                                            <input type="text" class="form-control" id="nom" v-model="nouvelActeur.nom"
                                                required>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="prenoms" class="form-label">Prénoms</label>
                                            <input type="text" class="form-control" id="prenoms"
                                                v-model="nouvelActeur.prenoms" required>
                                        </div>
                                    </div>
                                    <div class="mb-3 ">
                                        <label for="telacontacter" class="form-label">Adresse E-mail</label>
                                        <input type="text" class="form-control" id="telacontacter"
                                            v-model="nouvelActeur.email" required>
                                    </div>
                                    <div class="mb-3 ">
                                        <label for="telacontacter" class="form-label">Mot de passe</label>
                                        <input type="password" class="form-control" id="telacontacter"
                                            v-model="nouvelActeur.password" required>
                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="tel" class="form-label">Téléphone</label>
                                            <input type="text" class="form-control" id="tel" v-model="nouvelActeur.tel"
                                                required>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="telwhatsapp" class="form-label">Téléphone WhatsApp</label>
                                            <input type="text" class="form-control" id="telwhatsapp"
                                                v-model="nouvelActeur.telwhatsapp" required>
                                        </div>
                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="tel" class="form-label">Numéro MTN</label>
                                            <input type="text" class="form-control" id="tel"
                                                v-model="nouvelActeur.numeromtn" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="telwhatsapp" class="form-label">Numéro MOOV</label>
                                            <input type="text" class="form-control" id="telwhatsapp"
                                                v-model="nouvelActeur.numeromoov" required>
                                        </div>
                                    </div>

                                    <div class="mb-3 ">
                                        <label for="telacontacter" class="form-label">Numéro CELTIS</label>
                                        <input type="text" class="form-control" id="telacontacter"
                                            v-model="nouvelActeur.numeroceltis" required>
                                    </div>

                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="ecrire_francais" class="form-label">Sait écrire le
                                                français</label>
                                            <select class="form-select" id="ecrire_francais"
                                                v-model="nouvelActeur.ecrire_francais" required>
                                                <option value="oui">Oui</option>
                                                <option value="non">Non</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="lire_francais" class="form-label">Sait lire le français</label>
                                            <select class="form-select" id="lire_francais"
                                                v-model="nouvelActeur.lire_francais" required>
                                                <option value="oui">Oui</option>
                                                <option value="non">Non</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="adresse" class="form-label">Adresse</label>
                                            <input type="text" class="form-control" id="adresse"
                                                v-model="nouvelActeur.adresse" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="acontacter" class="form-label">Personne à contacter en cas de
                                                besoin</label>
                                            <input type="text" class="form-control" id="acontacter"
                                                v-model="nouvelActeur.acontacter" required>
                                        </div>
                                    </div>
                                    <div class="mb-3 ">
                                        <label for="telacontacter" class="form-label">Teléphone de la personne à
                                            contacter</label>
                                        <input type="text" class="form-control" id="telacontacter"
                                            v-model="nouvelActeur.telacontacter" required>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Enregistrer</button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- Modal pour ajouter les numéros de paiement -->
                <div class="modal fade" id="ajouterNumerosPaiementModal" tabindex="-1"
                    aria-labelledby="ajouterNumerosPaiementModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header" style="background-color: #006666;">
                                <h5 class="modal-title" id="ajouterNumerosPaiementModalLabel" style="color: white;">
                                    Ajouter un nouveau numéro de paiement</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>

                            <div class="modal-body">
                                <form @submit.prevent="ajouterNumerosPaiement()">
                                    <div class="mb-3 row">
                                        <div class="col-md-6">
                                            <label for="reseauGsm" class="form-label">Opérateur Mobile Money</label>
                                            <select class="form-select" id="reseauGsm"
                                                v-model="nouveauNumeroPaiement.reseaugsm">
                                                <option value="MTN">MTN</option>
                                                <option value="MOOV">MOOV</option>
                                                <option value="CELTIS">CELTIS</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="numero" class="form-label">Numéro de paiement</label>
                                            <input type="text" class="form-control" id="numero"
                                                v-model="nouveauNumeroPaiement.numero" required>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label for="debiteur_id" class="form-label">Attaché le numéro à un
                                            débiteur</label>
                                        <select class="form-select" id="debiteur_id"
                                            v-model="nouveauNumeroPaiement.debiteur_id" required>
                                            <option value="" disabled>Sélectionner le client</option>
                                            <option v-for="debiteur in debiteurs.data" :key="debiteur.id"
                                                :value="debiteur.id">
                                                {{ debiteur.nom }} {{ debiteur.prenoms }}
                                            </option>
                                        </select>
                                    </div>

                                    <button type="submit" class="btn btn-primary">Enregistrer</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">

                            <div class="row">
                                <div class="col-md-6">
                                    <label for="searchNom">Nom</label>
                                    <input v-model="nom" @input="fetchActeurs" type="text" class="form-control"
                                        id="searchNom">
                                </div>
                                <div class="col-md-6">
                                    <label for="searchTel">Télephone</label>
                                    <input v-model="tel" @input="fetchActeurs" type="text" class="form-control"
                                        id="searchTel">
                                </div>
                            </div>

                            <br />

                            <div class="mb-2 row">
                                <div class="col-md-6">
                                    <button type="button" class="btn btn-warning mb-3" data-bs-toggle="modal"
                                        data-bs-target="#ajouterActeurModal">
                                        <i class="fas fa-plus"></i> Ajouter un client
                                    </button><span>&nbsp;</span><span>&nbsp;</span>

                                    <button type="button" class="btn btn-danger mb-3"
                                        @click="ouvrirModalAjouterNumerosPaiement()">
                                        <i class="fas fa-plus"></i> Numéros de paiement
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group mb-3">
                                        <input type="file" class="form-control" accept="image/*">
                                        <button class="btn btn-primary" type="button">Importer des clients (CSV)</button>
                                    </div>
                                </div>
                            </div>

                            <h5 class="mb-4">Clients</h5>
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white;">N°</th>
                                            <th scope="col" style="color: white;">Nom</th>
                                            <th scope="col" style="color: white;">Prénoms</th>
                                            <th scope="col" style="color: white;">Télephone</th>
                                            <th scope="col" style="color: white;">Adresse</th>
                                            <th scope="col" style="color: white;">À contacter</th>
                                            <th scope="col" style="color: white;">Télephone</th>
                                            <th scope="col" style="color: white;">Sait lire</th>
                                            <th scope="col" style="color: white;">Sait Écrire</th>
                                            <th scope="col" style="color: white;">Action</th>

                                        </tr>
                                    </thead>


                                    <tbody v-if="debiteurs.data && debiteurs.data.length > 0">
                                        <tr v-for="(debiteur, index) in debiteurs.data" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ debiteur.nom }}</td>
                                            <td>{{ debiteur.prenoms }}</td>
                                            <td>{{ debiteur.tel }}</td>
                                            <td>{{ debiteur.adresse }}</td>
                                            <td>{{ debiteur.acontacter }}</td>
                                            <td>{{ debiteur.telacontacter }}</td>
                                            <td>{{ debiteur.lire_francais }}</td>
                                            <td>{{ debiteur.ecrire_francais }}</td>
                                            <td>

                                                <a :href="'/entreprise/debiteur-details/' + debiteur.id"
                                                    class="btn btn-info btn-sm">
                                                    <i class="fas fa-eye"></i>
                                                </a>
                                                <span>&nbsp;</span>
                                                <button @click="modifierDebiteur(debiteur.id)"
                                                    class="btn btn-warning btn-sm">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <span>&nbsp;</span>
                                                <button @click="supprimerDebiteur(debiteur.id)"
                                                    class="btn btn-danger btn-sm">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tbody v-else>
                                        <tr>
                                            <td colspan="12" class="text-center">
                                                <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                    role="alert">
                                                    <i class="fas fa-trash-alt mr-2"></i>
                                                    <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                    <span>Aucun client disponible.</span>
                                                    <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                    <i class="fas fa-trash-alt mr-2"></i>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</template>

<style></style>
<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            dossier: null
        };
    },
    created() {
        this.fetchData();
    },
    methods: {

        async fetchData() {
            try {
                const dossierId = this.$route.params.id;
                const response = await axios.get(`/dossiers/${dossierId}`);
                this.dossier = response.data.dossier;
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            }
        }
    }
};
</script>
<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="title-group mb-3">
                        <h1 class="h2 mb-0">Details du dossier</h1>
                    </div>
                    <div class="row my-4" v-if="dossier">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block custom-block-transation-detail bg-white">
                                <div class="d-flex flex-wrap align-items-center border-bottom pb-3 mb-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <img src="/images/LOGOAFRICAMONEY.png" class="profile-image img-fluid"
                                                    alt="">
                                                <div>
                                                    <p>DEBITEUR</p>
                                                    <small class="text-muted">Nom: <b>{{ dossier.debiteur.nom
                                                            }}</b></small><br />
                                                    <small class="text-muted">Prénom: <b>{{ dossier.debiteur.prenoms
                                                            }}</b></small><br />
                                                    <small class="text-muted">Tél: <b>{{ dossier.debiteur.tel
                                                            }}</b></small><br />
                                                    <small class="text-muted">Adresse: <b>{{ dossier.debiteur.adresse
                                                            }}</b></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms-auto">
                                        <p>DOSSIER</p>
                                        <small>Début: <b> {{ dossier.date_debut }}</b></small>
                                        <br />
                                        <small>Fin: <b> {{ dossier.date_fin }}</b></small>
                                        <br />
                                        <small>Statut: <span v-if="dossier.statut === 'En cours'"
                                                class="badge text-bg-warning">
                                                {{ dossier.statut }}
                                            </span>
                                            <span v-else-if="dossier.statut === 'Terminé'"
                                                class="badge text-bg-success">
                                                {{ dossier.statut }}
                                            </span>
                                            <span v-else-if="dossier.statut === 'Defaut'" class="badge text-bg-danger">
                                                {{ dossier.statut }}
                                            </span></small>
                                        <strong class="d-block text-success"><span class="me-1">Montant:</span> {{
                                            dossier.montant }} FCFA</strong>
                                    </div>
                                </div>
                                <h3 class="border-bottom pb-3 mb-3 text-center">Information suplémentaire</h3><br />
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="custom-block-transation-detail-item mt-4">
                                        <h6>Référence</h6>
                                        <p> {{ dossier.reference }}</p>
                                    </div>

                                    <div class="custom-block-transation-detail-item mt-4">
                                        <h6>Paiement cash</h6>
                                        <p>{{ dossier.paiement_cash }} FCFA</p>
                                    </div>

                                    <div class="custom-block-transation-detail-item mt-4">
                                        <h6>Périodicité</h6>
                                        <p>{{ dossier.periodicite }}</p>
                                    </div>

                                    <div class="custom-block-transation-detail-item mt-4">
                                        <h6>Remboursement</h6>
                                        <p>{{ dossier.nbrjourremboursement }} Jours</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<style></style>
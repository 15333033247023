<template>
    <header class="navbar sticky-top flex-md-nowrap">
      <div class="col-md-3 col-lg-3 me-0 px-3 fs-6">
        <a class="navbar-brand" href="/">
          <h3 class="text-white"> AfricaMoney </h3>
        </a>
      </div>
      <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <div class="navbar-nav me-lg-2">
        <div class="nav-item text-nowrap d-flex align-items-center">
          <div class="dropdown px-3">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="/../../images/LOGOAFRICAMONEY.png" class="profile-image img-fluid me-3" alt="">
            </a>
            <ul class="dropdown-menu bg-white shadow">
              <li>
                <div class="dropdown-menu-profile-thumb d-flex">
                  <img src="/../../images/LOGOAFRICAMONEY.png" class="profile-image img-fluid me-3" alt="">
                  <div class="d-flex flex-column">
                    <a>{{ userData.nom }}</a>
                  </div>
                </div>
              </li>
  
              <li>
                <a href="/client/parametre" class="dropdown-item">
                  <i class="bi-person me-2"></i>
                  Profil
                </a>
              </li>
  
              <li class="border-top mt-3 pt-2 mx-4">
                <a class="dropdown-item ms-0 me-0" @click="confirmLogout">
                  <i class="bi-box-arrow-left me-2"></i>
                  Déconnexion
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  </template>
  
  <script>
  import Swal from "sweetalert2";
  
  export default {
    data() {
      return {
        userData: {
          nom: "",
          adresse: "",
          telephone: "",
          numero_registre_commerce: "",
        },
      };
    },
    mounted() {
      const userDataString = sessionStorage.getItem("userData");
      if (userDataString) {
        try {
          const parsedUserData = JSON.parse(userDataString);
          this.userData.nom = parsedUserData.additional_info.nom;
          this.userData.adresse = parsedUserData.additional_info.adresse;
          this.userData.telephone = parsedUserData.additional_info.telephone;
          this.userData.numero_registre_commerce = parsedUserData.additional_info.numero_registre_commerce;
        } catch (error) {
          console.error("Erreur lors du parsing de userData depuis sessionStorage:", error);
        }
      }
    },
    methods: {
      confirmLogout() {
        Swal.fire({
          title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Annuler',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.logoutUser();
          }
        });
      },
      logoutUser() {
        sessionStorage.clear();
        window.location.href = "/";
      },
    },
  };
  </script>
  
  <style>
  /* Ajoute ton style ici */
  </style>
  
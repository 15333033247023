<template>
<main class="flex-shrink-0">

    <Head />
    <!-- Header -->
    <header class="py-5">
        <div class="container px-5">
            <div class="row gx-5 align-items-center">
                <div class="col-xxl-5">
                    <div class="text-center text-xxl-start">
                        <p class="fs-3 fw-light text-dark">
                            La solution africaine de paiement et de traçabilité de vos transactions
                        </p>
                        <h1 class="display-5 fw-bolder mb-5">
                            <span class="text-gradient d-inline" style="color: #006666">AfricaMoney</span>
                        </h1>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                            <a class="btn btn-secondary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder" href="/connexion" style="background-color: #006666">Se connecter</a>
                            <a class="btn btn-outline-dark btn-lg px-8 py-3 fs-6 fw-bolder" href="/paylink-user-registration">Créer un compte</a>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-7">
                    <div class="d-flex justify-content-center mt-5 mt-xxl-0">
                        <div class="profile bg-gradient-primary-to-secondary">
                            <img class="profile-img" src="images/LOGOAFRICAMONEY.png" alt="Logo Africamoney" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <!-- Services Section -->
    <section class="bg-light py-5">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="section-title" style="color: #006666">Nos Services</h2>
                <p class="text-muted">Découvrez comment AfricaMoney peut transformer votre gestion des paiements</p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card h-100 shadow-sm border-0">
                        <div class="card-body text-center">
                            <div class="mb-3" style="font-size: 2rem; color: #006666">
                                <i class="fas fa-credit-card"></i>
                            </div>
                            <h4 class="card-title" style="color: #006666">Compte E-MoMo</h4>
                            <p class="card-text">
                                Gérez et suivez vos transactions Mobile-Money en ligne avec un compte E-MoMo compatible avec tous les opérateurs (MTN, MOOV, CELTIIS).
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card h-100 shadow-sm border-0">
                        <div class="card-body text-center">
                            <div class="mb-3" style="font-size: 2rem; color: #006666">
                                <i class="fas fa-exchange-alt"></i>
                            </div>
                            <h4 class="card-title" style="color: #006666">PayLink</h4>
                            <p class="card-text">
                                Partagez un PayLink et recevez des paiements de vos amis, collègues ou clients pour tout type de projet de collecte d'argent.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card h-100 shadow-sm border-0">
                        <div class="card-body text-center">
                            <div class="mb-3" style="font-size: 2rem; color: #006666">
                                <i class="fas fa-dollar-sign"></i>
                            </div>
                            <h4 class="card-title" style="color: #006666">Paiements innovants</h4>
                            <p class="card-text">
                                Proposez des paiements flexibles à vos clients avec AfricaMoney, que vous soyez une école, une boutique ou un prestataire de services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Pourquoi nous choisir Section -->
    <section class="py-5">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="section-title" style="color: #006666">Pourquoi choisir AfricaMoney ?</h2>
                <p class="text-muted">Nous vous offrons une solution unique, sécurisée et simple pour la gestion de vos transactions financières.</p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card h-100 shadow-sm border-0">
                        <div class="card-body text-center">
                            <div class="icon-wrapper mb-3" style="font-size: 2rem; color: #006666">
                                <i class="fas fa-shield-alt"></i>
                            </div>
                            <h4 class="card-title" style="color: #006666">Sécurité de niveau bancaire</h4>
                            <p class="card-text">Nous assurons la protection maximale de vos données et transactions avec des systèmes de sécurité avancés, conformes aux standards internationaux.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card h-100 shadow-sm border-0">
                        <div class="card-body text-center">
                            <div class="icon-wrapper mb-3" style="font-size: 2rem; color: #006666">
                                <i class="fas fa-check-circle"></i>
                            </div>
                            <h4 class="card-title" style="color: #006666">Simplicité d’utilisation</h4>
                            <p class="card-text">Créez, partagez et gérez vos PayLinks facilement, sans avoir besoin de connaissances techniques, pour tout type de collecte de fonds.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card h-100 shadow-sm border-0">
                        <div class="card-body text-center">
                            <div class="icon-wrapper mb-3" style="font-size: 2rem; color: #006666">
                                <i class="fas fa-globe-africa"></i>
                            </div>
                            <h4 class="card-title" style="color: #006666">Couverture national</h4>
                            <p class="card-text">Bénéficiez de nos services partout en Afrique, quel que soit l'endroit où se trouvent vos clients ou partenaires.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Statistiques Section -->
    <section class="py-5">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="section-title" style="color: #006666">Statistiques</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="stat-card shadow-sm p-4 text-center bg-white">
                        <div class="mb-3" style="font-size: 2rem; color: #006666">
                            <i class="fas fa-building"></i>
                        </div>
                        <h3 class="stat-number" style="color: #006666">{{ paylinkTotal }}</h3>
                        <p class="text-muted">PayLinks générés</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="stat-card shadow-sm p-4 text-center bg-white">
                        <div class="mb-3" style="font-size: 2rem; color: #006666">
                            <i class="fas fa-exchange-alt"></i>
                        </div>
                        <h3 class="stat-number" style="color: #006666">{{ transactionCount }}</h3>
                        <p class="text-muted">Transactions effectuées</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="stat-card shadow-sm p-4 text-center bg-white">
                        <div class="mb-3" style="font-size: 2rem; color: #006666">
                            <i class="fas fa-money-bill-wave"></i>
                        </div>
                        <h3 class="stat-number" style="color: #006666">{{ totalMontant }} FCFA</h3>
                        <p class="text-muted">Montant total en FCFA</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Avis des utilisateurs Section -->
    <section class="py-5 bg-light">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="section-title" style="color: #006666">Avis des utilisateurs</h2>
                <p class="text-muted">Découvrez ce que nos clients disent de nous.</p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="testimonial-card p-4 shadow-sm bg-white">
                        <div class="d-flex align-items-center">
                            <img class="rounded-circle me-3" src="images/LOGOAFRICAMONEY.png" alt="Utilisateur 1" style="width: 60px;">
                            <div>
                                <h5 class="mb-0" style="color: #006666">Jean K.</h5>
                                <small class="text-muted">Entrepreneur</small>
                            </div>
                        </div>
                        <p class="mt-3 text-muted">AfricaMoney a transformé la manière dont je gère mes paiements. C’est rapide, sécurisé, et surtout, très simple à utiliser.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="testimonial-card p-4 shadow-sm bg-white">
                        <div class="d-flex align-items-center">
                            <img class="rounded-circle me-3" src="images/LOGOAFRICAMONEY.png" alt="Utilisateur 2" style="width: 60px;">
                            <div>
                                <h5 class="mb-0" style="color: #006666">Fatou S.</h5>
                                <small class="text-muted">Propriétaire de boutique</small>
                            </div>
                        </div>
                        <p class="mt-3 text-muted">Grâce à AfricaMoney, mes clients peuvent payer rapidement et sans tracas, même depuis l’étranger.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="testimonial-card p-4 shadow-sm bg-white">
                        <div class="d-flex align-items-center">
                            <img class="rounded-circle me-3" src="images/LOGOAFRICAMONEY.png" alt="Utilisateur 3" style="width: 60px;">
                            <div>
                                <h5 class="mb-0" style="color: #006666">Amadou B.</h5>
                                <small class="text-muted">Freelancer</small>
                            </div>
                        </div>
                        <p class="mt-3 text-muted">Le système PayLink est génial. J'ai pu recevoir des paiements pour mes services freelance très facilement.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- FAQ Section -->
    <section>
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="section-title" style="color: #006666">
                    Questions Fréquemment Posées
                </h2>
                <p class="section-subtitle text-muted">
                    Vous avez des questions ? Nous avons les réponses.
                </p>
            </div>
            <div class="accordion" id="faqAccordion">
                <div class="accordion-item">
                    <h4 class="accordion-header" id="faq1">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1" style="color: #006666">
                            Comment créer un PayLink ?
                        </button>
                    </h4>
                    <div id="collapse1" class="accordion-collapse collapse show" aria-labelledby="faq1" data-bs-parent="#faqAccordion">
                        <div class="accordion-body">
                            Pour créer un PayLink, rendez-vous sur notre page de création et
                            suivez les instructions pour configurer votre lien de paiement.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h4 class="accordion-header" id="faq2">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2" style="color: #006666">
                            Quels sont les frais de transaction ?
                        </button>
                    </h4>
                    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="faq2" data-bs-parent="#faqAccordion">
                        <div class="accordion-body">
                            Les frais de transaction sont clairement indiqués lors de la
                            création de chaque PayLink. Nous offrons des tarifs compétitifs
                            pour toutes vos transactions.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h4 class="accordion-header" id="faq3">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3" style="color: #006666">
                            Puis-je suivre mes transactions en temps réel ?
                        </button>
                    </h4>
                    <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="faq3" data-bs-parent="#faqAccordion">
                        <div class="accordion-body">
                            Oui, vous pouvez suivre vos transactions en temps réel via notre
                            tableau de bord intuitif.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h4 class="accordion-header" id="faq4">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4" style="color: #006666">
                            Comment contacter le support ?
                        </button>
                    </h4>
                    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="faq4" data-bs-parent="#faqAccordion">
                        <div class="accordion-body">
                            Vous pouvez contacter notre support via le formulaire de contact
                            sur notre site ou par email à support@africamoney.com.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Footer -->
    <footer class="py-4 mt-auto" style="background-color: #006666; color: white">
        <div class="container">
            <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
                <div class="col-auto">
                    <p class="small text-white m-0">&copy; AfricaMoney {{ new Date().getFullYear() }}</p>
                </div>
                <div class="col-auto">
                    <a class="small text-white text-decoration-none mx-2" href="#!">Confidentialité</a>
                    <a class="small text-white text-decoration-none mx-2" href="#!">Termes et Conditions</a>
                </div>
            </div>
        </div>
    </footer>
</main>
</template>

<script>
import Head from "@/components/Head.vue";
import axios from "@/axs/axios.js";

export default {
    components: {
        Head
    },
    data() {
        return {
            paylinkTotal: 0,
            totalMontant: 0,
            transactionCount: 0,
        };
    },
    mounted() {
        this.fetchPaylinkStats();
    },
    methods: {
        async fetchPaylinkStats() {
            try {
                const response = await axios.get("/paylinks-total");
                this.paylinkTotal = response.data.paylink_total;
                this.totalMontant = response.data.total_montant;
                this.transactionCount = response.data.transaction_count;
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des statistiques :",
                    error
                );
            }
        },
    },
};
</script>

<style>
.section-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.section-subtitle {
    font-size: 1.1rem;
    color: #666;
}

.card {
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: translateY(-10px);
}

.service-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    margin: 0 auto;
}
</style>

<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Recherche</h5>
                            <div class="row mb-4">
                                <div class="col-md-12">
                                    <input type="text" class="form-control" id="searchDossier">
                                </div>
                            </div>
                            <button type="button" class="btn btn-sm btn-primary"  @click="openModal">  <i class="fas fa-plus"></i>Ajouter</button>
                        </div>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Service Clients</h5>
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white;">N°</th>
                                            <th scope="col" style="color: white;">NOM</th>
                                            <th scope="col" style="color: white;">PRENOM</th>
                                            <th scope="col" style="color: white;">E-mail</th>
                                            <th scope="col" style="color: white;">Téléphone</th>
                                            <th scope="col" style="color: white;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(service, index) in services.data" :key="service.id">
                                            <td>{{ index + 1 + (services.current_page - 1) * services.per_page }}</td>
                                            <td>{{ service.nom }}</td>
                                            <td>{{ service.prenom }}</td>
                                            <td>{{ service.user.email }}</td>
                                            <td>{{ service.numero }}</td>
                                            <td>
                                                <button 
                                                    class="btn btn-warning btn-sm">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <span>&nbsp;</span>
                                                <button 
                                                    class="btn btn-danger btn-sm">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Pagination -->
                            <nav aria-label="Page navigation">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: !services.prev_page_url }">
                                        <a class="page-link" href="#" @click.prevent="fetchServices(services.prev_page_url)">Précédent</a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: !services.next_page_url }">
                                        <a class="page-link" href="#" @click.prevent="fetchServices(services.next_page_url)">Suivant</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                <!-- Modal pour ajouter un service client -->
                <div class="modal fade" id="addClientModal" tabindex="-1" aria-labelledby="addClientModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="addClientModalLabel">Ajouter un service client</h5>
                                <button type="button" class="btn-close" @click="closeModal"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="submitForm">
                                    <div class="mb-3">
                                        <label for="nom" class="form-label">Nom</label>
                                        <input type="text" class="form-control" id="nom" v-model="form.nom" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="prenom" class="form-label">Prénom</label>
                                        <input type="text" class="form-control" id="prenom" v-model="form.prenom" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label">E-mail</label>
                                        <input type="email" class="form-control" id="email" v-model="form.email" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="numero" class="form-label">Téléphone</label>
                                        <input type="text" class="form-control" id="numero" v-model="form.numero" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="password" class="form-label">Mot de passe</label>
                                        <input type="password" class="form-control" id="password" v-model="form.password" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="password_confirmation" class="form-label">Confirmer le mot de passe</label>
                                        <input type="password" class="form-control" id="password_confirmation" v-model="form.password_confirmation" required>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Enregistrer</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Swal from 'sweetalert2';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            form: {
                nom: '',
                prenom: '',
                email: '',
                numero: '',
                password: '',
                password_confirmation: '',
            },
            services: {},
        };
    },
    mounted() {

        this.fetchServices();
    },
    methods: {
        openModal() {
            const modal = new bootstrap.Modal(document.getElementById('addClientModal'));
            modal.show();
        },
        closeModal() {
            const modal = bootstrap.Modal.getInstance(document.getElementById('addClientModal'));
            modal.hide();
        },
        submitForm() {
            axios.post('/add-service-clients', this.form)
                .then(response => {
                    Swal.fire('Succès', 'Service client ajouté avec succès', 'success');
                    this.closeModal();
                    this.resetForm();
                    this.fetchServices();
                })
                .catch(error => {
                    if (error.response && error.response.data.errors) {
                        Swal.fire('Erreur', error.response.data.errors.join(', '), 'error');
                    } else {
                        Swal.fire('Erreur', 'Une erreur est survenue', 'error');
                    }
                });
        },
        resetForm() {
            this.form = {
                nom: '',
                prenom: '',
                email: '',
                numero: '',
                password: '',
                password_confirmation: '',
            };
        },
        fetchServices() {

            axios.get('/get-service-clients')
                .then(response => {
                    this.services = response.data;
                })
                .catch(error => {
                    Swal.fire('Erreur', 'Impossible de récupérer les services clients', 'error');
                });
        }
    }
};
</script>

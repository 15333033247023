<template>
    <main class="main">
        <nav class="navbar navbar-expand-lg navbar-light bg-#006666 py-3">
            <div class="container px-5">
                <a class="navbar-brand" href="/">
                    <img src="images/LOGOAFRICAMONEY.png" alt="Logo AfricaMoney" class="logo-image">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation"><span
                        class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                        <li class="nav-item"><a class="nav-link text-white" href="/">Acceuil</a></li>
                        <li class="nav-item"><a class="nav-link text-white" href="#">Service</a></li>
                        <li class="nav-item"><a class="nav-link text-white" href="/verification">Verification</a></li>
                        <li class="nav-item"><a class="nav-link text-white" href="#">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="container">
            <div class="row justify-content-center align-items-center min-vh-100 py-5">
                <div class="col-md-10 col-lg-7 col-xl-6">
                    <div class="shadow p-3 mb-5 bg-body rounded">
                        <div class="text-center mb-7">
                            <h3 class="text-1000">RECHERCHE</h3>
                        </div>
                        <form @submit.prevent="searchDossier">
                            <div class="mb-3">
                                <label for="reference" class="form-label">Reference</label>
                                <input type="text" class="form-control" id="reference" v-model="reference">
                            </div>
                            <button type="submit" class="btn btn-primary">Rechercher</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import axios from "@/axs/axios.js";
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            reference: ''
        };
    },
    methods: {
        searchDossier() {
            axios.get(`/get-dossier-details?reference=${this.reference}`)
                .then(response => {
                    if (response.data.status) {
                        // Traitez les données de la réponse ici
                        console.log(response.data.data);
                    } else {
                        Swal.fire({
                            title: 'Erreur',
                            text: response.data.message,
                            icon: 'error',
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    Swal.fire({
                        title: 'Erreur',
                        text: 'Une erreur est survenue lors de la recherche.',
                        icon: 'error',
                    });
                });
        }
    }
};
</script>

<style></style>

<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
          <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="container">
                    <div class="row justify-content-center align-items-center min-vh-100 py-5">
                        <div class="col-md-7">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <h3 class="text-center mb-4">INSCRIPTION</h3>
                                        <form @submit.prevent="registerUser">
                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="entreprise">Entreprise</label>
                                                <input class="form-control" v-model="entreprise" id="entreprise"
                                                    type="text" required />
                                                <p v-if="errors.entreprise" class="text-danger">{{ errors.entreprise[0]
                                                    }}</p>
                                            </div>
                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="telephone">Télephone</label>
                                                <input class="form-control" v-model="telephone" id="telephone"
                                                    type="tel" required />
                                                <p v-if="errors.telephone" class="text-danger">{{ errors.telephone[0] }}
                                                </p>
                                            </div>
                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="categorie">Catégorie</label>
                                                <select class="form-select" v-model="categorie" id="categorie">
                                                    <option value="">Sélectionner une catégorie</option>
                                                    <option value="RESTAURANT">Restaurant</option>
                                                    <option value="SUPER MARCHÉ">Supermarché</option>
                                                    <option value="MICRO FINANCE">Microfinance</option>
                                                    <option value="BTP">Btp</option>
                                                    <option value="MICRO FINANCE">Autre</option>
                                                </select>
                                                <p v-if="errors.categorie" class="text-danger">{{ errors.categorie[0] }}
                                                </p>
                                            </div>

                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="marchandmtn">Marchand MTN</label>
                                                <input class="form-control" v-model="marchandmtn" id="marchandmtn"
                                                    type="text" />
                                                <p v-if="errors.marchandmtn" class="text-danger">{{
                                            errors.marchandmtn[0] }}</p>
                                            </div>
                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="marchandmoov">Marchand MOOV</label>
                                                <input class="form-control" v-model="marchandmoov" id="marchandmoov"
                                                    type="text" />
                                                <p v-if="errors.marchandmoov" class="text-danger">{{
                                            errors.marchandmoov[0] }}</p>
                                            </div>
                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="marchandceltis">Marchand Celtis</label>
                                                <input class="form-control" v-model="marchandceltis" id="marchandceltis"
                                                    type="text" />
                                                <p v-if="errors.marchandceltis" class="text-danger">{{
                                            errors.marchandceltis[0] }}</p>
                                            </div>
                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="email">Email</label>
                                                <input class="form-control" v-model="email" id="email" type="email"
                                                    required />
                                                <p v-if="errors.email" class="text-danger">{{ errors.email[0] }}</p>
                                            </div>
                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="adresse">Adresse</label>
                                                <input class="form-control" v-model="adresse" id="adresse"
                                                    type="text" />
                                                <p v-if="errors.adresse" class="text-danger">{{ errors.adresse[0] }}</p>
                                            </div>
                                            <div class="mb-3 text-start">
                                                <label class="form-label" for="logo">Logo de l'entreprise</label>
                                                <input class="form-control" id="logo" type="file" accept="image/*" v-on:change="onFileChange"/>
                                                <p v-if="errors.logo" class="text-danger">{{ errors.logo[0] }}</p>
                                            </div>
                                            <div class="row g-3 mb-3">
                                                <div class="col-sm-6">
                                                    <label class="form-label" for="password">Mot de passe</label>
                                                    <input class="form-control form-icon-input" v-model="password"
                                                        id="password" type="password" required />
                                                    <p v-if="errors.password" class="text-danger">{{ errors.password[0]
                                                        }}</p>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label class="form-label" for="confirmPassword">Confirmation</label>
                                                    <input class="form-control form-icon-input"
                                                        v-model="password_confirmation" id="password_confirmation"
                                                        type="password" required />
                                                    <p v-if="errors.password_confirmation" class="text-danger">{{
                                            errors.password_confirmation[0] }}
                                                    </p>
                                                </div>
                                            </div>
                                            <button class="btn btn-secondary w-100 mb-3"
                                                style="background-color: #006666;" @click="registerUser"
                                                :disabled="loading">
                                                <span v-if="loading">Chargement...</span>
                                                <span v-else>Inscrire</span>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </main>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2'; // Importez SweetAlert2

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            entreprise: '',
            telephone: '',
            marchandmtn: '',
            marchandmoov: '',
            marchandceltis: '',
            email: '',
            adresse: '',
            categorie: '',
            password: '',
            password_confirmation: '',
            logo: null, // Ajout de la variable pour stocker le logo de l'entreprise
            loading: false,
            errors: {}
        };
    },

    methods: {
        async registerUser() {
            try {
                this.loading = true;

                // Création d'un objet FormData pour envoyer les données du formulaire, y compris le fichier de logo
                const formData = new FormData();
                formData.append('entreprise', this.entreprise);
                formData.append('telephone', this.telephone);
                formData.append('marchandmtn', this.marchandmtn);
                formData.append('marchandmoov', this.marchandmoov);
                formData.append('marchandceltis', this.marchandceltis);
                formData.append('email', this.email);
                formData.append('adresse', this.adresse);
                formData.append('categorie', this.categorie);
                formData.append('password', this.password);
                formData.append('password_confirmation', this.password_confirmation);
                formData.append('role_id', 2);
                formData.append('logo', this.logo); // Ajout du fichier de logo

                const response = await axios.post('/register', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data' // Indique au serveur que le contenu est de type formulaire multipart
                    }
                });

                // Afficher SweetAlert lorsque le compte est créé avec succès
                Swal.fire({
                    icon: 'success',
                    title: 'Entreprise créé avec succès!',
                    toast: true,
                    timer: 3000,
                    position: 'top-end',
                    showConfirmButton: false
                });
                
                window.location.reload();
                
            } catch (error) {
                console.error('Erreur lors de la requête d\'inscription :', error);
                if (error.response && error.response.status === 422 && error.response.data.errors) {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.loading = false;
            }
        },
        onFileChange(event) {
            // Mettre à jour la variable logo avec le fichier sélectionné
            this.logo = event.target.files[0];
        }
    },
};
</script>



<style>
/* Styles personnalisés si nécessaire */
</style>
<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-4">
                                        <label for="searchNumber">Numéro</label>
                                        <input v-model="numero" @input="fetchDossiersWithTransactions" type="text"
                                            class="form-control" id="searchNumber">
                                    </div>

                                    <div class="col-md-4">
                                        <label for="searchLibelle">Objet</label>
                                        <select v-model="libelle" @change="fetchDossiersWithTransactions"
                                            class="form-control" id="searchLibelle">
                                            <option value="">Sélectionner un l'objet</option>
                                            <option value="crédit">Crédit</option>
                                            <option value="epargne">Épargne</option>
                                            <option value="loyer">Loyer</option>
                                            <option value="remboursement prêt">Remboursement Prêt</option>
                                            <option value="remboursement dette">Remboursement Dette</option>
                                            <option value="scolarité">Scolarité</option>
                                            <option value="tontine">Tontine</option>
                                            <option value="autre">Autre</option>
                                        </select>
                                    </div>
                                </div>
                                <button type="button" @click="goBack()" class="btn btn-outline-success text-center"><i
                                        class="bi bi-arrow-return-left"></i></button>
                                <span>&nbsp;</span>
                            </div>
                        </div>
                    </div>
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Paiement des dossiers</h5>
                                <div class="table-responsive">
                                    <table v-if="dossiers.length > 0" class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">REFERENCE</th>
                                                <th scope="col" style="color: white;">OBJET</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">EXPEDITEUR</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                                <th scope="col" style="color: white;">GSM</th>
                                                <th scope="col" style="color: white;">OPERATION</th>
                                                <th scope="col" style="color: white;">NUMERO</th>
                                                <th scope="col" style="color: white;">MESSAGE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(dossier, dIndex) in dossiers" :key="dIndex">
                                                <tr v-for="(transaction, tIndex) in dossier.transactions" :key="tIndex">
                                                    <td>{{ dIndex * dossier.transactions.length + tIndex + 1 }}</td>
                                                    <td>{{ dossier.dossier.reference }}</td>
                                                    <td>{{ dossier.dossier.libelle }}</td>
                                                    <td>{{ transaction.datetransaction }}</td>
                                                    <td>{{ transaction.nomdestinataire }}</td>
                                                    <td>{{ transaction.montant }}</td>
                                                    <td>
                                                        <img v-if="transaction.operateur === 'MTN MONEY'"
                                                            src="/../../images/MOMOPAY.png" alt="MTN MONEY"
                                                            style="width:35px; height:34px;">
                                                        <img v-else-if="transaction.operateur === 'MOOV MONEY'"
                                                            src="/../../images/MOOVFLOOZ.png" alt="MOOV MONEY"
                                                            style="width:35px; height:34px;">
                                                        <img v-else-if="transaction.operateur === 'CELTIIS CASH'"
                                                            src="/../../images/LOGOCELTIS.png" alt="CELTIIS CASH"
                                                            style="width:35px; height:34px;">
                                                        <img v-else-if="transaction.operateur === 'DGI'"
                                                            src="/../../images/LOGODGI.jpg" alt="DGI"
                                                            style="width:35px; height:34px;">
                                                        <span v-else>{{ transaction.operateur }}</span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="transaction.mode === 1 || transaction.mode === 2">RETRAIT</span>
                                                        <span v-else-if="transaction.mode === 3">DEPOT</span>
                                                        <span v-else>{{ transaction.mode }}</span>
                                                    </td>
                                                    <td>{{ transaction.numero }}</td>
                                                    <td>{{ transaction.smsrecu }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <div v-else class="text-center">
                                        <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                            role="alert">
                                            <i class="fas fa-trash-alt mr-2"></i>
                                            <!-- Icône de corbeille --> <span>&nbsp;</span>
                                            <span>Aucune donnée disponible pour les critères de recherche
                                                sélectionnés.</span>
                                            <!-- Icône de corbeille --> <span>&nbsp;</span>
                                            <i class="fas fa-trash-alt mr-2"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <!-- Show current page and total pages -->
                                            Page 1
                                        </div>
                                        <div>
                                            <!-- Previous page button -->
                                            <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                                class="btn btn-warning">Précédent</button>
                                            <span>&nbsp;</span>
                                            <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                                class="btn btn-primary">Suivant</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            dossiers: [],
            libelle: '',
            numero: ''
        };
    },
    methods: {
        async fetchDossiersWithTransactions() {
            try {
                const response = await axios.get('/get-all-dossiers-transactions', {
                    params: {
                        libelle: this.libelle,
                        numero: this.numero
                    }
                });
                this.dossiers = response.data.data;
            } catch (error) {
                console.error('Error fetching dossiers with transactions:', error);
            }
        },
        goBack() {
            this.$router.back();
        },
    },
    created() {
        this.fetchDossiersWithTransactions();
    }
};
</script>

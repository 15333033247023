<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <label for="date">Date de pointage</label>
                                        <input type="date" id="date" class="form-control" v-model="filters.date_pointage" @change="applyFilters">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="searchNumber">Numéro</label>
                                        <input type="text" class="form-control" id="searchNumber" v-model="filters.telephonemtn" @input="applyFilters">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="role">Rôle</label>
                                        <input type="text" class="form-control" id="role" v-model="filters.role" @input="applyFilters">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="searchOuvrier">Ouvrier</label>
                                        <input type="text" class="form-control" id="searchOuvrier" v-model="filters.search" @input="applyFilters">
                                    </div>
                                </div>

                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <a class="btn btn-sm btn-success" href="/entreprise/listes-ouvriers">
                                    <i class="bi bi-arrow-right-square-fill me-2"></i> Listes des ouvriers
                                </a>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <input type="file" @change="handleFileUpload" />
                                <button @click="uploadFile" class="btn btn-sm btn-secondary mt-2">
                                    <i class="bi bi-file-earmark-arrow-up"></i> Soumettre la liste des ouvriers
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Liste des ouvriers pointés</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666; color: white;">
                                            <tr>
                                                <th scope="col">N°</th>
                                                <th scope="col">NOM & PRENOM</th>
                                                <th scope="col">ROLE</th>
                                                <th scope="col">DATE DE POINTAGE</th>
                                                <th scope="col">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(pointage, index) in ouvriersPointes" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ pointage.ouvrier.nom }} {{ pointage.ouvrier.prenom }}</td>
                                                <td>{{ pointage.ouvrier.role }}</td>
                                                <td>{{ pointage.date_pointage }}</td>
                                                <td>
                                                    <button class="btn btn-warning btn-sm">
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                    <button class="btn btn-danger btn-sm">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <!-- Afficher un message si aucun résultat n'est trouvé -->
                                            <tr v-if="ouvriersPointes.length === 0">
                                                <td colspan="5" class="text-center">Aucun résultat trouvé.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            file: null,
            ouvriersPointes: [],
            filters: {
                date_pointage: '',
                telephonemtn: '',
                role: '',
                search: ''
            }
        };
    },
    mounted() {
        this.fetchOuvriersPointes();
    },
    methods: {
        async fetchOuvriersPointes() {
            try {
                const response = await axios.get('/ouvriers-pointes', { params: this.filters });
                this.ouvriersPointes = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des ouvriers pointés:', error);
            }
        },

        handleFileUpload(event) {
            this.file = event.target.files[0];
        },

        async uploadFile() {
            if (!this.file) {
                Swal.fire('Erreur', 'Veuillez sélectionner un fichier à importer.', 'error');
                return;
            }

            try {
                // Récupérer l'ID de l'entreprise depuis sessionStorage
                const userData = JSON.parse(sessionStorage.getItem('userData'));
                const entrepriseId = userData.entreprise.id;

                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('entreprise_id', entrepriseId); // Ajouter l'ID de l'entreprise au FormData

                const response = await axios.post('/import-ouvriers', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                Swal.fire('Succès', response.data.success, 'success');
            } catch (error) {
                Swal.fire('Erreur', 'Une erreur s\'est produite lors de l\'importation du fichier.', 'error');
            }
        },

        applyFilters() {
            // Appliquer des filtres après un délai court pour éviter des appels répétés à l'API lors de la saisie
            setTimeout(() => {
                this.fetchOuvriersPointes();
            }, 300); // ajustez le délai en fonction de vos besoins
        }
    }
};
</script>

<style scoped>
/* Ajoutez vos styles ici si nécessaire */
</style>

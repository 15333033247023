<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            reference: '',
            dossier: null // Initialize dossier as null
        };
    },

    methods: {
        searchDossier() {
            axios.get(`/get-dossier-details?reference=${this.reference}`)
                .then(response => {
                    if (response.data.status) {
                        this.dossier = response.data.data; // Assign response data to dossier
                    } else {
                        Swal.fire({
                            title: 'Erreur',
                            text: response.data.message,
                            icon: 'error',
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    Swal.fire({
                        title: 'Erreur',
                        text: 'Une erreur est survenue lors de la recherche.',
                        icon: 'error',
                    });
                });
        },


        confirmExportCSV() {
            Swal.fire({
                title: 'Export CSV',
                text: 'Êtes-vous sûr de vouloir exporter les transactions en CSV ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.generateCSV();
                }
            });
        },

        generateCSV() {
            if (!this.dossier || !this.dossier.transactions) return;

            const csvContent = "data:text/csv;charset=utf-8,"
                + this.dossier.transactions.map(transaction =>
                    Object.values(transaction).join(",")
                ).join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "transactions.csv");
            document.body.appendChild(link);
            link.click();
        },


        calculateMontants() {
            if (!this.dossier || !this.dossier.transactions) return;

            const totalMontantTransactions = this.dossier.transactions.reduce((acc, transaction) => {
                return acc + parseFloat(transaction.montant);
            }, 0);

            const resteAPayer = parseFloat(this.dossier.montant) - totalMontantTransactions;

            return {
                montantAPayer: this.dossier.montant,
                montantPaye: totalMontantTransactions,
                reste: resteAPayer
            };
        },

        exportToPDF() {
            if (!this.dossier) return;

            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir télécharger le reçu ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    
                    const doc = new jsPDF();

                    doc.setFillColor(0, 102, 102);

                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');

                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png';

                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);


                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 20;
                    const text = `RAPPORT DU DOSSIER: ${this.dossier.reference}`;
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });

                    doc.setTextColor(0, 0, 0);

                    const columns = ['REFERENCE DOSSIER', 'MONTANT À PAYER', 'MONTANT PAYÉ', 'RESTE À PAYER'];
                    const montants = this.calculateMontants();
                    const data = [
                        [this.dossier.reference, montants.montantAPayer, montants.montantPaye, montants.reste]
                    ];

                    doc.autoTable({
                        head: [columns],
                        body: data,
                        startY: 50,
                        headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff' },
                    });

                    doc.save('recu_transaction.pdf');
                }
            });
        }
    }
};

</script>


<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <form @submit.prevent="searchDossier">
                        <div class="mb-3">
                            <label for="reference" class="form-label">Reference</label>
                            <input type="text" class="form-control" id="reference" v-model="reference">
                        </div>
                        <button type="submit" class="btn btn-primary">Rechercher</button>
                    </form> <br />
                    <button @click="confirmExportCSV" class="btn btn-sm btn-warning">
                        <i class="bi bi-file-earmark-spreadsheet"></i> Export CSV
                    </button>
                    <span>&nbsp;</span>
                    <button @click="exportToPDF" class="btn btn-sm btn-danger"><i
                            class="bi bi-file-earmark-pdf"></i>Export
                        PDF</button>
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Historiques des transactions</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">EXPEDITEUR</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                                <th scope="col" style="color: white;">OPERATION</th>
                                                <th scope="col" style="color: white;">GSM</th>
                                                <th scope="col" style="color: white;">MESSAGE</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="dossier">
                                            <!-- Utilisez une boucle v-for pour afficher chaque transaction -->
                                            <tr v-for="(transaction, index) in dossier.transactions" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ transaction.datetransaction }}</td>
                                                <td>{{ transaction.nomdestinataire }}</td>
                                                <td>{{ transaction.montant }}</td>
                                                <td>
                                                    <span
                                                        v-if="transaction.mode === 1 || transaction.mode === 2">RETRAIT</span>
                                                    <span v-else-if="transaction.mode === 3">DEPOT</span>
                                                    <span v-else>{{ transaction.mode }}</span>
                                                </td>
                                                <td>
                                                    <img v-if="transaction.operateur === 'MTN MONEY'"
                                                        src="/../../images/MOMOPAY.png" alt="MTN MONEY"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'MOOV MONEY'"
                                                        src="/../../images/MOOVFLOOZ.png" alt="MOOV MONEY"
                                                        style="width:35px; height:34px;">
                                                    <img v-else-if="transaction.operateur === 'CELTIS CASH'"
                                                        src="/../../images/LOGOCELTIS.png" alt="CELTIS CASH"
                                                        style="width:35px; height:34px;">
                                                    <span v-else>{{ transaction.operateur }}</span>
                                                </td>
                                                <td>{{ transaction.smsrecu }}</td>
                                            </tr>
                                        </tbody>

                                        <tbody v-else>
                                            <tr>
                                                <td colspan="12" class="text-center">
                                                    <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                        role="alert">
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <span>Veuillez entrez la réference du dossier pour afficher les
                                                            transactions.</span>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                        class="btn btn-warning">Précédent</button>
                                    <span>&nbsp;</span><span>&nbsp;</span>
                                    <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                        class="btn btn-primary">Suivant</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

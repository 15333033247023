<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            caisses: [],
            marchandmtn: '',
            marchandmoov: '',
            marchandceltis: '',
            gsmSolde: '',
            soldeDepart: 0,
            soldeActuel: 0,
            total: 0,
            observation: '',
            entrepriseId: '',
            operateur_gsm: '',
            date: ''
        };
    },
    mounted() {
        const userDataString = sessionStorage.getItem('userData');
        const userData = JSON.parse(userDataString);

        this.marchandmtn = userData.entreprise.marchandmtn;
        this.marchandmoov = userData.entreprise.marchandmoov;
        this.marchandceltis = userData.entreprise.marchandceltis;
        this.entrepriseId = userData.entreprise.id;

        this.fetchCaisseData();
    },
    watch: {
        date(newValue, oldValue) {
            this.fetchSolde();
        },
        gsmSolde(newValue, oldValue) {
            this.fetchSolde();
        }
    },
    methods: {
        goBack() {
            this.$router.back();
        },

        updateTotal() {

            const soldeDepartNum = parseFloat(this.soldeDepart) || 0;
        },

        getCurrentDate() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        calculateTotal() {
            // Convertir les valeurs en nombres si nécessaire
            const totalDepartNum = parseFloat(this.totalDepart) || 0;
            const soldeDepartNum = parseFloat(this.soldeDepart) || 0;

            // Calculer la somme
            return totalDepartNum + soldeDepartNum;
        },

        fetchCaisseData() {
            const params = {
                date: this.date,
                operateur_gsm: this.operateur_gsm
            };

            const entrepriseId = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')).entreprise.id : null;
            if (entrepriseId) {
                axios.get(`/caisses/${entrepriseId}`, { params })
                    .then(response => {
                        this.caisses = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.totalPages = response.data.last_page;

                        // Assigner la dernière valeur de total
                        if (this.caisses.length > 0) {
                            this.totalDepart = this.caisses[this.caisses.length - 1].total;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        Swal.fire('Erreur !', 'Une erreur s\'est produite lors de la récupération des données des caisses.', 'error');
                    });
            } else {
                console.error('Impossible de récupérer l\'identifiant de l\'entreprise depuis la session utilisateur.');
            }
        },


        fetchSolde() {
            if (!this.date || !this.gsmSolde) {
                return;
            }

            const params = {
                datetransaction: this.date,
                operateur: this.gsmSolde
            };

            axios.get("/total-paiement-par-date", { params })
                .then(response => {
                    this.soldeDepart = response.data.data.total_montant_selected_date;
                    this.updateTotal
                })
                .catch(error => {
                    console.error(error);
                });
        },
        ValiderCaisse() {
            axios.get(`/last-validation-date/${this.entrepriseId}`)
                .then(response => {
                    const lastValidationDate = response.data;

                    // Permettre la validation si lastValidationDate n'existe pas,
                    if (!lastValidationDate) {
                        this.validerSansVerificationDate();
                        return;
                    }

                    const nextDate = moment(lastValidationDate).add(1, 'days').format('YYYY-MM-DD');

                    if (nextDate !== this.date) {
                        Swal.fire('Erreur !', `Vous devez d'abord valider la caisse pour cette date ${nextDate}.`, 'error');
                        return;
                    }

                    // Obtenir la valeur du total à partir de l'input
                    const totalInput = document.getElementById('total');
                    const totalValue = parseFloat(totalInput.value) || 0;

                    const formData = {
                        date: this.date,
                        operateur_gsm: this.gsmSolde,
                        solde_depart: this.totalDepart,
                        solde_actuel: this.soldeActuel,
                        total: totalValue, // Utilisation de la valeur de l'input
                        description: this.observation,
                        entreprise_id: this.entrepriseId
                    };

                    axios.post('/add-caisses', formData)
                        .then(response => {
                            console.log(response.data);
                            Swal.fire('Succès !', 'La caisse a été validée avec succès.', 'success');
                            this.fetchCaisseData();
                        })
                        .catch(error => {
                            console.error(error);
                            Swal.fire('Erreur !', 'Une erreur s\'est produite lors de la validation de la caisse.', 'error');
                        });

                })
                .catch(error => {
                    console.error(error);
                    Swal.fire('Erreur !', 'Une erreur s\'est produite lors de la récupération de la dernière date de validation.', 'error');
                });
        },


        validerSansVerificationDate() {
            const formData = {
                date: this.date,
                operateur_gsm: this.gsmSolde,
                solde_depart: this.totalDepart,
                solde_actuel: this.soldeActuel,
                total: this.total,
                description: this.observation,
                entreprise_id: this.entrepriseId
            };

            axios.post('/add-caisses', formData)
                .then(response => {
                    console.log(response.data);
                    Swal.fire('Succès !', 'La caisse a été validée avec succès.', 'success');
                    this.fetchCaisseData();
                })
                .catch(error => {
                    console.error(error);
                    Swal.fire('Erreur !', 'Une erreur s\'est produite lors de la validation de la caisse.', 'error');
                });
        },

        exportCSV() {
            Swal.fire({
                title: 'Êtes-vous sûr?',
                text: "Vous êtes sur le point d'exporter les transactions au format CSV.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, exporter!',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const csvContent = this.generateCSV();
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'transactions.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    Swal.fire(
                        'Exporté!',
                        'Le fichier CSV a été exporté avec succès.',
                        'success'
                    );
                }
            });
        },
        generateCSV() {
            const header = ['N°', 'Date', 'Numéro', 'Solde départ', 'Solde actuel', 'Total'].join(',');
            const rows = this.caisses.map((caisse, index) => {
                return [
                    index + 1,
                    caisse.date,
                    caisse.operateur_gsm,
                    caisse.solde_depart,
                    caisse.solde_actuel,
                    caisse.total,
                ].join(',');
            });
            return [header, ...rows].join('\n');
        },
        exportToPDF() {
            Swal.fire({
                title: 'Exportation PDF',
                text: 'Êtes-vous sûr de vouloir exporter ces données en PDF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Exporter',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();
                    const totalPagesExp = "{total_pages_count_string}";

                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                    const imgDataLeft = '../images/LOGOAFRICAMONEY.png';
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);

                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 23;
                    const text = 'ACTIVITE DE LA CAISSE';
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });
                    doc.setTextColor(0, 0, 0);

                    const tableData = this.caisses.map((caisse, index) => [
                        index + 1,
                        caisse.date,
                        caisse.operateur_gsm,
                        caisse.solde_depart + ' ' + 'FCFA',
                        caisse.solde_actuel + ' ' + 'FCFA',
                        caisse.total + ' ' + 'FCFA',
                    ]);

                    const headers = ['N°', 'Date', 'Numéro', 'Solde départ', 'Solde actuel', 'Total'];
                    doc.autoTable({
                        head: [headers],
                        body: tableData,
                        startY: 50,
                        theme: 'grid',
                        columnStyles: {
                            0: { cellWidth: 10 },
                            1: { cellWidth: 30 },
                            2: { cellWidth: 40 },
                            3: { cellWidth: 40 },
                            4: { cellWidth: 40 },
                            5: { cellWidth: 40 },
                        },
                        didDrawPage: function (data) {
                            const pageSize = doc.internal.pageSize;
                            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                            const footerY = pageHeight - 20;

                            doc.setFontSize(10);
                            doc.text(`Page ${doc.internal.getNumberOfPages()}`, data.settings.margin.left, footerY);

                            if (typeof doc.putTotalPages === 'function') {
                                totalPages = data.pageCount;
                                doc.putTotalPages(totalPagesExp);
                                doc.text(`Page ${data.pageNumber} of ${totalPagesExp}`, data.settings.margin.left, pageHeight - 10);
                            }
                        },
                    });

                    for (let i = 1; i <= totalPages; i++) {
                        doc.setPage(i);
                        doc.setFillColor(0, 102, 102);
                        doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                        const middleX = doc.internal.pageSize.width / 2;
                        const middleY = 23;
                        const text = 'ACTIVITE DE LA CAISSE';
                        doc.setTextColor('#ffffff');
                        doc.text(text, middleX, middleY, { align: 'center' });
                    }

                    doc.save('table.pdf');
                }
            });
        },
    }
};
</script>


<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-6">
                                        <label for="startDate">Date</label>
                                        <input type="date" id="date" v-model="date" @change="fetchCaisseData"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="searchoperateur">Opérateur Gsm</label>
                                        <select class="form-select" id="searchoperateur" v-model="operateur_gsm"
                                            @change="fetchCaisseData" required>
                                            <option value="">Sélectionnez un opérateur</option>
                                            <option value="MTN MONEY">MTN MONEY ({{ marchandmtn }})</option>
                                            <option value="MOOV MONEY">MOOV MONEY ({{ marchandmoov }})</option>
                                            <option value="CELTIIS CASH">CELTIIS CASH ({{ marchandceltis }})</option>
                                        </select>
                                    </div>
                                </div>

                                <button type="button" @click="goBack()" class="btn btn-outline-success text-center"><i
                                        class="bi bi-arrow-return-left"></i></button>
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                                <button @click="exportCSV" class="btn btn-sm btn-warning">
                                    <i class="bi bi-file-earmark-spreadsheet"></i> Export CSV
                                </button>
                                <span>&nbsp;</span>
                                <button @click="exportToPDF" class="btn btn-sm btn-danger"><i
                                        class="bi bi-file-earmark-pdf"></i>Export
                                    PDF</button>
                                <span>&nbsp;</span>
                                <button class="btn btn-sm btn-success" data-bs-toggle="modal"
                                    data-bs-target="#validationCaise">
                                    <i class="bi bi-check-circle"></i> Valider la caise
                                </button>

                            </div>
                        </div>
                    </div>


                    <div class="modal fade" id="validationCaise" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header" style="background-color: #006666;">
                                    <h1 class="modal-title fs-5 text-center" id="exampleModalLabel"
                                        style="color: white;">Validation de la caisse
                                    </h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>

                                <div class="modal-body">
                                    <form @submit.prevent="ValiderCaisse">

                                        <!-- Champ Date -->
                                        <div class="mb-3 row">
                                            <div class="col-md-12">
                                                <label for="date" class="col-form-label">Date: (Validation de la
                                                    caisse)</label>
                                                <input type="date" class="form-control" id="date" v-model="date">
                                            </div>
                                        </div>

                                        <!-- Champ GSM-->
                                        <div class="mb-3 row">
                                            <div class="col-md-12">
                                                <label for="gsmSolde" class="col-form-label">GSM</label>
                                                <select class="form-select" id="searchoperateur" v-model="gsmSolde"
                                                    required>
                                                    <option value="">Sélectionnez un opérateur</option>
                                                    <option value="MTN MONEY">MTN MONEY ({{ marchandmtn }})</option>
                                                    <option value="MOOV MONEY">MOOV MONEY ({{ marchandmoov }})
                                                    </option>
                                                    <option value="CELTIS CASH">CELTIS CASH ({{ marchandceltis }})
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="mb-3 row">
                                            <div class="col-md-12">
                                                <label for="soldeDepart" class="col-form-label">Solde de départ:</label>
                                                <input type="number" class="form-control" id="soldeDepart"
                                                    v-model="totalDepart" readonly>

                                            </div>
                                        </div>

                                        <!-- Ajoutez @input pour déclencher la mise à jour de Total -->
                                        <div class="mb-3 row">
                                            <div class="col-md-12">
                                                <label for="soldeActuel" class="col-form-label">Solde actuel:</label>
                                                <input type="number" class="form-control" id="soldeActuel"
                                                    v-model="soldeDepart" min="0" required readonly
                                                    @input="updateTotal">
                                            </div>
                                        </div>
                                        
                                        <!--Solde Total-->
                                        <div class="mb-3 row">
                                            <div class="col-md-12">
                                                <label for="total" class="col-form-label">Total:</label>
                                                <!-- Utilisation de la méthode calculateTotal pour obtenir la somme -->
                                                <input type="number" class="form-control" id="total"
                                                    :value="calculateTotal()" min="0" required readonly>
                                            </div>
                                        </div>

                                        <!-- Champ Observation -->
                                        <div class="mb-3 row">
                                            <div class="col-md-12">
                                                <label for="observation" class="col-form-label">Observation:
                                                    (Optionnel)</label>
                                                <textarea class="form-control" id="observation" rows="3"
                                                    v-model="observation"></textarea>
                                            </div>
                                        </div>

                                        <button type="submit" class="btn btn-success">Enregistrer</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Activité de la caise</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">NUMERO</th>
                                                <th class="text-center" scope="col" style="color: white;">SOLDE DE
                                                    DEPART</th>
                                                <th class="text-center" scope="col" style="color: white;">SOLDE ACTUEL
                                                </th>
                                                <th class="text-center" scope="col" style="color: white;">SOLDE FINAL
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="caisses.length > 0">
                                            <tr v-for="(caisse, index) in caisses" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ caisse.date }}</td>
                                                <td>{{ caisse.operateur_gsm }}</td>
                                                <td class="text-center">{{ caisse.solde_depart }} FCFA</td>
                                                <td class="text-center">{{ caisse.solde_actuel }} FCFA</td>
                                                <td class="text-center">{{ caisse.total }} FCFA</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="12" class="text-center">
                                                    <div class="alert alert-warning d-flex justify-content-center align-items-center"
                                                        role="alert">
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <span>Aucune donnée disponible pour cette recherche.</span>
                                                        <!-- Icône de corbeille --> <span>&nbsp;</span>
                                                        <i class="fas fa-trash-alt mr-2"></i>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>

                                    </table>
                                    <button @click="fetchPreviousPage" :disabled="currentPage === 1"
                                        class="btn btn-warning">Précédent</button>
                                    <span>&nbsp;</span><span>&nbsp;</span>
                                    <button @click="fetchNextPage" :disabled="currentPage === totalPages"
                                        class="btn btn-primary">Suivant</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>